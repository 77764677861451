
import {BaseModel} from "./BaseModel";

export class RoutingModel extends BaseModel {
    repsNumberBookings: number = null;
    distancePrev: number = null;
    maxPerDay: number = null;
    repLocationTime: number = null;
    timeIndoorBlinds: number = null;
    timeIndoorShutter: number = null;
    timeOutdoorBoth: number = null;
    timeBeforeNext: number = null;
    endDayBooking: number = null;
    bookingTimeInterval: number = null;
    timeAllowCurtain: number = null;
    introAllowIndoor: number = null;
    introAllowOutdoor: number = null;

    setProperty(id: string, value: *): this {
        let name = '';
        switch (id){
            case '1': name = 'repsNumberBookings';break;
            case '2': name = 'distancePrev';break;
            case '3': name = 'maxPerDay';break;
            case '4': name = 'repLocationTime';break;
            case '5': name = 'timeIndoorBlinds';break;
            case '6': name = 'timeOutdoorBoth';break;
            case '7': name = 'timeBeforeNext';break;
            case '8': name = 'endDayBooking';break;
            case '9': name = 'bookingTimeInterval';break;
            case '10': name = 'timeAllowCurtain';break;
            case '11': name = 'timeIndoorShutter';break;
            case '12': name = 'introAllowIndoor';break;
            case '13': name = 'introAllowOutdoor';break;
        }
        return super.setProperty(name, value);
    }
    prepareProperties(){
        let result = {};
        result['1'] = this.repsNumberBookings;
        result['2'] = this.distancePrev;
        result['3'] = this.maxPerDay;
        result['4'] = this.repLocationTime;
        result['5'] = this.timeIndoorBlinds;
        result['6'] = this.timeOutdoorBoth;
        result['7'] = this.timeBeforeNext;
        result['8'] = this.endDayBooking;
        result['9'] = this.bookingTimeInterval;
        result['10'] = this.timeAllowCurtain;
        result['11'] = this.timeIndoorShutter;
        result['12'] = this.introAllowIndoor;
        result['13'] = this.introAllowOutdoor;
        return result;
    }
}