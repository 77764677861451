import React, {Component} from 'react';
import PropTypes from "prop-types";

export default class Switch extends Component {
    static propTypes = {
        defaultChecked: PropTypes.bool,
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
    };

    render() {
        let {id, customClassName, ...rest} = this.props;

        if (!id) {
            id = 'tf' + Math.random();
        }

        return (
            <>
                <div className="abc__switch">
                    <input
                        type="checkbox"
                        className="abc__switch__input"
                        id={id}
                        {...rest}
                    />
                    <label htmlFor={id} className="abc__switch__label">
                        <div className="abc__switch__box">
                            <span className="abc__switch__button"></span>
                            <span className="abc__switch__track"></span>
                        </div>
                    </label>
                </div>
            </>
        );
    }
};
