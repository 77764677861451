import Pagination from "../components/Pagination";
import {Booking} from "../components/form/model/Booking";
import {apiRequest} from "../components/Globals";

export default class BookingService {
    searchChangedTimeout = null;
    onDataChange: null;

    pagination: Pagination = new Pagination();
    searchParams = {
        search: null,
        status: [1],
        repId: null,
        dateFrom: null,
        dateTo: null
    };

    list: Booking[] = [];
    lastRequestHash: string = null;

    constructor(onDataChange) {
        this.onDataChange = onDataChange;

        this.clearSearchTimeOut = this.clearSearchTimeOut.bind(this);
        this.setSearchParams = this.setSearchParams.bind(this);
    }

    clearSearchTimeOut() {
        if (this.searchChangedTimeout) {
            clearTimeout(this.searchChangedTimeout);
        }
    }

    resetPagination(): this {
        this.list = [];
        this.pagination.reset();

        return this;
    }

    setSearchParams(params: {
        search: string,
        status: number[],
        repId: number,
        dateFrom: string,
        dateTo: string,
        isHolding: number
    } = {}) {
        this.clearSearchTimeOut();
        this.resetPagination();

        Object.assign(this.searchParams, params);

        this.searchChangedTimeout = setTimeout(
            () => {
                this.resetPagination();
                this.loadList();
            },
            800
        );
    }

    getOne(id: number): Booking {
        let result = null;

        this.list.some((row) => {
            if (row.id === id) {
                result = row;

                return true;
            }

            return false;
        });

        return result;
    }

    getPagination(): Pagination {
        return this.pagination;
    }

    loadMore() {
        this.pagination.page++;
        this.loadList();
    }

    loadList() {
        this.clearSearchTimeOut();

        if (!this.pagination.hasMore) {
            return;
        }

        let payload = {
            repId: this.searchParams.repId,
            searchParams: this.searchParams,
            pagination: this.pagination,
        };

        const lastRequestHash = Math.random();
        this.lastRequestHash = lastRequestHash;


        apiRequest("/bs/booking/", payload)
            .then(result => {
                // TODO need just break previous request
                if (this.lastRequestHash !== lastRequestHash) {
                    return;
                }

                if (result) {
                    this.pagination.hasMore = result.custom.length >= this.pagination.pageSize;

                    result.custom.forEach((row) => {
                        let booking = new Booking();
                        booking.assign(row);

                        this.list.push(booking);
                    })
                }

                if (this.onDataChange) {
                    this.onDataChange();
                }
            });

    }

}