import React, {Component} from 'react';

export default class CheckBox extends Component {
    errorMessage = 'Some error';

    constructor(props) {
        super(props);

        this.refMainContainer = React.createRef();

        this.onFocus = this.onFocus.bind(this);
        this.onFocusLost = this.onFocusLost.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onFocus() {
        this.refMainContainer.current.classList.add('abc__textfield--focused');
    }

    onFocusLost() {
        this.refMainContainer.current.classList.remove('abc__textfield--focused');
    }

    onKeyUp(event) {
        if (event.target.value !== '') {
            this.refMainContainer.current.classList.add('abc__textfield--filled');
        } else {
            this.refMainContainer.current.classList.remove('abc__textfield--filled');
        }
    }

    onClick() {

    }

    render() {
        let id = this.props.id;

        if (!id) {
            id = 'tf' + Math.random();
        }

        // let customClassName = '';

        if (this.props.disabled) {
            // customClassName += ' abc__textfield--disabled';
        }

        return (
            <>
                <div className="abc__checkbox__box">
                    <input type={this.props.type === 'checkbox' ? 'checkbox' : 'radio'} className="abc__checkbox__input" id={id} name={this.props.name} disabled={this.props.disabled} />
                    <label htmlFor={id} className="abc__checkbox__option">
                        <span className="abc__checkbox__button">
                            <svg className="svg__checkbox__checkmark" viewBox="0 0 20 20">
                                <path className="svg__checkmark__path" fill="none" d="M19.3,4.1L7,16.4l-6.1-6.1"/>
                            </svg>
                        </span>
                        <span className="abc__checkbox__text">{this.props.children}</span>
                    </label>
                </div>
            </>
        );
    }
};
