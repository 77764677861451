import React from "react";
import TextField from "../components/form/TextField";
import Select from "../components/form/Select";
import {apiRequest} from "../components/Globals";
import {notify} from "../components/Notify";
import Pagination from "../components/Pagination";
import Access from "../components/Access";
import {Auth} from "../components/Auth";
import moment from "moment";


export default class MarketingReport extends React.Component {
    state = {};

    reportList: [] = [];
    auth: Auth = new Auth();

    pagination: Pagination = new Pagination();
    searchParams = {
        search: null,
    };

    constructor(props) {
        super(props);

        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.clearSearchTimeOut = this.clearSearchTimeOut.bind(this);
        this.loadMore = this.loadMore.bind(this);

    }

    componentDidMount() {
        this.loadMarkReport();
    }

    clearSearchTimeOut() {
        if (this.searchChangedTimeout) {
            clearTimeout(this.searchChangedTimeout);
        }
    }

    resetPagination(): this {
        this.reportList = [];
        this.pagination.reset();

        return this;
    }

    loadMore() {
        this.pagination.page++;
        this.loadMarkReport();
    }

    setSearchParams(params: {
        search: string
    } = {}) {
        this.clearSearchTimeOut();
        this.resetPagination();

        Object.assign(this.searchParams, params);

        this.searchChangedTimeout = setTimeout(
            () => {
                this.resetPagination();
                this.loadMarkReport();
            },
            800
        );
    }

    onChangeSearch(event) {
        let search = {
            search: event.target.value
        };

        if (!Access.isAllowed('MarketingReport', 'fullList')) {
            search.repId = this.auth.userId;
        }

        this.setSearchParams(search);
    }

    loadMarkReport() {
        this.clearSearchTimeOut();

        if (!this.pagination.hasMore) {
            return;
        }

        let payload = {
            pagination: this.pagination,
            searchParams: this.searchParams
        }

        const lastRequestHash = Math.random();
        this.lastRequestHash = lastRequestHash;

        apiRequest('/stat/list', payload)
            .then(data => {
                let list = [];

                if (this.lastRequestHash !== lastRequestHash) {
                    return;
                }

                if (!data || !data.custom) {
                    return this.setState({});
                }

                this.pagination.hasMore = data.custom.length >= this.pagination.pageSize;

                data.custom.forEach(rep => {
                    list.push({
                        id: rep.id,
                        parentId: rep.parent_id,
                        utmCampaign: rep.utm_campaign,
                        utmSource: rep.utm_source,
                        utmMedium: rep.utm_medium,
                        utmContent: rep.utm_content,
                        customData: rep.custom_data,
                        adAgency: rep.ad_agency,
                        referralUrl: rep.referral_url,
                        device: rep.device,
                        ip: rep.ip,
                        dateAdd: rep.date_add,
                        entryPage: rep.entry_page
                    })
                })

                list.forEach(item => {
                    this.reportList.push(item);
                })
                this.setState({});

            }).catch(error => {
            notify({text: error.message});
        });
    }

    render() {
        return (
            <div>
                <>
                    <h1>Marketing Report</h1>

                    <div className="panel">
                        <div className="panel__top">
                            <div className="item panel__title">Marketing UTM's</div>
                            <div className="item">
                                <div className="panel__filter">
                                    <div className="item filter__search">
                                        <TextField
                                            label="Search" size="small"
                                            onChange={this.onChangeSearch}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="panel__body panel__body--no-padding">
                            <table className="tbl tbl--list">
                                <tbody>
                                <tr>
                                    <th>Agency</th>
                                    <th>UTM Campaign Name</th>
                                    <th>UTM Source</th>
                                    <th>UTM Medium</th>
                                    <th>UTM Content</th>
                                    <th>Entry Page</th>
                                    <th>Referral</th>
                                    <th>Date & Time</th>
                                    <th>Session</th>
                                    <th>Device</th>
                                    <th>IP Address</th>
                                </tr>

                                {this.reportList.map((row, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{row.adAgency}</td>
                                            <td>{row.utmCampaign}</td>
                                            <td>{row.utmSource}</td>
                                            <td>{row.utmMedium}</td>
                                            <td>{row.utmContent}</td>
                                            <td>{row.entryPage}</td>
                                            <td>{row.referralUrl}</td>
                                            <td>{moment(row.dateAdd).format('DD MMM YYYY hh:mm:ss')}</td>
                                            <td>{row.parentId ? row.parentId : row.id}</td>
                                            <td>{row.device}</td>
                                            <td>{row.ip}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>

                            {this.pagination.hasMore ?
                                <div className="panel__bottom">
                                    <div className="pagination">
                                        <input className="btn" type={'button'} value={'Load more'}
                                               onClick={this.loadMore}/>
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </>
            </div>
        );
    }
}