import {User} from "./form/model/User";
import {Fitting} from "./form/model/Fitting";

export default class FittingEventItem {
    title: string = null;
    id: number = null;
    extendedProps: {
        fitting: Fitting,
        user: User
    } = null;
    start: string = null;
    end: string = null;
}