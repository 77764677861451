import React from 'react';

export default class ContextMenu extends React.Component {
    // currentTarget = null;
    hideItemsList: [] = [];

    constructor(props) {
        super(props);

        this.refMain = React.createRef();

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onContextMenu = this.onContextMenu.bind(this);

        // if (!props.target) {
        //     throw new Error('Context menu mast have target');
        // }
    }

    componentDidMount() {
        // const target = document.getElementById(this.props.target);
        //
        // if (target) {
        //     document.addEventListener('contextmenu', this.onContextMenu);
        // }

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        // const target = document.getElementById(this.props.target);
        //
        // if (target) {
        //     document.removeEventListener('contextmenu', this.onContextMenu);
        // }

        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    onContextMenu(e) {
        // e.preventDefault();
        //
        // let z = {};
        // Object.assign(z, e);
        // console.log(['aaaaaaaaaaaa', z, this.hoverEvent]);
        // let menu = this.refMain.current;
        //
        // // if (!this.hoverEvent) {
        // //     menu.hide();
        // //     return;
        // // }
        //
        //
        // const resultWidth = e.screenX + window.scrollX + menu.offsetWidth;
        //
        // const positionFixX = window.scrollX + window.innerWidth < resultWidth ? window.scrollX + window.innerWidth - resultWidth : 0;
        //
        //
        // // menu.style.left = (e.screenX + window.scrollX + positionFixX) + 'px';
        // // menu.style.top = (e.pageY) + 'px';
        // // menu.style.visibility = 'visible';
        //
        // this.show(e.pageX + window.scrollX + positionFixX, e.pageY);
    }

    getWidth(): number {
        return this.refMain.current.offsetWidth;
    }

    onClick(onClick) {
        if (onClick) {
            onClick();
        }

        this.hide();
    }

    handleClickOutside(event) {
        if (!this.refMain.current.contains(event.target) ) {
            this.hide();
        }
    }

    show(left: number, top: number): void {
        let menu = this.refMain.current;
        menu.style.left = left + 'px';
        menu.style.top = top + 'px';
        menu.style.visibility = 'visible';
    }

    hide(): void {
        let menu = this.refMain.current;
        menu.style.left = 0 + 'px';
        menu.style.top = 0 + 'px';
        menu.style.visibility = 'hidden';
        // this.currentTarget = null;
    }

    hideItems(itemIds: []) {
        this.hideItemsList = itemIds;
        this.setState({});
    }

    render() {
        let {items, zIndex, ...rest} = this.props;

        if (zIndex === null || zIndex === undefined) {
            zIndex = 100;
        }

        return (
            <div className="abc__context__menu" style={{position: 'absolute', visibility: 'hidden', zIndex: zIndex, left: 0, top: 0}} ref={this.refMain}>

                {items.map((item, i) => {
                    if (this.hideItemsList.includes(item.id)) {
                        return '';
                    }

                    return (
                        <div className="abc__context__menu__item"
                            key={i}
                            onClick={() => this.onClick(item.onClick)}
                        >{item.label}</div>
                    )
                })}

            </div>
        );
    }
}