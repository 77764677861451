import {apiRequest} from "../components/Globals";
import {notify} from "../components/Notify";

export default class SettingsService {
    data: {
        category: string;
        name: string;
        value: string;
    }[] = [];
    loaded = false;
    onLoad = null;

    constructor(onLoad) {
        this.onLoad = onLoad;
    }

    getValue(category: string, param: string) {
        let result = null;

        this.data.some(item => {
           if (item.category === category && item.name === param) {
               result = item.value;

               return true;
           }

            return false;
        });

        return result;
    }

    setValue(category: string, param: string, value) {
        let result = false;

        this.data.some(item => {
           if (item.category === category && item.name === param) {
               item.value = value;
               result = true;

               return true;
           }

            return false;
        });

        return result;
    }

    doSave() {
        apiRequest(
            '/bs/settings/save',
            this.data
        )
            .then(data => {
                notify({
                    text: 'Settings saved'
                })
            }).catch(response => {
            notify({text: response.message});
        });
    }

    load() {
        apiRequest('/bs/settings', 'GET')
            .then(data => {
                this.data = [];
                this.loaded = true;

                if (!data || !data.custom) {
                    return;
                }

                data.custom.forEach(row => {
                    this.data.push({
                        category: row.category,
                        name: row.name,
                        value: row.value
                    });
                });

                if (this.onLoad) {
                    this.onLoad();
                }
            });
    }

}