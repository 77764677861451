import {MEASUREMENTS_SELECT, SHOWROOMS} from "../config/Globals";

export default class CalendarService {
    static storageKey = 'calendar';
    static version = '1.2';

    showRooms: [] = [];
    blinds: [] = [];
    hideUnavailable: boolean = true;
    calendarType: string = 'consultant';
    isWide: boolean = false;

    init() {
        Object.entries(SHOWROOMS).forEach(([key, showRoomName]) => {
            this.showRooms[key] = true;
        });
        MEASUREMENTS_SELECT.forEach((item) => {
            this.blinds[item.value] = true;
        });

        this.calendarType = 'consultant';
        this.isWide = false;
    }

    load() {
        const data = localStorage.getItem(CalendarService.storageKey);
        let needInit = false;

        // reset if version change
        if (!data) {
            needInit = true;
        }

        let json = JSON.parse(data);

        // reset if version change
        if (needInit || json.version !== CalendarService.version) {
            this.init();
            return;
        }

        this.showRooms = json.showroom ? json.showroom : [];
        this.blinds = json.blinds ? json.blinds : [];
        this.hideUnavailable = json.hideUnavailable;
        this.isWide = json.isWide;
        this.calendarType = json.calendarType ? json.calendarType : 'consultant';
    }

    save() {
        localStorage.setItem(
            CalendarService.storageKey,
            JSON.stringify({
                version: CalendarService.version,
                showroom: this.showRooms,
                blinds: this.blinds,
                hideUnavailable: this.hideUnavailable,
                calendarType: this.calendarType,
                isWide: this.isWide,
            })
        );
    }

}