import React from 'react';
import {apiRequest} from "../../components/Globals";
import moment from "moment";
import PropTypes from "prop-types";
import Select from "../../components/form/Select";
import DatePicker from "react-datepicker";
import BoxGroup from "../../components/form/BoxGroup";
import RadioBox from "../../components/form/RadioBox";
import {DROPDOWN_TIMES} from "../../config/Times";
import {notify} from "../../components/Notify";
import {User} from "../../components/form/model/User";
import {AVAILABILITY_EVENT_TYPE} from "../../config/Globals";
import TextArea from "../../components/form/TextArea";

export default class AvailabilityEvent extends React.Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date),
        user: PropTypes.instanceOf(User),
        onChange: PropTypes.func,
    };

    state = {
        startDate2: null,
        startDate: null,
        endDate: null,
        isFull: true,
        isMeeting: false,
        eventType: AVAILABILITY_EVENT_TYPE.default
    };
    description: string = null;

    constructor(props) {
        super(props);

        this.state.startDate = this.props.date;
        this.state.endDate = this.props.date;
        this.refStartTime = React.createRef();
        this.refEndTime = React.createRef();

        this.doSave = this.doSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    onChange() {
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    doSave() {
        let payload = {
            userId: this.props.user.id,
            startDate: this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-D') : '',
            endDate: this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-D') : '',
            startTime: null,
            endTime: null,
            message: null,
            event_type: this.state.eventType
        };

        if (!this.state.isFull) {
            payload.startTime = this.refStartTime.current.value;
            payload.endTime = this.refEndTime.current.value;
        }
        if (this.state.isMeeting) {
            payload.message = this.description;
            payload.event_type = AVAILABILITY_EVENT_TYPE.meeting
        }

        apiRequest('/bs/day-off/add', payload)
            .then(() => {
                this.onChange();
            }).catch(response => {
            notify({text: response.message});
        });
    }

    handleChange(date, dd) {
        this.setState({
            startDate: date[0],
            endDate: date[1],
        });

    }
    render() {
        return (
            <>
                <div className="panel">
{/*
                    <div className="panel__top">
                        <div className="panel__title">Unavailable dates</div>
                    </div>
*/}
                    <div className="panel__body">
                        <div className="ih">
                            <div className="label">Select a start and end date</div>
                            <DatePicker
                                className="datepicker"
                                placeholder="From"

                                selectsRange={true}
                                startDate={this.state.startDate /*? this.state.startDate : this.props.date*/}
                                endDate={this.state.endDate /*? this.state.endDate : this.props.date*/}

                                minDate={new Date()}
                                selected={this.state.startDate}
                                onChange={this.handleChange}
                                // inline
                                // dateFormat="MM-dd-yyyy"

                            />
                        </div>


                        {/*
                                <div className="item">
                                    <div className="ih">
                                        <div className="label">To</div>
                                        <DatePicker
                                            className="datepicker"
                                            selected={this.state.endDate}
                                            onChange={date => this.setState({endDate: date})}
                                        />
                                    </div>
                                </div>
*/}


                        <div className="ih">
                            <div className="form__field">
                                <BoxGroup label="" inline={true}>
                                    <RadioBox
                                        name={'type'}
                                        onChange={() => this.setState({isFull: true, isMeeting: false})}
                                        defaultChecked={true}
                                    >Entire Day</RadioBox>
                                    <RadioBox
                                        name={'type'}
                                        onChange={() => this.setState({isFull: false, isMeeting: false})}
                                        // defaultChecked={true}
                                    >Partial</RadioBox>
                                    <RadioBox
                                        name={'type'}
                                        onChange={() => this.setState({isFull: false, isMeeting: true})}
                                        // defaultChecked={true}
                                    >Meeting</RadioBox>
                                </BoxGroup>
                            </div>
                        </div>


                        {this.state.isFull || (this.state.isFull && !this.state.isMeeting) ? '' :
                            <div className={'ih'}>
                            <div className="time__grid">
                                    <div className="item">
                                        <Select
                                            size="small"
                                            label="Time from"
                                            ref={this.refStartTime}
                                            options={DROPDOWN_TIMES}
                                        />
                                    </div>

                                    <div className="item">
                                        < Select
                                            size="small"
                                            label="Time to"
                                            ref={this.refEndTime}
                                            options={DROPDOWN_TIMES}
                                        />
                                    </div>
                                </div>

                            </div>
                        }

                        {this.state.isMeeting &&
                            <TextArea
                                label={'Description'}
                                onChange={e => this.description = e.target.value}
                            />}
                    </div>

                    <div className="panel__bottom">
                        <div className="bh">
                            <button className="btn" onClick={this.doSave}>Add</button>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}