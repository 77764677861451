import React from 'react';
import moment from "moment";
import PropTypes from "prop-types";
import {User} from "../../components/form/model/User";
import {apiRequest} from "../../components/Globals";
import {notify} from "../../components/Notify";
import {BOOKING_STATUS, getFittingType} from "../../config/Globals";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import BookingService from "../../service/BookingService";
import FittingService from "../../service/FittingService";
import EventItem from "../../components/EventItem";
import Modal from "../../components/Modal";
import WayPoints from "../../components/WayPoints";
import Runs from "./Runs";
import {Auth} from "../../components/Auth";

export default class CalendarComponent extends React.Component {
    static propTypes = {
        user: PropTypes.instanceOf(User),
    };

    state = {
        isSending: false,
    };

    loading: number = 0;
    daysOff: [] = [];
    workingTime: {} = {};
    bookingService: BookingService = new BookingService(() => {
        this.updateCalendar()
    });
    fittingService: FittingService = new FittingService(() => {
        this.updateCalendar()
    });

    currentDate;
    weekStart;
    calendarItems: {} = {};

    selectedDay: number = null;
    modalMap = {
        show: false
    };

    modalRuns = {
        show: false
    };
    auth = new Auth();

    constructor(props) {
        super(props);
        this.currentDate = props.date;
        this.weekStart = moment(this.currentDate).isoWeekday(1);

        this.refLoading = React.createRef();

        this.auth.load();
        this.changeWeek = this.changeWeek.bind(this);
    }

    componentDidMount() {
        this.getRepsWorkingTime();
        this.loadData();
    }

    getWeekStart() {
        return this.weekStart.format('YYYY-MM-DD');
    }

    getWeekEnd() {
        return moment(this.weekStart.format('YYYY-MM-DD')).add(6, 'days').format('YYYY-MM-DD');
    }

    loadDayOffs() {
        this.loadingShow();

        apiRequest('/bs/day-off/period', {
            dateFrom: this.getWeekStart(),
            dateTo: this.getWeekEnd(),
            userId: this.auth.userId,
        })
            .then(result => {
                if (!result || !result.custom) {
                    this.daysOff = [];
                    this.updateCalendar();

                    return;
                }

                this.daysOff = result.custom;

                this.updateCalendar();
            });
    }

    loadHolidays() {
        this.loadingShow();

        apiRequest('/bs/user/get-holidays', {
            dateFrom: this.getWeekStart(),
            dateTo: this.getWeekEnd(),
        })
            .then(result => {
                if (!result || !result.custom) {
                    this.holidays = [];
                    this.updateCalendar();

                    return;
                }

                this.holidays = result.custom;

                this.updateCalendar();
            });
    }

    loadData() {
        this.loadDayOffs();
        this.loadBooking();
        this.loadFittings();
        this.loadHolidays();
    }

    loadBooking() {
        // if (moment(this.lastDate).format('YYYY-MM-DD') === moment(this.curDate).format('YYYY-MM-DD')) {
        //     return;
        // }

        // this.lastDate = this.curDate;

        this.loadingShow();

        this.bookingService
            .getPagination()
            .setPageSize(1200);

        this.bookingService.setSearchParams({
            repId: this.props.userId,
            status: [BOOKING_STATUS.active],
            dateFrom: this.getWeekStart(),
            dateTo: this.getWeekEnd(),
        });
    }

    loadFittings() {
        this.loadingShow();

        this.fittingService
            .getPagination()
            .setPageSize(1200);

        this.fittingService.setSearchParams({
            repId: this.props.userId,
            status: [BOOKING_STATUS.active],
            dateFrom: this.getWeekStart(),
            dateTo: this.getWeekEnd(),
        });
    }

    updateCalendar() {
        if (!this.loadingHide()) {
            return;
        }

        this.calendarItems = {};

        // not working
        let i;

        for (i = 0; i < 7; i++) {
            if (!this.workingTime[i + 1] || !this.workingTime[i + 1].enabled) {
                let day = moment(this.currentDate).isoWeekday(1).add(i, 'days');

                this.addCalendarItem(i, {
                    id: 'off' + Math.random(),
                    title: 'Unavailable',
                    start: day.format('YYYY-MM-DD 06:00:00'),
                    end: day.format('YYYY-MM-DD 20:00:00'),
                    className: 'unavailable',
                    // editable: false,
                    extendedProps: {
                        type: 'not-working'
                    },
                    // rendering: 'background'
                });
            }
        }


        // add day off to calendar
        this.daysOff.forEach(item => {
            let workingDayTime;

            // if (this.workingTime[moment(this.currentDate).isoWeekday(1).format('d')]) {
            //     workingDayTime = this.workingTime[moment(this.currentDate).isoWeekday(1).format('d')];
            // }

            let minDate = moment(item.start_date).format('d') - 1;
            let maxDate = moment(item.end_date).format('d') - 1;

            for (let j = minDate; j <= maxDate + 1; j++) {
                this.addCalendarItem(
                    j,
                    {
                        id: 'off' + j + '-' + item.id,
                        title: 'Unavailable',
                        start: moment(item.start_date, "YYYY-MM-DD").format('YYYY-MM-DD ' + (item.start_time ? item.start_time : (workingDayTime ? workingDayTime.from : '06:00:00'))),
                        end: moment(item.end_date, "YYYY-MM-DD").format('YYYY-MM-DD ' + (item.end_time ? item.end_time : (workingDayTime ? workingDayTime.to : '20:00:00'))),
                        className: 'unavailable__date',
                        editable: false,
                        extendedProps: {
                            type: 'not-working'
                        },
                        rendering: 'background'
                    }
                );
            }
        });


        // add holidays off to calendar
        this.holidays.forEach(item => {
            this.addCalendarItem(moment(item.date, "YYYY-MM-DD").format('d'), {
                id: 'hol' + item.id,
                title: item.name,
                start: moment(item.date, "YYYY-MM-DD").format('YYYY-MM-DD 06:00:00'),
                end: moment(item.date, "YYYY-MM-DD").format('YYYY-MM-DD 20:00:00'),
                className: 'public__holiday',
                editable: false,
                extendedProps: {
                    type: 'public-holiday'
                },
                rendering: 'background'
            });
        });

        // add loaded bookings to calendar
        this.bookingService.list.forEach(booking => {
            // let day = moment(booking.date).startOf('iweek').add(i, 'days');
            // const timeFrom = moment(booking.date + ' ' + booking.timeFrom);
            // const timeTo = moment(booking.date + ' ' + booking.timeTo);

            // if (user && !user.isOverlap) {
            //     user.isOverlap = this.isWorkerOverlap(user, timeFrom, timeTo);
            // }

            this.addCalendarItem(
                moment(booking.date).format('d') - 1,
                {
                    title: (booking.company ? booking.company : booking.firstName + ' ' + booking.lastName) + ' | '
                        + booking.suburb + ' | ' + booking.getMeasuringsString() + ' | ' + booking.message,
                    id: booking.id,
                    extendedProps: {
                        booking: booking
                    },
                    start: booking.date + ' ' + booking.timeFrom,
                    end: booking.date + ' ' + booking.timeTo,
                    className: booking.howToKnow === 14 ? 'self__booking' : ''
                }
            );
        });


        // add loaded fitting to calendar
        this.fittingService.list.forEach(fitter => {
            let className = fitter.fixedTime ? 'fixed__time' : '';
            let productList = [];

            fitter.productList?.forEach(item => {
                // productList.push(item.quantity + 'x ' + item.short_name + ' (' + item.type_name + ')')
                productList.push(item.quantity + ' ' + item.short_name)
            });

            this.addCalendarItem(
                moment(fitter.date).format('d') - 1,
                {
                    title: (fitter.booking.company ? fitter.booking.company : fitter.booking.firstName + ' ' + fitter.booking.lastName) + ' | '
                        + fitter.getTypeCaption() + ', ' + fitter.booking.suburb + ' | ' + productList.join(',') + ' | ' + fitter.notes,
                    id: 'fi' + fitter.id,
                    extendedProps: {
                        fitting: fitter
                    },
                    start: fitter.date + ' ' + fitter.timeFrom,
                    end: fitter.date + ' ' + fitter.timeTo,
                    className: className,
                }
            );

        });

        // // all calendars goto date
        // this.calendarRefs.forEach((userId, ref) => {
        //     if (!ref || !ref.current) {
        //         return;
        //     }
        //
        //     let calendar = ref.current.getApi();
        //     calendar.gotoDate(this.curDate);
        // });


        this.setState({});
    }

    addCalendarItem(day: number, event: EventItem) {
        if (!this.calendarItems) {
            this.calendarItems = {};
        }

        if (!event.extendedProps) {
            event.extendedProps = {};
        }

        if (!this.calendarItems[day]) {
            this.calendarItems[day] = [];
        }

        this.calendarItems[day].push(event);
    }

    getRepsWorkingTime() {
        this.loadingShow();

        apiRequest('/bs/user/get-reps-schedule', {
            rep_id: this.props.userId
        })
            .then(data => {
                this.workingTime = {};

                if (data && data.custom) {
                    data.custom.forEach(row => {
                        if (!this.workingTime) {
                            this.workingTime = {};
                        }

                        if (!this.workingTime[row.day]) {
                            this.workingTime[row.day] = {
                                from: row.time_from,
                                to: row.time_to,
                                enabled: row.status === 1
                            };
                        }
                    });
                }

                this.updateCalendar();
            }).catch(response => {
            notify({text: response.message});
        });
    }

    changeWeek(step: number) {
        if (step > 0) {
            this.currentDate = moment(this.currentDate).add(1, 'week')
        } else {
            this.currentDate = moment(this.currentDate).subtract(1, 'week');
        }

        this.weekStart = moment(this.currentDate).isoWeekday(1).startOf('iweek');

        this.loadData();
    }

    getUserPointAddresses() {
        if (!this.calendarItems[this.selectedDay]) {
            return [];
        }

        let items = [];

        this.calendarItems[this.selectedDay].forEach((item) => {
            if (item.extendedProps.booking) {
                items.push({
                    addr: item.extendedProps.booking.address + ', ' + item.extendedProps.booking.suburb + ', ' + item.extendedProps.booking.postalCode,
                    date: moment(item.extendedProps.booking.date + ' ' + item.extendedProps.booking.timeFrom)
                });
            } else if (item.extendedProps.fitting) {
                items.push({
                    addr: item.extendedProps.fitting.booking.address + ', ' + item.extendedProps.fitting.booking.suburb + ', ' + item.extendedProps.fitting.booking.postalCode,
                    date: moment(item.extendedProps.fitting.date + ' ' + item.extendedProps.fitting.timeFrom)

                });
            }
        });

        items.sort(function (a, b) {
            return a.date > b.date ? 1 : -1;
        });

        let result = [];

        items.forEach(item => {
            result.push(item.addr);
        });

        return result;
    }

    getUserEventList() {
        if (!this.calendarItems[this.selectedDay]) {
            return [];
        }

        let items = [];

        this.calendarItems[this.selectedDay].forEach((item) => {
            if (item.extendedProps.booking) {
                items.push(item.extendedProps.booking);
            } else {
                items.push(item.extendedProps.fitting);
            }
        });

        return items;
    }

    getSelectedDate() {
        return moment(this.weekStart.format('YYYY-MM-DD')).add(this.selectedDay, 'days').format('YYYY-MM-DD');
    }

    loadingShow() {
        this.loading++;
        this.refLoading.current.style.display = 'block';
    }

    loadingHide(): boolean {
        this.loading--;

        if (this.loading > 0) {
            return false;
        }

        this.refLoading.current.style.display = 'none';

        return true;
    }

    render() {
        let {events, userId, ...rest} = this.props;

        if (!events) {
            events = [];
        }

        let addClass = '';

        if (this.state.isSending) {
            addClass = 'runsheet__sending';
        }

        return (
            <>
                <div className="loading__overlay" ref={this.refLoading} style={{display: 'none'}}>
                    <svg className="icon__loading">
                        <circle cx="13" cy="13" r="13"></circle>
                    </svg>
                </div>

                <div className="calendar__navigation">
                    <div className="item">
                        <span className="calendar__arrows calendar__arrows--prev" onClick={() => {
                            this.changeWeek(-1)
                        }}> <svg className="svg__prev" width="13" height="22" viewBox="0 0 13 22" fill="none"><path d="M12 1L2 11L12 21"/></svg>Prev</span>
                    </div>
                    <div className="item">
                        <span className="calendar__arrows calendar__arrows--next" onClick={() => {
                            this.changeWeek(1)
                        }}>Next<svg className="svg__next" width="13" height="22" viewBox="0 0 13 22" fill="none"><path d="M1 1L11 11L1 21"/></svg></span>
                    </div>
                </div>

                <div className="calendar__booking">
                    <div
                        className="calendar__booking__row"
                        // onContextMenu={this.contextMenu}
                    >
                        {[0, 1, 2, 3, 4, 5, 6].map(i => {
                            let workingDayTime;

                            // if (this.workingTime[item.id] && this.workingTime[item.id][moment(this.curDate).format('d')]) {
                            //     workingDayTime = this.workingTime[item.id][moment(this.curDate).format('d')];
                            // }

                            return (
                                <div className={'calendar__booking__list'} key={i}>
                                    <div className="calendar__booking__reps__name">
                                        <span className="firstname">{moment(this.currentDate).isoWeekday(1).startOf('iweek').add(i, 'days').format('DD MMM')}<br/>
                                            {moment(this.currentDate).isoWeekday(1).startOf('iweek').add(i, 'days').format('dddd')}</span>
                                        {/*
                                        <span className={'working-time ' + (item.isOverlap ? 'overlap' : '')}>{workingDayTime && workingDayTime.enabled ?
                                        moment(moment(this.curDate).format('YYYY-MM-DD') + ' ' + workingDayTime.from).format('h:mm')
                                        + ' - ' + moment(moment(this.curDate).format('YYYY-MM-DD') + ' ' + workingDayTime.to).format('h:mm')
                                        : 'n/a'}</span>
*/}
                                        <div className="action">
                                                    <span className="action__link"
                                                          onClick={() => {
                                                              this.selectedDay = i;
                                                              this.modalMap.show = true;
                                                              this.setState({})
                                                          }}
                                                    >Route</span> | <span className="action__link"
                                                                          onClick={() => {
                                                                              this.selectedDay = i;
                                                                              this.modalRuns.show = true;
                                                                              this.setState({})
                                                                          }}
                                        >Runs</span>
                                        </div>
                                    </div>
                                    <FullCalendar
                                        key={Math.random()}
                                        initialView={'timeGridDay'}
                                        headerToolbar={{
                                            left: '',
                                            center: '',
                                            right: ''
                                        }}
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        events={this.calendarItems[i]}
                                        eventClick={this.onEventClick}
                                        // dateClick={(info) => {
                                        //     this.onDateClick(info, item)
                                        // }}


                                        slotMinTime={'06:00'}
                                        slotMaxTime={'20:00'}
                                        // slotMinTime={this.minTime}
                                        // slotMaxTime={this.maxTime}


                                        // droppable={"true"}
                                        // editable={"true"}
                                        allDaySlot={false}
                                        dayHeaders={false}

                                        // eventReceive={(info) => this.onMoveEventToOtherRep(info, item)}
                                        // eventDrop={this.onEventChangeTime}

                                        contentHeight={"auto"}
                                        eventDurationEditable={false}
                                        initialDate={moment(this.currentDate).isoWeekday(1).startOf('iweek').add(i, 'days').toDate()}
                                        // ref={this.calendarRefs[item.id]}
                                        // eventMouseEnter={this.onMouseEnterEvent}
                                        // eventMouseLeave={this.onMouseLeaveEvent}
                                        eventTimeFormat={{
                                            hour: 'numeric',
                                            minute: '2-digit',
                                            meridiem: false
                                        }}
                                    />
                                </div>

                            )
                        })}


                    </div>

                </div>

                <Modal
                    status={this.modalMap}
                >
                    <div className="panel">
                        <WayPoints points={this.getUserPointAddresses()}/>
                    </div>
                </Modal>

                <Modal
                    status={this.modalRuns}
                    className="modal__printing"
                >

                    <Runs
                        date={this.getSelectedDate()}
                        userId={userId}
                        events={this.getUserEventList()}
                    />

                </Modal>

            </>
        );
    }
}
