import React from 'react';
import Select from "../components/form/Select";
import BoxGroup from "../components/form/BoxGroup";
import RadioBox from "../components/form/RadioBox";
import Popup from "../components/Popup";
import Modal from "../components/Modal";
import {apiRequest} from "../components/Globals";
import {daysOfWeek, DROPDOWN_TIMES} from "../config/Times";
import DatePicker from "react-datepicker";
import {Auth} from "../components/Auth";
import {notify} from "../components/Notify";
import moment from "moment";
import Access from "../components/Access";
import {AVAILABILITY_EVENT_TYPE, ROLES} from "../config/Globals";
import CalendarComponent from "./calendar/CalendarComponent";
import TextArea from "../components/form/TextArea";

export default class Availability extends React.Component {
    state = {
        items: [],
        holidays: [],
        startDate2: null,
        startDate: null,
        endDate: null,
        isFull: true,
        isMeeting: false,
        eventType: AVAILABILITY_EVENT_TYPE.default

    };

    auth: Auth = new Auth();

    modalStatusDates = {
        show: false
    };

    hashCalendar = Math.random();

    schedule: [] = [];
    description: string = null;

    constructor(props) {
        super(props);

        this.auth.load();

        this.refStartTime = React.createRef();
        this.refEndTime = React.createRef();
        this.refUser = React.createRef();
        this.refCalendarContent = React.createRef();
        this.refSOmePanel = React.createRef();

        this.doShowDatesModal = this.doShowDatesModal.bind(this);
        this.doSave = this.doSave.bind(this);
        this.loadUsers = this.loadUsers.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getSchedule = this.getSchedule.bind(this);
        this.updateCalendarSize = this.updateCalendarSize.bind(this);
    }

    handleChange(date, dd) {
        this.setState({
            startDate: date[0],
            endDate: date[1],
        });

    }

    componentDidMount() {
        this.refCalendarContent.current.style.width = this.refSOmePanel.current.offsetWidth + 'px';

        this.loadDayOffs();
        this.loadUsers();
        this.getSchedule();
        this.loadHolidays();
        window.addEventListener('resize', this.updateCalendarSize);
    }

    loadDayOffs() {
        let url = Access.isAllowed('MeasureAndQuote', 'availabilityFull') ? '/bs/day-off' : '/bs/day-off/personal-list';

        apiRequest(url)
            .then(data => {
                this.setState({
                    items: data ? data.custom : []
                })
            });
    }

    loadHolidays() {
        apiRequest('/bs/user/get-close-holiday-list')
            .then(result => {
                if (!result || !result.custom) {
                    this.setState({holidays: []});

                    return;
                }

                this.setState({holidays: result.custom});
            });
    }

    doSave() {
        let payload = {
            userId: Access.isAllowed('MeasureAndQuote', 'availabilityFull') ? this.refUser.current.value : this.auth.userId,
            startDate: this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-D') : '',
            endDate: this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-D') : '',
            startTime: null,
            endTime: null,
            message: null,
            event_type: this.state.eventType
        };

        if (!this.state.isFull) {
            payload.startTime = this.refStartTime.current.value;
            payload.endTime = this.refEndTime.current.value;
        }
        if (this.state.isMeeting) {
            payload.message = this.description;
            payload.event_type = AVAILABILITY_EVENT_TYPE.meeting
        }


        apiRequest('/bs/day-off/add', payload)
            .then(() => {
                this.modalStatusDates.show = false;
                this.hashCalendar = Math.random();

                this.loadDayOffs();
                this.setState({})
            }).catch(response => {
            notify({text: response.message});
        });
    }

    doDelete(id: number) {
        apiRequest('/bs/day-off/delete', {
            id: id
        })
            .then(() => {
                this.modalStatusDates.show = false;
                this.hashCalendar = Math.random();

                this.loadDayOffs();
                this.setState({})
            }).catch(response => {
            notify({text: response.message});
        });
    }

    doShowDatesModal() {
        this.modalStatusDates.show = true;
        this.setState({});
    }

    doHideDatesModal() {
        this.modalStatusDates.show = false;
        this.setState({});
    }

    loadUsers() {
        apiRequest('/bs/user/get-reps-dropdown')
            .then(data => {
                let list = [];
                data.custom.forEach(item => {
                    list.push({value: item.value, label: item.label});
                });

                this.setState({
                    userList: list,
                });
            })
    }

    getSchedule() {
        apiRequest(
            "/bs/user/get-reps-schedule",
            {rep_id: this.auth.userId}
        )
            .then(data => {
                if (!data) {
                    return;
                }

                this.schedule = data.custom;
                this.setState({});
            });
    }

    updateCalendarSize() {
        this.refCalendarContent.current.style.width = this.refSOmePanel.current.offsetWidth + 'px';
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateCalendarSize);
    }
    render() {

        return (
            <>
                <h1>Availability</h1>

                {this.auth.role === ROLES.reps ?
                    <div className="panel">
                        <div className="panel__top">
                            <div className="panel__title">Available Days</div>
                        </div>
                        <div className="panel__body">
                            {daysOfWeek.map((day, key) => {
                                let scheduleDay = this.schedule[parseInt(key)];

                                return (
                                    <div className="available__days">
                                        <div className="available__days__item">{day}</div>
                                        <div
                                            className="available__days__item">{scheduleDay && scheduleDay['status'] === 1 ? moment('2021-06-21 ' + scheduleDay['time_from']).format('h:mma') + ' - ' + moment('2021-06-21 ' + scheduleDay['time_to']).format('h:mma') : 'n/a'}</div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                    : ''}

                <div className="panel" ref={this.refSOmePanel}>
                    <div className="panel__top">
                        <div className="panel__title">Unavailable Dates</div>
                        <div className="panel__action"><span className="btn btn--s" onClick={this.doShowDatesModal}>Add</span></div>
                    </div>
                    <div className="panel__body panel__body--no-padding">

                        <ul className="availability">

                            {this.state.items.map((item, key) => {

                                let title = moment(item.start_date).format('DD MMMM YYYY');

                                if (item.end_date !== item.start_date) {
                                    title += ' to ' + moment(item.end_date).format('DD MMMM YYYY');
                                }

                                if (item.start_time) {
                                    title += ' (' + moment(item.start_date + ' ' + item.start_time).format('h:mma') + ' - '
                                        + moment(item.start_date + ' ' + item.end_time).format('h:mma') + ')';
                                }


                                // 21 May 2021 to 23 May 2021 = IF MORE THAN 1 DAY
                                // 21 May 2021 = IF 1 DAY
                                // 21 May 2021 (9:00am - 12:00pm) = IF PARTIAL
                                return (
                                    <li key={item.id}>
                                        <div className="text">{Access.isAllowed('MeasureAndQuote', 'availabilityFull') ? item.full_name + ' - ' : ''}{title}</div>
                                        <div className="action">
                                            <Popup
                                                container={'header'}
                                                trigger={
                                                    <div className="menu__dots__trigger">
                                                        <div className="menu__dots__trigger__dots"></div>
                                                        <div className="menu__dots__trigger__dots"></div>
                                                        <div className="menu__dots__trigger__dots"></div>
                                                    </div>
                                                }
                                                action={'click'}
                                                className={'menu__dots abc__toggle'}
                                            >
                                                <div className="menu__dots__dropdown">
                                                    <div className="menu__dots__list">
                                                        <div className="menu__dots__list__item" onClick={() => this.doDelete(item.id)}>Delete</div>
                                                    </div>
                                                </div>
                                            </Popup>
                                        </div>
                                    </li>)
                            })}
                        </ul>

                    </div>

                </div>

                <div className="panel">
                    <div className="panel__top">
                        <div className="panel__title">Next Public Holidays</div>
                    </div>
                    <div className="panel__body panel__body--no-padding">

                        <ul className="availability">

                            {this.state.holidays.map((item, key) => {
                                return (
                                    <li key={item.id}>
                                        <div className="text">{moment(item.date).format('DD MMMM YYYY') + ' ' + item.name}</div>
                                    </li>
                                )
                            })}
                        </ul>

                    </div>

                </div>

                <div className="horizontal__scroll" ref={this.refCalendarContent}>
                    <div className="horizontal__scroll__content">

                        <CalendarComponent
                            key={this.hashCalendar}
                            userId={this.auth.userId}
                            date={new Date()}
                        />
                    </div>
                </div>

                <Modal
                    status={this.modalStatusDates}
                    className="xs__full xs__top"
                >
                    <div className="abc__modal__content">
                        <div className="panel">
                            <div className="panel__top">
                                <div className="panel__title">Unavailable dates</div>
                            </div>
                            <div className="panel__body">
                                {Access.isAllowed('MeasureAndQuote', 'availabilityFull') ?
                                    <div className="ih">
                                        <Select
                                            label="Select User"
                                            options={this.state.userList}
                                            ref={this.refUser}
                                            // defaultValue={this.currentUser.id}
                                        />
                                    </div>
                                    : ''}


                                <div className="ih">
                                    <div className="label">Select a start and end date</div>
                                    <DatePicker
                                        className="datepicker"
                                        placeholder="From"

                                        selectsRange={true}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}

                                        minDate={new Date()}
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                        // inline
                                        // dateFormat="MM-dd-yyyy"

                                    />
                                </div>


                                {/*
                                <div className="item">
                                    <div className="ih">
                                        <div className="label">To</div>
                                        <DatePicker
                                            className="datepicker"
                                            selected={this.state.endDate}
                                            onChange={date => this.setState({endDate: date})}
                                        />
                                    </div>
                                </div>
*/}


                                <div className="ih">
                                    <div className="form__field">
                                        <BoxGroup label="" inline={true}>
                                            <RadioBox
                                                name={'type'}
                                                onChange={() => this.setState({isFull: true, isMeeting: false})}
                                                defaultChecked={true}
                                            >Entire Day</RadioBox>
                                            <RadioBox
                                                name={'type'}
                                                onChange={() => this.setState({isFull: false, isMeeting: false})}
                                                // defaultChecked={true}
                                            >Partial</RadioBox>
                                            <RadioBox
                                                name={'type'}
                                                onChange={() => this.setState({isFull: false, isMeeting: true})}
                                                // defaultChecked={true}
                                            >Meeting</RadioBox>

                                        </BoxGroup>
                                    </div>
                                </div>


                                {this.state.isFull || (this.state.isFull && !this.state.isMeeting) ? '' :
                                    <div className={'ih'}>
                                    <div className="time__grid">
                                            <div className="item">
                                                <Select
                                                    size="small"
                                                    label="Time from"
                                                    ref={this.refStartTime}
                                                    options={DROPDOWN_TIMES}
                                                />
                                            </div>

                                            <div className="item">
                                                < Select
                                                    size="small"
                                                    label="Time to"
                                                    ref={this.refEndTime}
                                                    options={DROPDOWN_TIMES}
                                                />
                                            </div>
                                        </div>
                                        </div>

                                }

                                {this.state.isMeeting &&
                                    <TextArea
                                        label={'Description'}
                                        onChange={e => this.description = e.target.value}
                                    />}

                            </div>

                            <div className="panel__bottom">
                                <div className="bh">
                                    <button className="btn" onClick={this.doSave}>Add</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>

            </>
        );
    }
}