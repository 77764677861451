import React from 'react';
import {apiRequest} from "../../components/Globals";
import moment from "moment";
import PropTypes from "prop-types";
import TextField from "../../components/form/TextField";
import CheckBox from "../../components/form/CheckBox";
import Select from "../../components/form/Select";
import OldSelect from 'react-select'
import TextArea from "../../components/form/TextArea";
import {BOOKING_STATUS, FITTING_TYPE, PRODUCT_LOCATION} from "../../config/Globals";
import {User} from "../../components/form/model/User";
import {Booking} from "../../components/form/model/Booking";
import {DROPDOWN_TIMES} from "../../config/Times";
import {notify} from "../../components/Notify";
import Modal from "../../components/Modal";
import SearchCustomerBooking from "../../components/form/SearchCustomerBooking";
import DatePicker from "react-datepicker";
import BoxGroup from "../../components/form/BoxGroup";
import RadioBox from "../../components/form/RadioBox";
import {Fitting} from "../../components/form/model/Fitting";
import {OnlyNumberValidator} from "../../components/form/validators/OnlyNumberValidator";

export default class FittingEvent extends React.Component {

    static propTypes = {
        calendarEvent: PropTypes.instanceOf(Fitting),
        user: PropTypes.instanceOf(User),
        onChange: PropTypes.func,
        doDelete: PropTypes.func
    };

    state = {
        loaded: false,
        logs: [],
        indoorProductList: [],
        outdoorProductList: [],
    };

    modalCancelConfirm = {
        show: false
    };
    fitting: Fitting;
    eventCheckHash: string;
    eventCheckProductHash: string = 'emptyProductHash';

    products: {
        product: string,
        quantity: number,
        id: number,
        other: string,
        type: number,
    }[] = [];

    fitters: [] = [];
    selectedFitter: [] = [];


    constructor(props) {
        super(props);

        this.refFormLeftSide = React.createRef();
        this.refFormRightSide = React.createRef();
        this.refTimeManual = React.createRef();
        this.refTimeAuto = React.createRef();
        this.refThirdSide = React.createRef();

        if (this.props.user) {
            this.selectedFitter = [{
                value: this.props.user.id,
                label: this.props.user.firstName + ' ' + this.props.user.lastName,
                isFixed: true,
            }];
        }

        // this.loadLogs = this.loadLogs.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
        this.doSelectCustomer = this.doSelectCustomer.bind(this);
        this.showCardDetails = this.showCardDetails.bind(this);
        this.changeProduct = this.changeProduct.bind(this);
        this.addFitter = this.addFitter.bind(this);
        this.onFittersChange = this.onFittersChange.bind(this);

        this.fitting = this.props.calendarEvent;

        if (!this.fitting) {
            this.fitting = new Fitting();
        }

        if (!this.fitting.booking) {
            this.fitting.booking = new Booking();
        }

        this.eventCheckHash = this.fitting.hash;
    }

    componentDidMount(): void {
        this.loadFittingUsers();
        this.loadFitters();
        this.loadProducts();
        this.loadProductsList();
    }

    loadFitters() {

        apiRequest(
            '/bs/user/fitters')
            .then((data) => {
                this.fitters = [];

                if (data.custom) {
                    data.custom.forEach(row => {
                        this.fitters.push({
                            value: row.id,
                            label: row.full_name,
                            // isFixed: row.id === this.props.user.id
                        });
                    });
                }

                this.setState({});
            })
            .catch(data => {
                // this.updateLastError(data);
                // notify({text: data.message});
                // this.onLoading(false);
                // this.setState({});
            });
    }

    loadFittingUsers() {
        apiRequest(
            '/bs/fitting/get-users', {
                fittingId: this.fitting.id,
            })
            .then((data) => {
                if (data.custom) {
                    data.custom.forEach(row => {
                        if (this.props.user && row.id === this.props.user.id.toString()) {
                            return;
                        }

                        this.selectedFitter.push({
                            value: Number(row.id),
                            label: row.full_name,
                            // isFixed: row.id === this.props.user.id
                        });
                    });
                }

                this.setState({});
            })
            .catch(data => {
                // this.updateLastError(data);
                // notify({text: data.message});
                // this.onLoading(false);
                // this.setState({});
            });
    }

    injectCustomTime(value) {
        let exists = false;

        DROPDOWN_TIMES.some(item => {
            if (item.value === value) {
                exists = true;

                return true;
            }

            return false;
        });

        let result = [...DROPDOWN_TIMES];

        if (!exists && value) {
            result.unshift({
                value: value,
                label: moment('2021-01-15 ' + value).format('h:mma')
            });
        }

        return result;
    }

    updateLastError(data) {
        this.lastErrors = data.fields ? data.fields : {};
    }

    onSave() {
        if (!this.validate()) {
            this.setState({});

            return;
        }

        let fitters = [];

        this.selectedFitter.forEach(item => {
            fitters.push(item.value);
        });

        if (fitters.length === 0) {
            notify({text: 'Fitter is required'});

            return;
        }

        this.onLoading(true);

        apiRequest(
            '/bs/fitting/edit',
            {
                userId: this.props.user.id,
                bookingId: this.fitting.booking.id,
                fittingId: this.fitting.id,
                callBefore: this.fitting.callBefore,
                fixedTime: this.fitting.fixedTime,

                customerNo: this.fitting.customerNo,
                // type: this.fitting.type,
                date: this.fitting.date,
                isAutoTime: this.fitting.isAutoTime,
                timeType: this.fitting.timeType,
                timeFrom: this.fitting.timeFrom,
                timeTo: this.fitting.timeTo,
                windows: this.fitting.windows,
                notes: this.fitting.notes,
                balanceDue: this.fitting.balanceDue,
                products: this.products,
                // contractPrice: this.fitting.contractPrice,
                productLocation: this.fitting.productLocation,
                takedowns: this.fitting.takedowns,
                fitters: fitters,
                send_sms: this.fitting.smsReminder ? 1 : 0,
            }
        )
            .then(() => {
                this.onChange();
                this.showCancelPopup(false);
            })
            .catch(data => {
                this.updateLastError(data);
                notify({text: data.message});
                this.onLoading(false);
                this.setState({});
            });
    }

    doDelete() {
        if (this.props.doDelete) {
            return this.props.doDelete();
        }
    }

    onDelete() {
        this.showCancelPopup(false);
        this.onLoading(true);

        apiRequest('/bs/fitting/change-status', {
            id: this.fitting.id,
            status: BOOKING_STATUS.canceled
        })
            .then(() => {
                // this.onChange();
                this.showCancelPopup(false);
                this.doDelete();

                notify({
                    text: 'Booking has been cancelled.'
                });
            })
            .catch(response => {
                this.onLoading(false);
                notify({
                    text: response.message
                })
            });
    }

    showCancelPopup(show: boolean): void {
        this.modalCancelConfirm.show = show;

        if (show) {
            this.lastErrors = {};
        }

        this.setState({});
    }

    onAddressChange(event) {
        if (!event.target.currentLocation.streetNumber) {
            return;
        }

        this.fitting.booking.postalCode = event.target.currentLocation.postalCode;
        this.fitting.booking.suburb = event.target.currentLocation.locality;
        this.fitting.booking.address = event.target.currentLocation.streetNumber + ' ' + event.target.currentLocation.street;
        this.setState({});
    }

    validate() {
        this.lastErrors = [];

        if (!this.fitting.isValid()) {
            this.fitting.validationMessages.forEach(item => {
                this.lastErrors[item.fieldName] = item.message;
            });

        }

        this.products.forEach((product, i) => {
            let numberValidator = new OnlyNumberValidator();

            if ((product.id || product.product)) {
                if (!numberValidator.isValid(product.quantity)) {
                    this.lastErrors['product_' + i] = 'Required numbers only';
                }

                if (!product.type) {
                    this.lastErrors['type_' + i] = 'Required';
                }
            } else if (i === 0) {
                this.lastErrors['product_out'] = 'Product selection is required';
                this.lastErrors['product_ind'] = 'Product selection is required';
            }
        });

        if (this.lastErrors && Object.keys(this.lastErrors).length > 0) {
            console.log(['dddFFF', this.lastErrors]);
            return false;
        }
        console.log(['zzzzz', this.lastErrors]);

        return true;
    }

    doSelectCustomer(event, booking: Booking) {
        this.fitting.booking = booking;

        this.showCardDetails(!!event.target.value);

        this.setState({});
    }

    showCardDetails(show: boolean) {
        if (show) {
            this.refFormLeftSide.current.style.visibility = "visible";
            this.refFormRightSide.current.style.visibility = "visible";
            this.refThirdSide.current.style.visibility = "visible";
        } else {
            this.refFormLeftSide.current.style.visibility = "hidden";
            this.refFormRightSide.current.style.visibility = "hidden";
            this.refThirdSide.current.style.visibility = "hidden";
        }

        this.setState({});
    }

    onLoading(show: boolean) {
        if (this.props.onLoading) {
            this.props.onLoading(show);
        }
    }

    onChange() {
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    changeProduct(e) {
        const name = e.target.name.split('_');
        this.products[name[1]][name[0]] = e.target.value;

        // Indoor product "6" and Outdoor Product "11" === Other
        if(this.products[name[1]].product !== "6" && this.products[name[1]].product !== "11") {
            this.products[name[1]].other = "";
        }

        if (name[0] === 'product') {
            this.setState({});
        }
    }

    loadProducts() {
        this.products = [];

        if (!this.fitting.id || this.eventCheckHash === this.eventCheckProductHash) {
            if (!this.fitting.id) {
                this.products.push({product: '', quantity: ''});
            }

            this.setState({});
            return;
        }

        this.eventCheckProductHash = this.eventCheckHash;

        apiRequest('/bs/fitting/product-list', {
            'id': this.fitting.id
        })
            .then(response => {
                if (!response || !response.custom) {
                    this.products.push({product: '', quantity: ''});
                } else {
                    response.custom.forEach(row => {
                        this.products.push({
                            product: row.product_id,
                            quantity: row.quantity,
                            id: row.id,
                            other: row.product,
                            type: row.type_id
                        });
                    });
                }

                this.setState({})
            });
    }

    loadProductsList() {
        apiRequest('/bs/fitting/product-name-list')
            .then(response => {
                if (!response || !response.custom) {
                    this.setState({loaded: true});
                    return;
                }

                let listIndoor = [{value: '', label: ''}];
                let listOutdoor = [{value: '', label: ''}];

                response.custom.forEach(row => {
                    if (row.type === 'indoor') {
                        listIndoor.push({
                            value: row.id,
                            label: row.short_name,
                        });
                    } else {
                        listOutdoor.push({
                            value: row.id,
                            label: row.short_name,
                        });
                    }
                });

                this.setState({
                    outdoorProductList: listOutdoor,
                    indoorProductList: listIndoor,
                    loaded: true
                })
            });
    }

    addFitter() {
        alert('HALO');
    }

    onFittersChange(value, actionMeta) {
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                // if (actionMeta.removedValue.isFixed) {
                //     return;
                // }
                break;
            case 'clear':
                // value = this.selectedFitter.filter((option) => option.isFixed);
                break;
        }


        this.selectedFitter = value;
        this.setState({});
    }

    render() {
        const user = this.props.user;

        if (!this.state.loaded) {
            return '';
        }

        return (
            <>
                <div className="panel__top">
                    <div
                        className="panel__title">
                        {this.fitting.id ? 'Booking #' + this.fitting.id : 'Add Fitting'} for {user.firstName + ' ' + user.lastName}
                    </div>
                </div>

                <div className="panel__body">
                    <div className="add__booking__fitter__select__customer">
                        <SearchCustomerBooking
                            label="Customer Name / Phone / Email / Company / Address"
                            size=""
                            onChange={this.doSelectCustomer}
                        />
                    </div>

                    <div className="calendar__booking__box calendar__booking__box--fitter">
                        <div className="item">
                            <div ref={this.refFormLeftSide} style={{visibility: this.fitting.id ? 'visible' : 'hidden'}}>
                                {this.fitting ?
                                    <>
                                        <p><strong>
                                            {this.fitting.booking ? this.fitting.booking.firstName + ' ' + this.fitting.booking.lastName : ''}<br/>
                                            {this.fitting.booking ? this.fitting.booking.company : ''}<br/>
                                            {this.fitting.booking ? this.fitting.booking.phone : ''}<br/>
                                            {this.fitting.booking ? this.fitting.booking.unitNo : ''}<br/>
                                            {this.fitting.booking ? this.fitting.booking.address + ', '
                                                + this.fitting.booking.suburb
                                                + (this.fitting.booking.postalCode ? ', ' + this.fitting.booking.postalCode : '') : ''}<br/>
                                            {this.fitting.booking ? this.fitting.booking.email : ''}
                                        </strong>
                                        </p>

                                        <p><strong>
                                            MQ Date: {this.fitting.booking ? moment(this.fitting.booking.date).format('DD MMMM yyyy') : ''}<br/>
                                            Measured by: {this.fitting.booking ? this.fitting.booking.repsName : ''}
                                        </strong>
                                        </p>

                                        <p>
                                            Qty Blinds: {this.fitting.booking ? this.fitting.booking.blinds : ''}<br/>
                                            Qty Indoor Shutters: {this.fitting.booking ? this.fitting.booking.indoorShutters : ''}<br/>
                                            Qty Curtains: {this.fitting.booking ? this.fitting.booking.curtains : ''}<br/>
                                            Qty Outdoor: (Blinds & Shutters): {this.fitting.booking ? this.fitting.booking.outdoorBlinds : ''}<br/>
                                            Qty Security: {this.fitting.booking ? this.fitting.booking.secure : ''}
                                        </p>
                                    </>
                                    : ''}
                            </div>
                        </div>

                        <div className="item" ref={this.refFormRightSide} style={{visibility: this.fitting.id ? 'visible' : 'hidden'}}>


                            <div className="form__row">
                                <div className="form__label">Fitter</div>
                                <OldSelect
                                    value={this.selectedFitter}
                                    isMulti
                                    // styles={styles}
                                    // isClearable={this.selectedFitter.some((v) => !v.isFixed)}
                                    name="colors"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={this.onFittersChange}
                                    options={this.fitters}
                                />
                            </div>

                            <div className="form__row">
                                <div className="form__label">SAP No.</div>
                                <div className="form__field">
                                    <TextField
                                        label=""
                                        size="small"
                                        value={this.fitting.customerNo}
                                        onChange={(e) => {
                                            this.fitting.customerNo = e.target.value
                                        }}
                                        errorMessage={this.lastErrors ? this.lastErrors['customerNo'] : ''}
                                    />

                                </div>
                            </div>


                            <div className="form__row">
                                <div className="form__label">Fitting Date</div>
                                <div className="form__field">
                                    <DatePicker
                                        className="datepicker"
                                        placeholder="From"

                                        selectsRange={false}
                                        startDate={this.fitting.date ? moment(this.fitting.date, "YYYY-MM-DD") : null}
                                        // endDate={this.state.endDate}

                                        minDate={new Date()}
                                        selected={this.fitting.date ? moment(this.fitting.date, "YYYY-MM-DD").toDate() : null}
                                        onChange={(date) => {
                                            this.fitting.date = moment(date).format('YYYY-MM-DD');
                                            this.setState({});
                                        }}
                                        dateFormat="dd MMMM yyyy"

                                    />

                                </div>
                            </div>


                            {!this.fitting.id ?
                                <>
                                     {/*
                                    <div className="form__row">
                                        <div className="form__label">Time type</div>
                                        <div className="form__field">
                                            <BoxGroup label="" inline={true}>
                                                
                                                <RadioBox
                                                    name={'time_type'}
                                                    onChange={() => this.onTimeTypeChange(true)}
                                                    defaultChecked={true}
                                                >Auto</RadioBox>

                                                <RadioBox
                                                    name={'time_type'}
                                                    onChange={() => this.onTimeTypeChange(false)}
                                                    defaultChecked={true}
                                                >Manual</RadioBox>
                                            </BoxGroup>
                                        </div>
                                    </div>
                                    */}

                                    {/*
                                            <div ref={this.refTimeAuto}>
                                                <div className="form__row">
                                                    <div className="form__label">Fitting Time</div>
                                                    <div className="form__field">
                                                        <Select
                                                            label=""
                                                            size="small"
                                                            options={[
                                                                {
                                                                    value: 'am',
                                                                    label: 'AM'
                                                                },
                                                                {
                                                                    value: 'pm',
                                                                    label: 'PM'
                                                                },
                                                            ]}
                                                            defaultValue={this.fitting.timeType}
                                                            onChange={(e) => {
                                                                this.fitting.timeType = e.target.value
                                                            }}
                                                            errorMessage={this.lastErrors ? this.lastErrors['timeType'] : ''}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
*/}

                                </>
                                : ''}

                            {/*<div ref={this.refTimeManual} style={!this.fitting.id ? {display: 'none'} : {}}>*/}
                            <div ref={this.refTimeManual}>
                                <div className="form__row">
                                    <div className="form__label">Appointment Start</div>
                                    <div className="form__field">
                                        <Select
                                            label=""
                                            size="small"
                                            options={this.injectCustomTime(this.fitting.timeFrom)}
                                            defaultValue={this.fitting.timeFrom}
                                            onChange={(e) => {
                                                this.fitting.timeFrom = e.target.value
                                            }}
                                            errorMessage={this.lastErrors ? this.lastErrors['timeFrom'] : ''}
                                        />
                                    </div>
                                </div>
                                <div className="form__row">
                                    <div className="form__label">Appointment End</div>
                                    <div className="form__field">
                                        <Select
                                            label=""
                                            size="small"
                                            options={this.injectCustomTime(this.fitting.timeTo)}
                                            defaultValue={this.fitting.timeTo}
                                            onChange={(e) => {
                                                this.fitting.timeTo = e.target.value
                                            }}
                                            errorMessage={this.lastErrors ? this.lastErrors['timeTo'] : ''}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="form__row">
                                <div className="form__label">All windows to be cleared</div>
                                <div className="form__field">
                                    <BoxGroup label="" inline={true}>
                                        <RadioBox
                                            name={'windows_cleared'}
                                            onChange={() => {
                                                this.fitting.windows = true;
                                                this.setState({});
                                            }}
                                            defaultChecked={this.fitting.windows}
                                        >Yes</RadioBox>
                                        <RadioBox
                                            name={'windows_cleared'}
                                            onChange={() => {
                                                this.fitting.windows = false;
                                                this.setState({});
                                            }}
                                            defaultChecked={!this.fitting.windows}
                                        >No</RadioBox>
                                    </BoxGroup>
                                </div>
                            </div>

                            {this.fitting.windows === true && <div className="form__row">
                                <div className="form__label">Takedowns</div>
                                <div className="form__field"><TextField
                                    label=""
                                    size="small"
                                    value={this.fitting.takedowns}
                                    onChange={(e) => {
                                        this.fitting.takedowns = e.target.value;
                                    }}
                                    // errorMessage={this.lastErrors ? this.lastErrors['balanceDue'] : ''}
                                /></div>
                            </div>
                            }
                            <div className="form__row">
                                <div className="form__label">Balance Due</div>
                                <div className="form__field"><TextField
                                    label=""
                                    size="small"
                                    value={this.fitting.balanceDue}
                                    onChange={(e) => {
                                        this.fitting.balanceDue = e.target.value
                                    }}
                                    // errorMessage={this.lastErrors ? this.lastErrors['balanceDue'] : ''}
                                /></div>
                            </div>

                            {/*<div className="form__row">*/}
                            {/*    <div className="form__label">Total Contact Price</div>*/}
                            {/*    <div className="form__field"><TextField*/}
                            {/*        label=""*/}
                            {/*        size="small"*/}
                            {/*        value={this.fitting.contractPrice}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            this.fitting.contractPrice = e.target.value*/}
                            {/*        }}*/}
                            {/*        // errorMessage={this.lastErrors ? this.lastErrors['contractPrice'] : ''}*/}
                            {/*    /></div>*/}
                            {/*</div>*/}


                            <div className="form__row">
                                <div className="form__label">Notes</div>
                                <div className="form__fields"><TextArea
                                    label=""
                                    size="small"
                                    value={this.fitting.notes}
                                    onChange={(e) => {
                                        this.fitting.notes = e.target.value
                                    }}
                                    // errorMessage={this.lastErrors ? this.lastErrors['notes'] : ''}
                                /></div>
                            </div>

                            <div className="form__row">
                                <div className="form__label">Product location</div>
                                <div className="form__field">
                                    <Select
                                        label=""
                                        size="small"
                                        options={PRODUCT_LOCATION}
                                        defaultValue={this.fitting.productLocation}
                                        onChange={(e) => {
                                            this.fitting.productLocation = e.target.value
                                        }}
                                        errorMessage={this.lastErrors ? this.lastErrors['productLocation'] : ''}
                                    />
                                </div>
                            </div>



                            <CheckBox
                                checked={this.fitting.callBefore}
                                onChange={(e) => {
                                    this.fitting.callBefore = !!e.target.checked
                                }}
                            >Call customer before appointment</CheckBox>

                            <CheckBox
                                checked={this.fitting.fixedTime}
                                onChange={(e) => {
                                    this.fitting.fixedTime = !!e.target.checked
                                }}
                            >Fixed time</CheckBox>

                        </div>

                        <div className="item" ref={this.refThirdSide} style={{visibility: this.fitting.id ? 'visible' : 'hidden'}}>
                            {this.products ? this.products.map((product, j) => {
                                return (
                                    <React.Fragment key={j}>

                                        <div className="form__row">
                                            <div className="form__label">Indoor</div>
                                            <div className="form__field">
                                                <Select
                                                    key={product.product}
                                                    label=""
                                                    size="small"
                                                    name={'product_' + j}
                                                    options={this.state.indoorProductList}
                                                    defaultValue={product.product}
                                                    onChange={this.changeProduct}
                                                    errorMessage={this.lastErrors ? this.lastErrors['product_ind'] : ''}
                                                />
                                            </div>
                                        </div>

                                        <div className="form__row">
                                            <div className="form__label">Outdoor</div>
                                            <div className="form__field">
                                                <Select
                                                    key={product.product}
                                                    label=""
                                                    size="small"
                                                    name={'product_' + j}
                                                    options={this.state.outdoorProductList}
                                                    defaultValue={product.product}
                                                    onChange={this.changeProduct}
                                                    errorMessage={this.lastErrors ? this.lastErrors['product_out'] : ''}
                                                />
                                            </div>
                                        </div>

                                        {['6', '11'].includes(product.product) ?
                                            <div className="form__row">
                                                <div className="form__label">Other</div>
                                                <div className="form__field">
                                                    <TextField
                                                        label=""
                                                        onChange={this.changeProduct}
                                                        name={'other_' + j}
                                                        size="small"
                                                        value={product.other}
                                                    >
                                                    </TextField>
                                                </div>
                                            </div>
                                            : ''}

                                        <div className="form__row">
                                            <div className="form__label">Qty</div>
                                            <div className="form__field">
                                                <TextField
                                                    label=""
                                                    onChange={this.changeProduct}
                                                    name={'quantity_' + j}
                                                    size="small"
                                                    value={product.quantity ? product.quantity.toString() : ''}
                                                    errorMessage={this.lastErrors ? this.lastErrors['product_' + j] : ''}
                                                >
                                                </TextField>
                                            </div>
                                        </div>

                                        <div className="form__row">
                                            <div className="form__label">Type</div>
                                            <div className="form__field">
                                                <Select
                                                    label=""
                                                    size="small"
                                                    options={FITTING_TYPE}
                                                    name={'type_' + j}
                                                    defaultValue={product.type}
                                                    onChange={this.changeProduct}
                                                    errorMessage={this.lastErrors ? this.lastErrors['type_' + j] : ''}
                                                />
                                            </div>
                                        </div>


                                    </React.Fragment>
                                )
                            }) : ''}
                            <div className="form__row">
                                <div className="form__label"></div>
                                <div className="form__field"><button className="link" onClick={() => {
                                    this.products.push({
                                        product: '',
                                        quantity: 0
                                    });
                                    this.setState({})
                                }}>Add more</button></div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="panel__bottom">
                    <div className="bh bh--right">
                        <button className="btn" onClick={this.onSave}>{this.fitting.id ? 'Update' : 'Add'} Fitters Booking</button>
                        {this.fitting.id ?
                            <button
                                className="btn btn--grey"
                                onClick={() => {
                                    this.showCancelPopup(true);
                                }}
                            >Cancel Booking</button> : ''}

                        <span className="mq__lead__box">
                                <CheckBox
                                    checked={this.fitting.smsReminder}
                                    onChange={(e) => {
                                        this.fitting.smsReminder = e.target.checked;
                                    }}
                                >Send SMS Reminder</CheckBox>
                            </span>
                    </div>
                </div>


                <Modal
                    status={this.modalCancelConfirm}
                >

                    <div className="panel">
                        <div className="panel__top">
                            <div className="panel__title">Cancel Booking</div>
                        </div>
                        <div className="panel__body">
                            Are you sure you want to cancel this booking?

                        </div>
                        <div className="panel__bottom">
                            <div className="bh">
                                <button className="btn" onClick={this.onDelete}>Yes</button>
                                <button
                                    className="btn"
                                    onClick={() => {
                                        this.showCancelPopup(false);
                                    }}
                                >No
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>


            </>
        );
    }
}