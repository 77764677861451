import React from 'react';
import Popup from "../components/Popup";
import {Auth} from "./Auth";
import PropTypes from "prop-types";
import {apiRequest} from "./Globals";
import {Link} from "react-router-dom";
import Access from "./Access";
import searchIcon from '../images/seacrhIcon.svg';
import TextField from "./form/TextField";
import BoxGroup from "./form/BoxGroup";
import RadioBox from "./form/RadioBox";
import BookingService from "../service/BookingService";
import FittingService from "../service/FittingService";
import Modal from "./Modal";
import Tabs from "./form/Tabs";
import Logs from "../pages/calendar/Logs";
import moment from "moment";
import Moment from "react-moment";
import {Booking} from "./form/model/Booking";
import {Fitting} from "./form/model/Fitting";
import {ROLES} from "../config/Globals";

class Header extends React.Component {
    SEARCH_TYPE_BOOKINGS = 'bookings';
    SEARCH_TYPE_FITTINGS = 'fittings';
    SEARCH_TYPE_OLD = 'goBookingData';

    static propTypes = {
        onLogout: PropTypes.func,
    };

    state = {
        modal: false,
        searchBy: this.SEARCH_TYPE_BOOKINGS,
        logs: [],
    };

    modalStatusRep = {
        show: false
    };

    auth: Auth = new Auth();
    name: string = '';

    searchChangedTimeout = null;
    searchResultList: [] = [];
    searchInput: string = null;
    selectedEvent: {} = null;
    products: [] = [];

    fittingService: FittingService = new FittingService(() => {
        this.searchResultList = this.fittingService.list;
        this.setState({})
    });
    bookingService: BookingService = new BookingService(() => {
        this.searchResultList = this.bookingService.list;
        this.setState({})
    });

    constructor(props: P, context: any) {
        super(props, context);

        this.doLogout = this.doLogout.bind(this);

        this.auth.load();

        let nameParts;

        if (!this.auth.name) {
            nameParts = [' ', ' ']
        } else {
            nameParts = this.auth.name.split(' ');
        }

        if (nameParts.length < 2) {
            nameParts.push(' ');
        }

        this.name = nameParts[0].substr(0, 1).toUpperCase() + nameParts[1].substr(0, 1).toUpperCase();

        let searchBy = localStorage.getItem('searchBy');

        if (searchBy) {
            this.state.searchBy = searchBy;
        }

        this.loadProducts = this.loadProducts.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.loadComments = this.loadComments.bind(this);
        this.showEventInfoModal = this.showEventInfoModal.bind(this);
        this.clearSearchTimeOut = this.clearSearchTimeOut.bind(this);

        // this.fittingService.searchParams.status = [];
        // this.bookingService.searchParams.status = [];
    }

    componentDidMount(): void {
        // let search = {};
        // let searchBy = localStorage.getItem('searchBy');
        //
        // if (searchBy) {
        //     this.setState({searchBy: searchBy});
        //     this.onChangeSearch('', searchBy);
        // } else {
        //     this.bookingService.setSearchParams(search);
        // }
    }

    loadProducts(fittingId: number) {
        this.products = [];

        apiRequest('/bs/fitting/product-list', {
            'id': fittingId
        })
            .then(response => {
                if (!response || !response.custom) {
                    return;
                }

                this.products = response.custom;
                this.setState({});
            });

    }

    doLogout() {
        apiRequest('/bs/user/logout');

        let auth = new Auth();
        auth.save();
        auth.setIsInSession(false);

        window.location.href = '/';
        // if (this.props.onLogout) {
        //     this.props.onLogout();
        // }
    }

    loadComments(id: number) {
        if (!id) {
            this.setState({logs: []});
            return;
        }

        apiRequest('/bs/booking/comment-list', {
            'bookingId': id
        })
            .then(response => {
                this.setState({logs: response && response.custom ? response.custom : []})
            });
    }

    loadCsvBookings(searchText) {
        let payload = {
            searchParams: searchText,
            pagination: this.bookingService.pagination
        };

        apiRequest('/bs/booking/search', payload)
            .then(data => {
                let list = [];

                if (!data || !data.custom) {
                    return this.this.searchResultList = [];
                }

                data.custom.forEach(event => {
                    let curDate = event.appointment_date.split('.');
                    event.appointment_date = moment(`${curDate[2]}-${curDate[1]}-${curDate[0]}`).format('DD MMMM YYYY');

                    list.push({
                        id: event.id,
                        customerName: event.customer_name,
                        email: event.email,
                        phone: event.phone,
                        address: event.address,
                        timeFrom: event.time_from,
                        timeTo: event.time_to,
                        repsName: event.booked_by,
                        date: event.appointment_date,
                        product1: event.product_1,
                        product2: event.product_2,
                        product3: event.product_3,
                        product4: event.product_4,
                        overview: event.overview,
                        mobile: event.mobile,
                        fitterName: event.fitters_name,
                        customerNumber: event.customer_number,
                        comments: event.comments,
                        balanceDue: event.balance_due,
                        clearedWindows: event.all_windows_to_be_cleared,
                        systemComments: event.system_comments,
                    })
                });

                this.searchResultList = list;
                this.setState({});

            })
            .catch(error => {
                console.error(error);
            })
    }

    showEventInfoModal(event, id: number) {
        this.modalStatusRep.show = true;
        if (this.state.searchBy === this.SEARCH_TYPE_BOOKINGS) {
            this.selectedEvent = this.bookingService.getOne(id);
            // this.loadComments(id);
        }

        if (this.state.searchBy === this.SEARCH_TYPE_FITTINGS) {
            this.selectedEvent = this.fittingService.getOne(id);
            this.loadProducts(id);
        }

        if (this.state.searchBy === this.SEARCH_TYPE_OLD) {
            this.searchResultList.some(bookingEvent => {
                if (bookingEvent.id === id) {
                    this.selectedEvent = bookingEvent;
                    return true;
                }
                return false;
            })
        }

        this.setState({});
    }

    clearSearchTimeOut() {
        if (this.searchChangedTimeout) {
            clearTimeout(this.searchChangedTimeout);
        }
    }

    onChangeSearch(searchText, searchBy) {
        this.searchInput = searchText;
        this.searchResultList = [];

        let search = {
            search: this.searchInput
        };

        if (searchBy === this.SEARCH_TYPE_BOOKINGS) {
            this.bookingService.setSearchParams(search);
            this.searchResultList = this.bookingService.list;

            if (!Access.isAllowed('MeasureAndQuote', 'fullList')) {
                search.repId = this.auth.userId;
            }
        } else if (searchBy === this.SEARCH_TYPE_FITTINGS) {
            this.fittingService.setSearchParams(search);
            this.searchResultList = this.fittingService.list;

            if (!Access.isAllowed('Fitter', 'fullList')) {
                search.repId = this.auth.userId;
            }
        } else if (searchBy === this.SEARCH_TYPE_OLD) {
            this.clearSearchTimeOut();

            this.searchChangedTimeout = setTimeout(
                () => {
                    this.loadCsvBookings(search);
                    this.setState({});
                },
                800
            );
        }
    }

    switchSearchType(eventType) {
        this.searchResultList = [];
        this.searchInput = '';

        localStorage.setItem('searchBy', eventType);
        this.setState({searchBy: eventType});
    }

    render() {

        return (
            <div className="header">
                <div className="header__site">
                    <svg className="header__logo" viewBox="0 0 285.35 78.2">
                        <path
                            d="M26.48.1H36.69L62.74,45.51H50.86L45.4,35.57H16.62l-5.28,9.94H.78ZM41,27.38,31.23,8.9,21.11,27.38Z"
                            transform="translate(0 -0.1)"/>
                        <path
                            d="M69.78.1h38.91q5.28,0,7.74,2T118.9,8.9v5.54a7,7,0,0,1-2,5.29,9.76,9.76,0,0,1-5.8,2.37,9.83,9.83,0,0,1,6.07,2.77,8.33,8.33,0,0,1,2.11,5.95v5.8q0,8.9-13.38,8.89H69.78ZM80.17,7.49V18.32h24.12a8.26,8.26,0,0,0,3.65-.62c.79-.41,1.19-1.29,1.19-2.64V10.75c0-1.35-.4-2.23-1.19-2.64a8.26,8.26,0,0,0-3.65-.62Zm0,18.31V37.33h24.12a7.62,7.62,0,0,0,3.91-.75c.8-.5,1.19-1.6,1.19-3.3V29.85c0-1.7-.39-2.8-1.19-3.3a7.62,7.62,0,0,0-3.91-.75Z"
                            transform="translate(0 -0.1)"/>
                        <path
                            d="M141.69,8.19V37h28.52V27.21l10.56,2.2v5.37a20.66,20.66,0,0,1-.57,5.28,6.62,6.62,0,0,1-1.89,3.3A7.56,7.56,0,0,1,174.83,45a24.91,24.91,0,0,1-5.32.48H142.4a25.07,25.07,0,0,1-5.33-.48,7.57,7.57,0,0,1-3.47-1.67,6.7,6.7,0,0,1-1.9-3.3,20.66,20.66,0,0,1-.57-5.28V10.84a20.69,20.69,0,0,1,.57-5.29,6.79,6.79,0,0,1,1.9-3.3A7.57,7.57,0,0,1,137.07.58,25.07,25.07,0,0,1,142.4.1h27.11a24.91,24.91,0,0,1,5.32.48,7.56,7.56,0,0,1,3.48,1.67,6.7,6.7,0,0,1,1.89,3.3,20.69,20.69,0,0,1,.57,5.29v4.31l-10.56,1.67V8.19Z"
                            transform="translate(0 -0.1)"/>
                        <rect y="52.9" width="285.3" height="1.25"/>
                        <path
                            d="M130.6,65.83a6.29,6.29,0,0,1-1.89,4.82,6.75,6.75,0,0,1-4.85,1.78,12.17,12.17,0,0,1-4.26-1V53.83h1.3v8a6.16,6.16,0,0,1,4.32-1.9,5.07,5.07,0,0,1,3.88,1.62A6.07,6.07,0,0,1,130.6,65.83Zm-1.37.14a5,5,0,0,0-1.18-3.42,3.71,3.71,0,0,0-3-1.37,5.69,5.69,0,0,0-4.16,2.1v7.33a7.67,7.67,0,0,0,2.83.56,5.57,5.57,0,0,0,4-1.41A5,5,0,0,0,129.23,66Z"
                            transform="translate(0 -0.1)"/>
                        <path
                            d="M132.83,66.22a6.18,6.18,0,0,1,1.75-4.5A5.88,5.88,0,0,1,139,59.91a6.1,6.1,0,0,1,6.17,6.21,6.24,6.24,0,0,1-1.74,4.49A5.84,5.84,0,0,1,139,72.43a6,6,0,0,1-6.17-6.21Zm1.37,0a4.87,4.87,0,0,0,1.41,3.59A4.7,4.7,0,0,0,139,71.17a4.51,4.51,0,0,0,3.41-1.41,4.88,4.88,0,0,0,1.35-3.51,5,5,0,0,0-1.4-3.64,4.52,4.52,0,0,0-3.4-1.43,4.65,4.65,0,0,0-3.4,1.39A4.82,4.82,0,0,0,134.2,66.17Z"
                            transform="translate(0 -0.1)"/>
                        <path
                            d="M147.46,66.22a6.22,6.22,0,0,1,1.75-4.5,5.9,5.9,0,0,1,4.42-1.81,6.08,6.08,0,0,1,6.17,6.21,6.24,6.24,0,0,1-1.74,4.49,5.84,5.84,0,0,1-4.43,1.82,6,6,0,0,1-6.17-6.21Zm1.37,0a4.87,4.87,0,0,0,1.41,3.59,4.7,4.7,0,0,0,3.43,1.41,4.49,4.49,0,0,0,3.41-1.41,4.88,4.88,0,0,0,1.35-3.51,5,5,0,0,0-1.4-3.64,4.51,4.51,0,0,0-3.4-1.43,4.65,4.65,0,0,0-3.4,1.39A4.86,4.86,0,0,0,148.83,66.17Z"
                            transform="translate(0 -0.1)"/>
                        <path d="M162.56,53.83h1.29V72.18h-1.29Zm1.47,12,6.77-5.68h1.7l-6.7,5.68,7.73,6.34h-1.78Z"
                              transform="translate(0 -0.1)"/>
                        <path d="M174.37,53.94h1.29v2.21h-1.29Zm0,6.22h1.29v12h-1.29Z" transform="translate(0 -0.1)"/>
                        <path
                            d="M179.23,72.18v-12h1.3v2.28a5.83,5.83,0,0,1,1.78-1.87,4.14,4.14,0,0,1,2.34-.66,4.46,4.46,0,0,1,3.26,1.25,5,5,0,0,1,1.26,3.69v7.33h-1.29v-7c0-2.7-1.1-4-3.31-4a4.7,4.7,0,0,0-4,2.58v8.42Z"
                            transform="translate(0 -0.1)"/>
                        <path
                            d="M194.87,72.17c-1.35-.32-2-.89-2-1.71a1.48,1.48,0,0,1,.58-1.16,4.85,4.85,0,0,1,1.74-.86A3.78,3.78,0,0,1,193,67.13a4,4,0,0,1-1-2.74,3.94,3.94,0,0,1,1.29-3,5.41,5.41,0,0,1,3.81-1.19h4.8v1.05h-2.76a3.29,3.29,0,0,1,1.21,1.38,3.8,3.8,0,0,1,.48,1.8,4.09,4.09,0,0,1-.87,2.66,5,5,0,0,1-3,1.56l-1.46.41c-1,.28-1.5.72-1.5,1.3a1,1,0,0,0,.51.85,6.76,6.76,0,0,0,2.36.43l1.39.08q3.58.28,3.58,3.1a2.87,2.87,0,0,1-1.49,2.65,7.79,7.79,0,0,1-4,.87,7.41,7.41,0,0,1-3.68-.79,2.61,2.61,0,0,1-1.52-2.37,2.44,2.44,0,0,1,.77-1.8A5.58,5.58,0,0,1,194.87,72.17ZM192.52,75c0,1.46,1.36,2.2,4.09,2.2a6.4,6.4,0,0,0,2.9-.59,1.88,1.88,0,0,0,1.17-1.74c0-1.38-1.29-2.07-3.89-2.07C194,72.82,192.52,73.55,192.52,75Zm.75-10.67a3.32,3.32,0,0,0,.87,2.31,3,3,0,0,0,2.28,1,3.29,3.29,0,0,0,2.3-.82,3.05,3.05,0,0,0,.91-2.39,2.92,2.92,0,0,0-1-2.25,3.21,3.21,0,0,0-2.23-.91,3,3,0,0,0-2.24.91A3.05,3.05,0,0,0,193.27,64.35Z"
                            transform="translate(0 -0.1)"/>
                        <path
                            d="M210.94,70.11a8,8,0,0,0,3.82,1.06,2.78,2.78,0,0,0,1.88-.61,1.89,1.89,0,0,0,.71-1.5c0-.85-.65-1.54-1.94-2.06l-1.48-.62Q211,65.21,211,63.19a2.9,2.9,0,0,1,1.15-2.33,4.22,4.22,0,0,1,2.78-1A6.87,6.87,0,0,1,218.2,61v1.45a6.5,6.5,0,0,0-3.34-1.35,2.75,2.75,0,0,0-1.89.63,1.84,1.84,0,0,0-.71,1.42,1.56,1.56,0,0,0,.57,1.22,5.79,5.79,0,0,0,1.7,1l1.1.48a6,6,0,0,1,2.25,1.33,2.81,2.81,0,0,1,.71,2,2.88,2.88,0,0,1-1.22,2.42,4.5,4.5,0,0,1-2.75.89,7.05,7.05,0,0,1-3.68-1.08Z"
                            transform="translate(0 -0.1)"/>
                        <path d="M219.54,60.16h1.35l4.37,8.36,4.22-8.36h1.31L221.72,78.3h-1.31l4.24-8.5Z"
                              transform="translate(0 -0.1)"/>
                        <path
                            d="M231.76,70.11a7.92,7.92,0,0,0,3.82,1.06,2.75,2.75,0,0,0,1.87-.61,1.86,1.86,0,0,0,.71-1.5c0-.85-.64-1.54-1.93-2.06l-1.48-.62q-2.91-1.17-2.91-3.19A2.92,2.92,0,0,1,233,60.86a4.24,4.24,0,0,1,2.78-1A6.86,6.86,0,0,1,239,61v1.45a6.49,6.49,0,0,0-3.35-1.35,2.72,2.72,0,0,0-1.88.63,1.81,1.81,0,0,0-.71,1.42,1.55,1.55,0,0,0,.56,1.22,6.07,6.07,0,0,0,1.7,1l1.11.48a5.85,5.85,0,0,1,2.24,1.33,2.77,2.77,0,0,1,.71,2,2.87,2.87,0,0,1-1.21,2.42,4.52,4.52,0,0,1-2.75.89,7,7,0,0,1-3.68-1.08Z"
                            transform="translate(0 -0.1)"/>
                        <path
                            d="M239.69,61.21V60.16H243V57.29l.88-1.14h.41v4h3.38v1.05h-3.38v8.32a1.55,1.55,0,0,0,.45,1.28,2.09,2.09,0,0,0,1.33.36,5.69,5.69,0,0,0,2.23-.6v1.24a5,5,0,0,1-2.5.62,3,3,0,0,1-2.05-.66A2.37,2.37,0,0,1,243,69.9V61.21Z"
                            transform="translate(0 -0.1)"/>
                        <path
                            d="M250.81,65.74a5,5,0,0,0,1.37,4,4.69,4.69,0,0,0,3.43,1.4,5.82,5.82,0,0,0,2.16-.38,11,11,0,0,0,2.29-1.43v1.45a7.22,7.22,0,0,1-4.66,1.62,5.48,5.48,0,0,1-4.34-1.9,6.62,6.62,0,0,1-1.65-4.5A6.35,6.35,0,0,1,251,61.69a5,5,0,0,1,4-1.78,5.1,5.1,0,0,1,3.84,1.58,5.86,5.86,0,0,1,1.51,4.25Zm.16-1.06h7.76c-.49-2.34-1.76-3.5-3.8-3.5a3.72,3.72,0,0,0-2.61,1A4.64,4.64,0,0,0,251,64.68Z"
                            transform="translate(0 -0.1)"/>
                        <path
                            d="M262.9,60.16h1.29v2.28A5.87,5.87,0,0,1,266,60.49a3.8,3.8,0,0,1,2-.58,4.14,4.14,0,0,1,4.06,3,7.33,7.33,0,0,1,2-2.25,4.09,4.09,0,0,1,5.28.6,5,5,0,0,1,1.22,3.54v7.43h-1.29V65.3a4.69,4.69,0,0,0-.88-3,2.89,2.89,0,0,0-2.38-1.09,3.06,3.06,0,0,0-1.84.61A7.79,7.79,0,0,0,272.42,64v8.21h-1.3V65.26a4.57,4.57,0,0,0-.89-3.07,3,3,0,0,0-2.42-1q-2,0-3.62,2.58v8.42H262.9Z"
                            transform="translate(0 -0.1)"/>
                        <path d="M283.6,69.56h1.75v2.62H283.6Z" transform="translate(0 -0.1)"/>
                    </svg>
                </div>
                <div>
                    {[ROLES.showroom, ROLES.commercial, ROLES.admin].includes(this.auth.role) && <Popup
                        trigger={
                            <img src={searchIcon} alt={'searchIcon'}/>
                        }
                        action={'click'}
                        className={'popup__search'}
                        closeOnClick={true}
                    >
                        <div className="popup__search__content">

                            <div className="ih">
                                <TextField
                                    label="Search Name / Location / Phone / Date / Consultant or Fitters Name"
                                    size="small"
                                    autoFocus
                                    value={this.searchInput}
                                    onChange={(e) => this.onChangeSearch(e.target.value, this.state.searchBy)}
                                />
                            </div>

                            <div className="ih">
                                <BoxGroup label="" inline={true}>
                                    <RadioBox
                                        name={'type'}
                                        onChange={() => {
                                            this.switchSearchType(this.SEARCH_TYPE_BOOKINGS);
                                        }}
                                        defaultChecked={this.state.searchBy === this.SEARCH_TYPE_BOOKINGS}
                                    >M&Q</RadioBox>
                                    <RadioBox
                                        name={'type'}
                                        onChange={() => {
                                            this.switchSearchType(this.SEARCH_TYPE_FITTINGS);
                                        }}
                                        defaultChecked={this.state.searchBy === this.SEARCH_TYPE_FITTINGS}
                                    >Fitting</RadioBox>
                                    <RadioBox
                                        name={'type'}
                                        onChange={() => {
                                            this.switchSearchType(this.SEARCH_TYPE_OLD);
                                        }}
                                        defaultChecked={this.state.searchBy === this.SEARCH_TYPE_OLD}
                                    >Go Bookings</RadioBox>
                                </BoxGroup>
                            </div>

                            <table className="tbl__data__list">
                                <tbody>
                                <tr>
                                    <th>Customer Name</th>
                                    <th>Location</th>
                                    <th>Phone</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Type</th>
                                    <th>Assigned To</th>
                                </tr>

                                {this.searchResultList.map(event => {
                                    let productSeparator = '';

                                    if (this.state.searchBy === this.SEARCH_TYPE_FITTINGS) {
                                        return (
                                            <tr key={event.id}>
                                                <td><span
                                                    onClick={(e) => this.showEventInfoModal(e, event.id)}
                                                    className="link"
                                                >{event.booking.firstName + ' ' + event.booking.lastName}</span>
                                                </td>
                                                <td>{event.booking.address}</td>
                                                <td>{event.booking.phone}</td>
                                                <td>{moment(event.date).format('DD MMMM YYYY')}</td>
                                                <td>{moment(event.date + ' ' + event.timeFrom).format('a').toUpperCase()}</td>
                                                <td>{event.productList ? event.productList.map(product => {
                                                    let result = productSeparator + product.type_name;
                                                    productSeparator = ', ';

                                                    return result;
                                                }) : ''}</td>
                                                <td>{event.fitterName}</td>
                                            </tr>
                                        );
                                    }

                                    if (this.state.searchBy === this.SEARCH_TYPE_BOOKINGS) {
                                        return (
                                            <tr key={event.id}>
                                                <td><span onClick={(e) => this.showEventInfoModal(e, event.id)}
                                                          className="link">{event.firstName + ' ' + event.lastName}</span>
                                                </td>
                                                <td>{event.address} {event.suburb}</td>
                                                <td>{event.phone}</td>
                                                <td>{moment(event.date).format('DD MMMM YYYY')}</td>
                                                <td>
                                                    <div className="booking__list__time"><Moment
                                                        format={'LT'}>{event.date + ' ' + event.timeFrom}</Moment> to <Moment
                                                        format={'LT'}>{event.date + ' ' + event.timeTo}</Moment>
                                                    </div>
                                                </td>
                                                <td></td>
                                                <td>{event.repsName}</td>
                                            </tr>
                                        );
                                    }

                                    //'goBookingData'
                                    return (

                                        <tr key={event.id}>
                                            <td><span
                                                onClick={(e) => this.showEventInfoModal(e, event.id)}
                                                className="link"
                                            >{event.customerName}</span>
                                            </td>
                                            <td>{event.address}</td>
                                            <td>{event.phone}</td>
                                            <td>{event.date}</td>
                                            <td>{event.timeFrom} - {event.timeTo}</td>
                                            <td></td>
                                            <td>{event.repsName}</td>
                                        </tr>

                                    );
                                })}
                                </tbody>
                            </table>

                        </div>
                    </Popup>}

                </div>


                <Modal
                    status={this.modalStatusRep}
                    className="modal__booking__information"
                >
                    <div className="abc__modal__content">
                        <Tabs
                            items={
                                [
                                    'Booking Information',
                                    'Logs',
                                ]
                            }
                        >

                            <div className="panel">
                                <div className="panel__top">
                                    <div className="panel__title">Fitting Information</div>
                                </div>
                                <div className="panel__body">
                                    {this.selectedEvent && this.selectedEvent instanceof Fitting ?
                                        <div className="modal__booking__information__column">

                                            <div className="item">
                                                <div className="form__row">
                                                    <div className="form__label">Customer's Name</div>
                                                    <div
                                                        className="form__field">{this.selectedEvent.booking.firstName} {this.selectedEvent.booking.lastName}</div>
                                                </div>
                                                <div className="form__row">
                                                    <div className="form__label">Email</div>
                                                    <div
                                                        className="form__field">{this.selectedEvent.booking.email}</div>
                                                </div>
                                                <div className="form__row">
                                                    <div className="form__label">Company</div>
                                                    <div
                                                        className="form__field">{this.selectedEvent.booking.company}</div>
                                                </div>
                                                <div className="form__row">
                                                    <div className="form__label">Phone</div>
                                                    <div
                                                        className="form__field">{this.selectedEvent.booking.phone}</div>
                                                </div>
                                                <div className="form__row">
                                                    <div className="form__label">Call Customer Before Appointment</div>
                                                    <div
                                                        className="form__field">{this.selectedEvent.booking.callBefore ? 'YES' : 'NO'}</div>
                                                </div>
                                                {/*<div className="form__row">*/}
                                                {/*    <div className="form__label">Unit No.</div>*/}
                                                {/*    <div*/}
                                                {/*        className="form__field">{this.selectedEvent.booking.unitNo}</div>*/}
                                                {/*</div>*/}
                                                <div className="form__row">
                                                    <div className="form__label">Address</div>
                                                    <div
                                                        className="form__field">{this.selectedEvent.booking.address}, {this.selectedEvent.booking.suburb}, {this.selectedEvent.booking.postalCode}</div>
                                                </div>

                                                <div className="form__row">
                                                    <div className="form__label">Date</div>
                                                    <div
                                                        className="form__field">{moment(this.selectedEvent.date).format('DD MMMM YYYY dddd')}</div>
                                                </div>

                                                <div className="form__row">
                                                    <div className="form__label">Appointment Start</div>
                                                    <div className="form__field">
                                                        {moment(this.selectedEvent.date + ' ' + this.selectedEvent.timeFrom).format('h:mm a').toUpperCase()} - {moment(this.selectedEvent.date + ' ' + this.selectedEvent.timeTo).format('h:mm a').toUpperCase()}
                                                    </div>
                                                </div>

                                                <div className="form__row">
                                                    <div className="form__label">SAP No.</div>
                                                    <div className="form__field">{this.selectedEvent.customerNo}</div>
                                                </div>
                                                <div className="form__row">
                                                    <div className="form__label">All windows to be cleared</div>
                                                    <div
                                                        className="form__field">{this.selectedEvent.windows ? 'YES' : 'NO'}</div>
                                                </div>
                                                <div className="form__row">
                                                    <div className="form__label">Balance Due</div>
                                                    <div className="form__field">{this.selectedEvent.balanceDue}</div>
                                                </div>

                                                {/*<div className="form__row">*/}
                                                {/*    <div className="form__label">Contract Price</div>*/}
                                                {/*    <div className="form__field">{this.selectedEvent.contractPrice}</div>*/}
                                                {/*</div>*/}

                                                <div className="form__row">
                                                    <div className="form__label">Product Location</div>
                                                    <div
                                                        className="form__fields">{this.selectedEvent.productLocationText}</div>
                                                </div>

                                                <div className="form__row">
                                                    <div className="form__label">Send SMS Reminder</div>
                                                    <div
                                                        className="form__field">{this.selectedEvent.smsReminder ? 'YES' : 'NO'}</div>
                                                </div>

                                                <div className="form__row">
                                                    <div className="form__label">Notes</div>
                                                    <div className="form__fields">{this.selectedEvent.notes}</div>

                                                </div>

                                                <div className="form__row">
                                                    <div className="form__label">Products</div>
                                                    {this.products.map((product) => {
                                                        return (
                                                            <div
                                                                key={product.id}>{product.quantity} {product.product ? product.product : product.short_name}({product.name})</div>
                                                        )
                                                    })}
                                                </div>

                                            </div>

                                        </div> : this.selectedEvent && this.selectedEvent instanceof Booking ?
                                            <div className="modal__booking__information__column">

                                                <div className="item">
                                                    <div className="form__row">
                                                        <div className="form__label">Customer's Name</div>
                                                        <div
                                                            className="form__field">{this.selectedEvent.firstName} {this.selectedEvent.lastName}</div>
                                                    </div>
                                                    <div className="form__row">
                                                        <div className="form__label">Email</div>
                                                        <div
                                                            className="form__field">{this.selectedEvent.email}</div>
                                                    </div>
                                                    <div className="form__row">
                                                        <div className="form__label">Company</div>
                                                        <div
                                                            className="form__field">{this.selectedEvent.company}</div>
                                                    </div>
                                                    <div className="form__row">
                                                        <div className="form__label">Phone</div>
                                                        <div
                                                            className="form__field">{this.selectedEvent.phone}</div>
                                                    </div>
                                                    <div className="form__row">
                                                        <div className="form__label">Call Customer Before
                                                            Appointment
                                                        </div>
                                                        <div
                                                            className="form__field">{this.selectedEvent.callBefore ? 'YES' : 'NO'}</div>
                                                    </div>

                                                    <div className="form__row">
                                                        <div className="form__label">Unit No.</div>
                                                        <div
                                                            className="form__field">{this.selectedEvent.unitNo}</div>
                                                    </div>

                                                    <div className="form__row">
                                                        <div className="form__label">This is a M&Q Lead</div>
                                                        <div
                                                            className="form__field">{this.selectedEvent.isLead ? 'YES' : 'NO'}</div>
                                                    </div>

                                                    <div className="form__row">
                                                        <div className="form__label">Address</div>
                                                        <div
                                                            className="form__field">{this.selectedEvent.address}, {this.selectedEvent.suburb}, {this.selectedEvent.postalCode}</div>
                                                    </div>

                                                    <div className="form__row">
                                                        <div className="form__label">Date</div>
                                                        <div
                                                            className="form__field">{moment(this.selectedEvent.date).format('DD MMMM YYYY dddd')}</div>
                                                    </div>

                                                    <div className="form__row">
                                                        <div className="form__label">Send SMS Reminder</div>
                                                        <div
                                                            className="form__field">{this.selectedEvent.smsReminder ? 'YES' : 'NO'}</div>
                                                    </div>

                                                    <div className="form__row">
                                                        <div className="form__label">Qty: Blinds</div>
                                                        <div
                                                            className="form__field">{this.selectedEvent.blinds ? this.selectedEvent.blinds : 0}</div>
                                                    </div>
                                                    <div className="form__row">
                                                        <div className="form__label">Qty: Indoor Shutters</div>
                                                        <div
                                                            className="form__field">{this.selectedEvent.shutters ? this.selectedEvent.shutters : 0}</div>
                                                    </div>
                                                    <div className="form__row">
                                                        <div className="form__label">Qty: Curtains</div>
                                                        <div
                                                            className="form__field">{this.selectedEvent.curtains ? this.selectedEvent.curtains : 0}</div>
                                                    </div>
                                                    <div className="form__row">
                                                        <div className="form__label">Qty: Outdoor</div>
                                                        <div
                                                            className="form__field">{this.selectedEvent.outerBlinds ? this.selectedEvent.outerBlinds : 0}</div>
                                                    </div>

                                                    <div className="form__row">
                                                        <div className="form__label">Qty: Security doors & window screens</div>
                                                        <div
                                                            className="form__field">{this.selectedEvent.secure ? this.selectedEvent.secure : 0}</div>
                                                    </div>

                                                    <div className="form__row">
                                                        <div className="form__label">Time</div>
                                                        <div className="form__field">
                                                            {moment(this.selectedEvent.date + ' ' + this.selectedEvent.timeFrom).format('h:mm a').toUpperCase()} - {moment(this.selectedEvent.date + ' ' + this.selectedEvent.timeTo).format('h:mm a').toUpperCase()}
                                                        </div>
                                                    </div>

                                                    <div className="form__row">
                                                        <div className="form__label">Notes</div>
                                                        <div
                                                            className="form__fields">{this.selectedEvent.message}</div>
                                                    </div>
                                                </div>

                                            </div> : this.selectedEvent ?

                                                <div className="modal__booking__information__column">

                                                    <div className="item">
                                                        <div className="form__row">
                                                            <div className="form__label">Customer's Name</div>
                                                            <div
                                                                className="form__field">{this.selectedEvent.customerName}</div>
                                                        </div>
                                                        <div className="form__row">
                                                            <div className="form__label">Booked By</div>
                                                            <div
                                                                className="form__field">{this.selectedEvent.repsName}</div>
                                                        </div>
                                                        <div className="form__row">
                                                            <div className="form__label">Email</div>
                                                            <div
                                                                className="form__field">{this.selectedEvent.email}</div>
                                                        </div>
                                                        <div className="form__row">
                                                            <div className="form__label">Phone</div>
                                                            <div
                                                                className="form__field">{this.selectedEvent.phone}</div>
                                                        </div>
                                                        <div className="form__row">
                                                            <div className="form__label">Address</div>
                                                            <div
                                                                className="form__field">{this.selectedEvent.address}</div>
                                                        </div>

                                                        <div className="form__row">
                                                            <div className="form__label">Date</div>
                                                            <div
                                                                className="form__field">{this.selectedEvent.date}</div>
                                                        </div>

                                                        <div className="form__row">
                                                            <div className="form__label">Qty: product 1</div>
                                                            <div
                                                                className="form__field">{this.selectedEvent.product1 ? this.selectedEvent.product1 : 0}</div>
                                                        </div>
                                                        <div className="form__row">
                                                            <div className="form__label">Qty: Product 2</div>
                                                            <div
                                                                className="form__field">{this.selectedEvent.product2 ? this.selectedEvent.product2 : 0}</div>
                                                        </div>
                                                        <div className="form__row">
                                                            <div className="form__label">Qty: Product 3</div>
                                                            <div
                                                                className="form__field">{this.selectedEvent.product3 ? this.selectedEvent.product3 : 0}</div>
                                                        </div>
                                                        <div className="form__row">
                                                            <div className="form__label">Qty: Product 4</div>
                                                            <div
                                                                className="form__field">{this.selectedEvent.product4 ? this.selectedEvent.product4 : 0}</div>
                                                        </div>

                                                        <div className="form__row">
                                                            <div className="form__label">Time</div>
                                                            <div className="form__field">
                                                                {this.selectedEvent.date} at {this.selectedEvent.timeFrom} - {this.selectedEvent.timeTo}
                                                            </div>
                                                        </div>

                                                        <div className="form__row">
                                                            <div className="form__label">Overview</div>
                                                            <div
                                                                className="form__fields">{this.selectedEvent.overview}</div>
                                                        </div>

                                                        <div className="form__row">
                                                            <div className="form__label">All Windows To Be Cleared</div>
                                                            <div
                                                                className="form__fields">{this.selectedEvent.clearedWindows}</div>
                                                        </div>

                                                        <div className="form__row">
                                                            <div className="form__label">Fitter Name</div>
                                                            <div
                                                                className="form__fields">{this.selectedEvent.fitterName}</div>
                                                        </div>

                                                        <div className="form__row">
                                                            <div className="form__label">Customer Number</div>
                                                            <div
                                                                className="form__fields">{this.selectedEvent.customerNumber}</div>
                                                        </div>

                                                        <div className="form__row">
                                                            <div className="form__label">Balance Due</div>
                                                            <div
                                                                className="form__fields">{this.selectedEvent.balanceDue}</div>
                                                        </div>

                                                        <div className="form__row">
                                                            <div className="form__label">Comments</div>
                                                            <div
                                                                className="form__fields">{this.selectedEvent.comments}</div>
                                                        </div>

                                                        <div className="form__row">
                                                            <div className="form__label">System Comments</div>
                                                            <div
                                                                className="form__fields">{this.selectedEvent.systemComments}</div>
                                                        </div>
                                                    </div>

                                                </div> : ''}

                                </div>

                            </div>
                            <div>
                                <div className="panel">
                                    <div className="panel__top">
                                        <div className="panel__title">Logs</div>
                                    </div>
                                    <div className="panel__body">
                                        <Logs
                                            calendarEvent={this.state.searchBy === this.SEARCH_TYPE_BOOKINGS ? {booking: this.selectedEvent} : {fitting: this.selectedEvent}}/>
                                    </div>

                                </div>
                            </div>
                        </Tabs>

                    </div>

                </Modal>


                <div className="header__account">
                    <Popup
                        // container={'header__account'}
                        trigger={
                            <div className="header__account__nav">{this.name}</div>
                        }
                        action={'click'}
                        className={'menu__dots'}
                        closeOnClick={false}
                    >
                        <div className="menu__dots__dropdown">
                            <div className="menu__dots__list">
                                <div className="menu__dots__list">
                                    {Access.isAllowed('TodaysRun', 'view') ?
                                        <div className="menu__dots__list__item"><Link to="/todays-run">Today's
                                            Run</Link></div>
                                        : ''
                                    }
                                    {Access.isAllowed('Fitter', 'view') ?
                                        <div className="menu__dots__list__item"><Link to="/fitting-todays-run">Today's
                                            Run</Link></div>
                                        : ''
                                    }

                                    {Access.isAllowed('MeasureAndQuote', 'view') ?
                                        <div className="menu__dots__list__item"><Link
                                            to="/measure-and-quote">Measure &amp; Quote</Link></div>
                                        : ''
                                    }
                                    {Access.isAllowed('Fitter', 'view') ?
                                        <div className="menu__dots__list__item"><Link to="/fittings">Fittings</Link>
                                        </div>
                                        : ''
                                    }

                                    <div className="menu__dots__list__item"><Link to="/availability">Availability</Link>
                                    </div>
                                </div>
                                <div className="menu__dots__list__item" onClick={this.doLogout}>Logout</div>
                            </div>
                        </div>
                    </Popup>

                </div>
            </div>

        );
    }
}

export default Header;