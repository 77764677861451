export const TIMES = {
    '07:00': '7 AM', '07:15': '7.15 AM', '07:30': '7.30 AM', '07:45': '7.45 AM',
    '08:00': '8 AM', '08:15': '8.15 AM', '08:30': '8.30 AM', '08:45': '8.45 AM',
    '09:00': '9 AM', '09:15': '9.15 AM', '09:30': '9.30 AM', '09:45': '9.45 AM',
    '10:00': '10 AM', '10:15': '10.15 AM', '10:30': '10.30 AM', '10:45': '10.45 AM',
    '11:00': '11 AM', '11:15': '11.15 AM', '11:30': '11.30 AM', '11:45': '11.45 AM',
    '12:00': '12 AM', '12:15': '12.15 AM', '12:30': '12.30 AM', '12:45': '12.45 AM',
    '13:00': '1 PM', '13:15': '1.15 PM', '13:30': '1.30 PM', '13:45': '1.45 PM',
    '14:00': '2 PM', '14:15': '2.15 PM', '14:30': '2.30 PM', '14:45': '2.45 PM',
    '15:00': '3 PM', '15:15': '3.15 PM', '15:30': '3.30 PM', '15:45': '3.45 PM',
    '16:00': '4 PM', '16:15': '4.15 PM', '16:30': '4.30 PM', '16:45': '4.45 PM',
    '17:00': '5 PM', '17:15': '5.15 PM', '17:30': '5.30 PM', '17:45': '5.45 PM',
    '18:00': '6 PM', '18:15': '6.15 PM', '18:30': '6.30 PM', '18:45': '6.45 PM',
    '19:00': '7 PM'
};

export const DROPDOWN_TIME_VALUES = {
    '06:00:00': '6:00am', '06:15:00': '6:15am', '06:30:00': '6:30am', '06:45:00': '6:45am',
    '07:00:00': '7:00am', '07:15:00': '7:15am', '07:30:00': '7:30am', '07:45:00': '7:45am',
    '08:00:00': '8:00am', '08:15:00': '8:15am', '08:30:00': '8:30am', '08:45:00': '8:45am',
    '09:00:00': '9:00am', '09:15:00': '9:15am', '09:30:00': '9:30am', '09:45:00': '9:45am',
    '10:00:00': '10:00am', '10:15:00': '10:15am', '10:30:00': '10:30am', '10:45:00': '10:45am',
    '11:00:00': '11:00am', '11:15:00': '11:15am', '11:30:00': '11:30am', '11:45:00': '11:45am',
    '12:00:00': '12:00pm', '12:15:00': '12:15pm', '12:30:00': '12:30pm', '12:45:00': '12:45pm',
    '13:00:00': '1:00pm', '13:15:00': '1:15pm', '13:30:00': '1:30pm', '13:45:00': '1:45pm',
    '14:00:00': '2:00pm', '14:15:00': '2:15pm', '14:30:00': '2:30pm', '14:45:00': '2:45pm',
    '15:00:00': '3:00pm', '15:15:00': '3:15pm', '15:30:00': '3:30pm', '15:45:00': '3:45pm',
    '16:00:00': '4:00pm', '16:15:00': '4:15pm', '16:30:00': '4:30pm', '16:45:00': '4:45pm',
    '17:00:00': '5:00pm', '17:15:00': '5:15pm', '17:30:00': '5:30pm', '17:45:00': '5:45pm',
    '18:00:00': '6:00pm', '18:15:00': '6:15pm', '18:30:00': '6:30pm', '18:45:00': '6:45pm',
    '19:00:00': '7:00pm', '19:15:00': '7:15pm', '19:30:00': '7:30pm', '19:45:00': '7:45pm', '20:00:00': '8:00pm'
};

export const DROPDOWN_TIMES = [
    // {value: '06:00:00', label: '6:00am'},
    // {value: '06:15:00', label: '6:15am'},
    // {value: '06:30:00', label: '6:30am'},
    // {value: '06:45:00', label: '6:45am'},
    {value: '07:00:00', label: '7:00am'},
    {value: '07:15:00', label: '7:15am'},
    {value: '07:30:00', label: '7:30am'},
    {value: '07:45:00', label: '7:45am'},
    {value: '08:00:00', label: '8:00am'},
    {value: '08:15:00', label: '8:15am'},
    {value: '08:30:00', label: '8:30am'},
    {value: '08:45:00', label: '8:45am'},
    {value: '09:00:00', label: '9:00am'},
    {value: '09:15:00', label: '9:15am'},
    {value: '09:30:00', label: '9:30am'},
    {value: '09:45:00', label: '9:45am'},
    {value: '10:00:00', label: '10:00am'},
    {value: '10:15:00', label: '10:15am'},
    {value: '10:30:00', label: '10:30am'},
    {value: '10:45:00', label: '10:45am'},
    {value: '11:00:00', label: '11:00am'},
    {value: '11:15:00', label: '11:15am'},
    {value: '11:30:00', label: '11:30am'},
    {value: '11:45:00', label: '11:45am'},
    {value: '12:00:00', label: '12:00pm'},
    {value: '12:15:00', label: '12:15pm'},
    {value: '12:30:00', label: '12:30pm'},
    {value: '12:45:00', label: '12:45pm'},
    {value: '13:00:00', label: '1:00pm'},
    {value: '13:15:00', label: '1:15pm'},
    {value: '13:30:00', label: '1:30pm'},
    {value: '13:45:00', label: '1:45pm'},
    {value: '14:00:00', label: '2:00pm'},
    {value: '14:15:00', label: '2:15pm'},
    {value: '14:30:00', label: '2:30pm'},
    {value: '14:45:00', label: '2:45pm'},
    {value: '15:00:00', label: '3:00pm'},
    {value: '15:15:00', label: '3:15pm'},
    {value: '15:30:00', label: '3:30pm'},
    {value: '15:45:00', label: '3:45pm'},
    {value: '16:00:00', label: '4:00pm'},
    {value: '16:15:00', label: '4:15pm'},
    {value: '16:30:00', label: '4:30pm'},
    {value: '16:45:00', label: '4:45pm'},
    {value: '17:00:00', label: '5:00pm'},
    {value: '17:15:00', label: '5:15pm'},
    {value: '17:30:00', label: '5:30pm'},
    {value: '17:45:00', label: '5:45pm'},
    {value: '18:00:00', label: '6:00pm'},
    {value: '18:15:00', label: '6:15pm'},
    {value: '18:30:00', label: '6:30pm'},
    {value: '18:45:00', label: '6:45pm'},
    // {value: '19:00:00', label: '7:00pm'},
    // {value: '19:15:00', label: '7:15pm'},
    // {value: '19:30:00', label: '7:30pm'},
    // {value: '19:45:00', label: '7:45pm'},
    // {value: '20:00:00', label: '8:00pm'},
];

export const WORKING_HOURS = {
    default: {
        enabled: true,
        from: '08:00:00',
        to: '16:00:00'
    },
    // uncomment to specify for certain day
    // days: {
    //     '1': {from: '12:30:00', to: '14:00:00', enabled: false},
    //     // '5': {from: '09:00:00', to: '17:00:00'},
    //     '6': {enabled: false},
    // }
};

export const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

/**
 * Array with default working times for the week
 *
 * @returns {{}}
 */
export function getDefaultTimes() {
    let result = {};

    for (let i=0; i<7; i++) {
        result[i] = {};

        if (WORKING_HOURS.days && WORKING_HOURS.days[i]) {
            Object.assign(result[i], WORKING_HOURS.days[i])
        } else {
            Object.assign(result[i], WORKING_HOURS.default);
        }

        if (result[i].enabled === undefined) {
            result[i].enabled = true;
        }

        result[i].x = 'def-' + Math.random();
    }

    return result;
}