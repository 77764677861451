import React, {Component} from 'react';
import PropTypes from "prop-types";

export default class Popup extends Component {
    static propTypes = {
        action: PropTypes.oneOf(['click', 'hover']),
        // trigger: PropTypes.func,
        closeOnClick: PropTypes.bool,
    };

    state = {
        display: false,
    };

    constructor(props) {
        super(props);

        this.action = 'click';
        this.allowedActions = ['click', 'hover'];

        this.refMain = React.createRef();
        this.refTrigger = React.createRef();

        this.hidePopup = this.hidePopup.bind(this);
        this.doClick = this.doClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.getParent = this.getParent.bind(this);
    }

    hidePopup() {
        if (this.props.closeOnClick) {
            return;
        }
        this.setState({display: false});
    }

    onMouseOut(e) {
        if (this.action !== 'hover') {
            return;
        }

        this.hidePopup();
    }

    onMouseEnter(e) {
        if (this.action !== 'hover') {
            return;
        }

        this.setState({display: true});
    }

    doClick(e) {
        if (this.action !== 'click') {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        if (this.state.display) {
            return;
        }

        this.setState({display: !this.state.display});
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        this.getParent(event.target);
        if (!this.refMain.current.contains(event.target) && !this.refTrigger.current.contains(event.target)) {
            this.setState({display: false});
        }
    }

    getParent(node) {
        if (node.parentNode) {
            // удаляем элемент из дерева
            this.getParent(node.parentNode);
        }
    }

    render() {
        if (!this.props.trigger) {
            throw Error("Trigger is missing");
        }

        const {trigger, container, action, className, closeOnClick, ...rest} = this.props;

        if (action) {
            if (this.allowedActions.indexOf(action) === -1) {
                throw Error('Invalid action. Allowed only: ' + this.allowedActions.join(','))
            }

            this.action = action;
        }

        // const CustomTag = `h${this.props.priority}`;
        const CustomTag = container ? `${container}` : `div`;

        let triggerElement = React.cloneElement(
            trigger,
            action === 'hover'
                ? {
                    onMouseOver: this.onMouseEnter,
                    ref: this.refTrigger
                }
                : {
                    onClick: this.doClick,
                    ref: this.refTrigger
                }
        );

        return (
            <CustomTag
                // onMouseOver={this.showPopup}
                onMouseLeave={this.onMouseOut}
                onClick={this.hidePopup}
                className={className + (this.state.display ? ' abc__toggle abc__toggle--open' : '')}
                // style={{background:"blue", padding: "5px"}}
                ref={this.refMain}
                {...rest}
            >
                {triggerElement}
                {/*<p style={{background:"red"}}>Something separate the trigger and content</p>*/}
                {this.state.display ? this.props.children : ''}
            </CustomTag>
        );
    }
};
