import {Booking} from "./form/model/Booking";
import {User} from "./form/model/User";
import {Fitting} from "./form/model/Fitting";

export default class EventItem {
    title: string = null;
    id: number = null;
    extendedProps: {
        booking: Booking,
        fitting: Fitting,
        user: User
    } = null;
    start: string = null;
    end: string = null;
}