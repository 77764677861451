export default class ConfigService {
    static storageKey = 'config';
    static storageKeyTime = 'configTime';

    data = null;

    constructor(init: boolean) {
        if (init) {
            this.loadFromSever();

            return;
        }

        if (!this.loadFromStorage()) {
            this.loadFromSever();
            // this.loadFromStorage();
        }
    }

    loadFromStorage() {
        const data = sessionStorage.getItem(ConfigService.storageKey);

        if (!data) {
            return false;
        }

        this.data = JSON.parse(data);

        return !!this.data;
    }

    loadFromSever() {
        let _this = this;

        fetch(document.location.origin + '/config.json?' + Math.random() )
            .then(response => {
                return response.json();
            }).then(function (json) {
                let date = new Date();

                sessionStorage.setItem(
                    ConfigService.storageKey,
                    JSON.stringify(json)
                );
                sessionStorage.setItem(
                    ConfigService.storageKeyTime,
                    date.getTime()
                );

                _this.loadFromStorage();
            });
    }

}