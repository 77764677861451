import React from 'react';
import TextField from "../components/form/TextField";
import SettingsService from "../service/SettingsService";

export default class Routing extends React.Component {
    state = {
        loaded: false,
        settings: null
    };

    refs = [];

    settingsService: SettingsService = new SettingsService(() => {
        this.onSettingsLoad()
    });

    constructor(props) {
        super(props);

        this.doSave = this.doSave.bind(this);
        this.onSettingsLoad = this.onSettingsLoad.bind(this);
        this.renderWorkingOption = this.renderWorkingOption.bind(this);
        this.renderOneItem = this.renderOneItem.bind(this);
    }

    componentDidMount() {
        this.settingsService.load();
    }

    onSettingsLoad() {
        this.refs = [];

        this.settingsService.data.forEach(item => {
            if (item.category !== 'routing') {
                return;
            }

            console.log(['fffff', item]);
            this.refs[item.name] = React.createRef();
        });

        this.setState({});
    }

    doSave(e) {
        e.preventDefault();

        console.log(this.refs);

        this.settingsService.data.forEach(item => {
            if (item.category !== 'routing') {
                return;
            }

            if (!this.refs[item.name] || !this.refs[item.name].current) {
                console.warn('Invalid ref for: "' +  item.name + '"');

                return;
            }

            this.settingsService.setValue('routing', item.name, this.refs[item.name].current.value);
        });

        this.settingsService.doSave();
    }

    renderOneItem(i: number) {
        return (
            <div className="routing__row">
                <div className="item">{i} Hours</div>
                <div className="item">
                    <TextField
                        size="small"
                        label=""
                        value={this.settingsService.getValue('routing', 'max.runs' + i)}
                        ref={this.refs['max.runs' + i]}
                    />
                </div>
                <div className="item">
                    <TextField
                        size="small"
                        label=""
                        value={this.settingsService.getValue('routing', 'max.qty' + i)}
                        ref={this.refs['max.qty' + i]}
                    />
                </div>
            </div>
        )
    }

    renderWorkingOption() {
        let result = [];

        for (let i=2; i<10; i++) {
            result.push(this.renderOneItem(i))
        }

        return result;
    }

    render() {

        return (
            <>
                <h1>Routing</h1>
                <form name="settings">

                    <div className="panel">

                        <div className="panel__body">

                            <h3>Runs and Quantity</h3>
                            <div className="routing">
                                <div className="routing__row">
                                    <div className="item">Working Hours</div>
                                    <div className="item">Max Number of Runs</div>
                                    <div className="item">Max Measuring Qty</div>
                                </div>


                                <div className="routing__row">
                                    <div className="item">2 Hours</div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.runs2')}
                                            ref={this.refs['max.runs2']}
                                        />
                                    </div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.qty2')}
                                            ref={this.refs['max.qty2']}
                                        />
                                    </div>
                                </div>
                                <div className="routing__row">
                                    <div className="item">3 Hours</div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.runs3')}
                                            ref={this.refs['max.runs3']}
                                        />
                                    </div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.qty3')}
                                            ref={this.refs['max.qty3']}
                                        />
                                    </div>
                                </div>
                                <div className="routing__row">
                                    <div className="item">4 Hours</div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.runs4')}
                                            ref={this.refs['max.runs4']}
                                        />
                                    </div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.qty4')}
                                            ref={this.refs['max.qty4']}
                                        />
                                    </div>
                                </div>
                                <div className="routing__row">
                                    <div className="item">5 Hours</div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.runs5')}
                                            ref={this.refs['max.runs5']}
                                        />
                                    </div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.qty5')}
                                            ref={this.refs['max.qty5']}
                                        />
                                    </div>
                                </div>
                                
                                
                                <div className="routing__row">
                                    <div className="item">6 Hours</div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.runs6')}
                                            ref={this.refs['max.runs6']}
                                        />
                                    </div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.qty6')}
                                            ref={this.refs['max.qty6']}
                                        />
                                    </div>
                                </div>
                                <div className="routing__row">
                                    <div className="item">7 Hours</div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.runs7')}
                                            ref={this.refs['max.runs7']}
                                        />
                                    </div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.qty7')}
                                            ref={this.refs['max.qty7']}
                                        />
                                    </div>
                                </div>
                                <div className="routing__row">
                                    <div className="item">8 Hours</div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.runs8')}
                                            ref={this.refs['max.runs8']}
                                        />
                                    </div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.qty8')}
                                            ref={this.refs['max.qty8']}
                                        />
                                    </div>
                                </div>
                                <div className="routing__row">
                                    <div className="item">9 Hours</div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.runs9')}
                                            ref={this.refs['max.runs9']}
                                        />
                                    </div>
                                    <div className="item">
                                        <TextField
                                            size="small"
                                            label=""
                                            value={this.settingsService.getValue('routing', 'max.qty9')}
                                            ref={this.refs['max.qty9']}
                                        />
                                    </div>
                                </div>
                            </div>

                            <h3>Consultants Selection</h3>

                            <div className="form__row form__row--routing">
                                <div className="form__field"><TextField
                                    size="small"
                                    suffix="km"
                                    label=""
                                    value={this.settingsService.getValue('routing', 'closest')}
                                    ref={this.refs['closest']}
                                /></div>
                                <div className="form__label"><strong>Closest consultant</strong> will be prioritised if within specified km</div>
                            </div>

                            <div className="form__row form__row--routing">
                                <div className="form__field"><TextField
                                    size="small"
                                    suffix="days"
                                    label=""
                                    value={this.settingsService.getValue('routing', 'max.days')}
                                    ref={this.refs['max.days']}
                                /></div>
                                <div className="form__label"><strong>Maximum calendar day option</strong> (amount of days we letting customer to book ahead)</div>
                            </div>


                            <h3>Selecting Timeframe</h3>

                            <div className="form__row form__row--routing">
                                <div className="form__field"><TextField
                                    size="small"
                                    suffix="min"
                                    label=""
                                    value={this.settingsService.getValue('routing', 'item.interval')}
                                    ref={this.refs['item.interval']}
                                /></div>
                                <div className="form__label"><strong>Displaying time option interval</strong> eg. [10:00] [12:00] [2:00]</div>
                            </div>



                        </div>
                        <div className="panel__bottom">
                            <div className="bh">
                                <button className="btn" onClick={this.doSave}>Update</button>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        );
    }
}