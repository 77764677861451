import React from 'react';
import PropTypes from "prop-types";

/**
 * Base component for project
 *
 */
export default class AbcComponent extends React.Component {
    static propTypes = {
        app: PropTypes.shape({
            head: PropTypes.shape({
                className: PropTypes.string,
                title: PropTypes.string,
                description: PropTypes.string,
            }),
            component: PropTypes.string
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.pushChanges = this.pushChanges.bind(this);
    }

    pushChanges(appParams) {
        if (this.props.onStateChanged) {
            this.props.onStateChanged(appParams);
        }
    }
};
