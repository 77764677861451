const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
});

export const currencyFormat = (value, isRound) => {
    let val;
    if (value) {
        if (isRound === false) {
            val = formatter.format(value);
        } else {
            val = formatter.format(Math.round(value));
        }
    } else {
        val = '$0.00';
    }

    return val;
}
