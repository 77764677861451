import React from 'react';
import {apiRequest} from "../../components/Globals";
import moment from "moment";
import PropTypes from "prop-types";
import {notify} from '../../components/Notify';
import TextArea from '../../components/form/TextArea';

export default class Logs extends React.Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date),
        userId: PropTypes.number
    };

    state = {
        logs: [],
    };

    refComment;
    storeDate: string = '';

    constructor(props) {
        super(props);

        this.refComment = React.createRef();

        this.loadLogs = this.loadLogs.bind(this);
        this.postComment = this.postComment.bind(this);
        this.isBooking = this.isBooking.bind(this);
        this.isFitting = this.isFitting.bind(this);
    }

    componentDidMount(): void {
        this.loadLogs();
    }

    // onLoading(show: boolean) {
    //     if (this.props.onLoading) {
    //         this.props.onLoading(show);
    //     }
    // }


    loadLogs() {
        let params = {};

        if (this.props.date) {
            if (this.storeDate === moment(this.props.date).format('YYYY-MM-DD')) {
                return;
            }

            this.storeDate = moment(this.props.date).format('YYYY-MM-DD');

            params.date = moment(this.props.date).format('YYYY-MM-DD');
        }

        if (this.isFitting()) {
            params.fittingId = this.props.calendarEvent.fitting.id;
        }

        if (this.isBooking()) {
            params.bookingId = this.props.calendarEvent.booking.id;
        }

        if (!params.fittingId && !params.bookingId) {
            // console.log('logs: no event');
            return;
        }

        apiRequest(
            '/bs/booking/get-log',
            params
        )
            .then(data => {
                this.setState({logs: data && data.custom ? data.custom : []});
            })
    }


    // validate() {
    //     this.lastErrors = [];

    //     if (!this.bookingValidate.isValid()) {
    //         this.booking.validationMessages.forEach(item => {
    //             this.lastErrors[item.fieldName] = item.message;
    //         });

    //         return false;
    //     }

    //     return true;
    // }

    postComment() {
        // if (!this.validate()) {
        //     this.setState({});

        //     return;
        // }

        // this.onLoading(true);

        let payload = {
            message: this.refComment.current.value
        };

        if (this.isFitting()) {
            payload.fittingId = this.props.calendarEvent.fitting.id;
        }

        if (this.isBooking()) {
            payload.bookingId = this.props.calendarEvent.booking.id;
        }

        apiRequest('/bs/booking/log-comment', payload)
            .then(() => {
                this.loadLogs();
                // this.showBookingPopup(false);
                // this.onLoading(false);
            })
            .catch(data => {
                // this.updateLastError(data);
                notify({text: data.message});
                // this.onLoading(false);
                this.setState({});
            });

    }

    isBooking() {
        return (this.props.calendarEvent && this.props.calendarEvent.booking && this.props.calendarEvent.booking.id);
    }

    isFitting() {
        return (this.props.calendarEvent && this.props.calendarEvent.fitting && this.props.calendarEvent.fitting.id);
    }

    render() {
        return (
            <>
                {this.isBooking() || this.isFitting() ?
                    <>
                        <div className="ih">
                            <TextArea
                                key={Math.random()}
                                label=""
                                size="small"
                                ref={this.refComment}
                                errorMessage={this.lastErrors ? this.lastErrors['comment'] : ''}
                            />
                        </div>
                        <div className="bh">
                            <button onClick={this.postComment} className="btn btn-primary">Add a comment</button>
                        </div>

                        <br/>
                        <br/>
                    </> : ''}

                {this.state.logs.map(item => {
                    let shortcat = '', fullName;

                    if (item.full_name) {
                        const shortcatParts = item.full_name.split(' ');
                        shortcat += shortcatParts[0].substr(0, 1).toUpperCase();

                        if (shortcatParts[1]) {
                            shortcat += shortcatParts[1].substr(0, 1).toUpperCase();
                        }

                        fullName = item.full_name;
                    } else if (!item.user_id) {
                        shortcat = 'SB';
                        fullName = 'Self Booking';
                    }

                    return (
                        <div className="logs" key={Math.random()}>
                            <div className="logs__column">
                                <div className="logs__avatar">{shortcat}</div>
                            </div>
                            <div className="logs__column">
                                <div className="logs__info">
                                    <span className="logs__info__name">{fullName}</span>
                                    <span className="logs__info__time">{moment(item.event_date).format('D MMMM Y h:mma')}</span>
                                </div>
                                <div className="logs__details" dangerouslySetInnerHTML={{__html: item.message}}></div>
                            </div>
                        </div>
                    )
                })}
            </>
        );
    }
}