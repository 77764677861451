import {BaseValidator} from "./BaseValidator";

export class RegExpValidator extends BaseValidator {
    regExp: RegExp = null;

    constructor(regExp: RegExp, message: string = null) {
        super(message);
        this.regExp = regExp;
    }

    isValid(value: *): boolean {
        return this.regExp.test(value);
    }

}