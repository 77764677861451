import React from 'react';
import {apiRequest} from "../components/Globals";
import {notify} from "../components/Notify";
import CheckBox from "../components/form/CheckBox";
import TextArea from "../components/form/TextArea";

export default class Settings extends React.Component {
    state = {
        list: {},
        settings: null
    };

    blackInput = null;

    constructor(props) {
        super(props);

        this.refReminder = React.createRef();
        this.refOnTheWay = React.createRef();
        this.refRunsheet = React.createRef();
        this.refFitterRunsheet = React.createRef();
        this.refCustomerFitting = React.createRef();
        this.refCustomerFittingEmail = React.createRef();

        this.doSave = this.doSave.bind(this);
        this.doUpdate = this.doUpdate.bind(this);
    }

    componentDidMount() {
        this.loadSettings();
    }

    doUpdate(event, id) {
        this.currentSettings.setProperty(id, event.target.value);
    }

    doSave() {
        let payload = [
            {
                category: 'sms',
                name: 'onTheWay',
                value: this.refOnTheWay.current.checked ? 1 : 0
            },
            {
                category: 'sms',
                name: 'reminder',
                value: this.refReminder.current.checked ? 1 : 0
            },
            {
                category: 'email',
                name: 'runsheet',
                value: this.refRunsheet.current.checked ? 1 : 0
            },
            {
                category: 'email',
                name: 'fitter.runsheet',
                value: this.refFitterRunsheet.current.checked ? 1 : 0
            },
            {
                category: 'sms',
                name: 'customer.fittings',
                value: this.refCustomerFitting.current.checked ? 1 : 0
            },
            {
                category: 'email',
                name: 'customer.fittings',
                value: this.refCustomerFittingEmail.current.checked ? 1 : 0
            },
            {
                category: 'sms',
                name: 'blacklist',
                value: this.blackInput
            },
        ];

        apiRequest(
            '/bs/settings/save',
            payload
        )
            .then(data => {
                notify({
                    text: 'Settings saved'
                })
            }).catch(response => {
            notify({text: response.message});
        });
    }

    loadSettings() {
        apiRequest('/bs/settings', 'GET')
            .then(data => {
                let list = {};

                if (!data || !data.custom) {
                    return;
                }

                data.custom.forEach(row => {
                    list[row.category + '_' + row.name] = row.value;
                });

                this.setState({list: list});
            });
    }

    render() {

        return (
            <>
                <h1>Settings</h1>
                {/*<form name="settings">*/}

                <div className="panel">
                    <div className="panel__body">
                        <div className="ih">
                            <CheckBox
                                key={Math.random()}
                                checked={this.state.list.sms_reminder === "1"}
                                ref={this.refReminder}
                            >Send SMS Reminder - 12:30pm</CheckBox>

                            <CheckBox
                                key={Math.random()}
                                checked={this.state.list.sms_onTheWay === "1"}
                                ref={this.refOnTheWay}
                            >Send SMS on their way</CheckBox>

                            <CheckBox
                                key={Math.random()}
                                checked={this.state.list.email_runsheet === "1"}
                                ref={this.refRunsheet}
                            >Send Consultant Runsheet Email - 4pm</CheckBox>

                            <CheckBox
                                key={Math.random()}
                                checked={this.state.list['email_fitter.runsheet'] === "1"}
                                ref={this.refFitterRunsheet}
                            >Send Fitters Runsheet Email - 5pm</CheckBox>

                            <CheckBox
                                key={Math.random()}
                                checked={this.state.list['sms_customer.fittings'] === "1"}
                                ref={this.refCustomerFitting}
                            >Send SMS Reminder Fitting Customer - 12:45pm</CheckBox>

                            <CheckBox
                                key={Math.random()}
                                checked={this.state.list['email_customer.fittings'] === "1"}
                                ref={this.refCustomerFittingEmail}
                            >Send Fitting Email Confirmation - 4:15pm</CheckBox>
                        </div>

                        <TextArea
                            label={'Black Listed Phone Numbers'}
                            size="small"
                            underline={false}
                            onChange={(e) => {
                                this.blackInput = e.target.value;
                            }}
                            value={this.state.list.sms_blacklist}
                        />
                    </div>
                    <div className="panel__bottom">
                        <div className="bh">
                            <button className="btn" onClick={this.doSave}>Update</button>
                        </div>
                    </div>
                </div>
                {/*</form>*/}
            </>
        );
    }
}