import React from 'react';
import {getRoleCaption} from "../config/Globals";
import {apiRequest} from "../components/Globals";
import {User} from "../components/form/model/User";

export default class UserStatus extends React.Component {
    state = {
        userList: [],
    };

    constructor(props) {
        super(props);

        this.lodUsers = this.lodUsers.bind(this);
    }

    componentDidMount() {
        this.lodUsers();
    }

    lodUsers() {

        apiRequest('/bs/user/user-list')
            .then(data => {
                if (data.custom) {
                    let userList = [];

                    data.custom.forEach(item => {
                        let user = new User();
                        user.assign(item);
                        userList.push(user);
                    });

                    this.setState({userList: userList});
                }
            });
    }

    render() {

        return (
            <>
                <h1>User Status</h1>

                <div className="panel">
                    <div className="panel__body">
                        <div className="user__status">
                            {this.state.userList.map(item => {
                                return (
                                    <div className={"user__status__list user__status__" + item.onlineStatus}>{item.firstName} {item.lastName} ({getRoleCaption(item.role)})</div>
                                )
                            })}
                        </div>
                    </div>
                </div>

            </>
        );
    }
}