import React, {Component} from 'react';
import PropTypes from "prop-types";

export default class Tabs extends Component {
    static propTypes = {
        defaultIndex: PropTypes.number,
    };

    selectedTab: number = 0;

    constructor(props) {
        super(props);

        this.onTabClick = this.onTabClick.bind(this);

        if (this.props.defaultIndex) {
            this.selectedTab = this.props.defaultIndex;
        }
    }

    onTabClick(tabIndex: number) {
        this.selectedTab = tabIndex;
        this.setState({});
    }

    render() {
        let {defaultIndex, items, ...rest} = this.props;

        if (!items) {
            items = [];
        }

        return (
            <div>
                <div className={'abc__tabs'}>
                    {items.map((item, i) => {
                    // {this.props.children && this.props.children.map ? this.props.children.map((child, i) => {
                        // console.log(['tabE', child, child.props.id, child.props.caption, child.getId()]);

                        if (typeof this.props.showTab !== 'undefined' && !this.props.showTab[i]) {
                            if (i === this.selectedTab) {
                                this.selectedTab++;
                            }

                            return '';
                        }

                        let classname = 'abc__tabs__item';

                        if (i === this.selectedTab) {
                            classname += ' abc__tabs__item--active';
                        }

                        return (
                            <div className={classname}
                                key={i}
                                //style={style}
                                onClick={() => this.onTabClick(i)}
                            >{item}</div>
                        )
                    })/* : ''*/}
                </div>
                <div className="abc__tabs__content">
                    {this.props.children && this.props.children.map ? this.props.children.map((child, i) => {
                        if (typeof this.props.showTab !== 'undefined' && !this.props.showTab[i]) {
                            return '';
                        }

                        let props = {
                            key: i,
                            style: {
                                display: 'none'
                            }
                        };

                        if (this.selectedTab === i) {
                            props.style.display = 'block';
                        }

                        let el = React.cloneElement(child, props);

                        return (
                            el
                        )
                    }) : ''}
                </div>
            </div>
        );
    }
};
