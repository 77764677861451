import React, {Component} from 'react';

export default class Featured extends Component {

    render() {
        return (
            <section className="footer__featured">
                <div className="footer__featured__title">Featured in</div>
                <div className="footer__featured__list">
                    <div className="item"><img src="images/featured-seven.png" alt="XXXXX"/></div>
                    <div className="item"><img src="images/featured-homes-to-love.png" alt="XXXXX"/></div>
                    <div className="item"><img src="images/featured-realliving.png" alt="XXXXX"/></div>
                    <div className="item"><img src="images/featured-belle.png" alt="XXXXX"/></div>
                    <div className="item"><img src="images/featured-countrystyle.png" alt="XXXXX"/></div>
                    <div className="item"><img src="images/featured-australian-house-and-garden.png" alt="XXXXX"/></div>
                    <div className="item"><img src="images/featured-inside-out.png" alt="XXXXX"/></div>
                    <div className="item"><img src="images/featured-interiors-addict.png" alt="XXXXX"/></div>
                </div>
            </section>
        );
    }
};
