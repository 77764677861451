import React from 'react';
import PropTypes from "prop-types";
import BaseField from "./BaseField";

export default class Select extends BaseField {
    static propTypes = {
        name: PropTypes.string,
        label: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ]),
                label: PropTypes.string
            })
        ),
        variant: PropTypes.oneOf(['underline', 'background']),
        underline: PropTypes.bool,
        onChange: PropTypes.func,
        defaultValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        disabled: PropTypes.bool,
        errorMessage: PropTypes.string,
        className: PropTypes.string
    };

    value: string | number = null;
    label: string = null;
    savedDefaultValue = null;
    name: string = null;
    currentItem: number = -1;

    constructor(props) {
        super(props);

        this.name = props.name;

        this.refItems = React.createRef();
        this.wrapperRef = React.createRef();
        this.refUl = React.createRef();

        this.onDropDown = this.onDropDown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.doSelect = this.doSelect.bind(this);
        this.setCurrentValue = this.setCurrentValue.bind(this);
        this.clear = this.clear.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onDropDown(event) {
        if (this.props.disabled) {
            return;
        }

        this.showDropDown(!this.isDropDownVisible());
    }

    setCurrentValue(value) {
        if (!value) {
            this.clear();

            return;
        }

        if (!this.props.options) {
            return;
        }

        this.props.options.some((option) => {
            if (option.value === value) {
                this.value = option.value;
                this.label = option.label;

                return true;
            }

            return false;
        });
    }

    showDropDown(show) {
        if (show) {
            this.refItems.current.style.display = "block";
            this.wrapperRef.current.classList.add('abc__select--activated');

            document.addEventListener('keydown', this.onKeyDown);
        } else {
            this.refItems.current.style.display = "none";
            this.wrapperRef.current.classList.remove('abc__select--activated');

            document.removeEventListener('keydown', this.onKeyDown);
        }
    }

    isDropDownVisible() {
        return this.wrapperRef.current.classList.contains('abc__select--activated');
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.showDropDown(false);
        }
    }

    onKeyDown(event) {
        event.preventDefault();
        event.stopPropagation();

        switch (event.key) {
            case 'ArrowUp':
                if (this.currentItem > 0) {
                    this.currentItem--;
                }

                break;
            case 'ArrowDown':
                if (this.props.options && this.currentItem < this.props.options.length - 1) {
                    this.currentItem++;
                }

                break;

            case 'Enter':
                this.showDropDown(false);
                this.doSelect(this.props.options[this.currentItem].value);
                break;

            default:
                if (this.props.options && this.props.options.length > 0) {
                    this.props.options.some((item, i) => {
                        let text = item.label.substr(0, 1);

                        if (text && text.toLowerCase() === event.key) {
                            this.currentItem = i;

                            return true;
                        }

                        return false;
                    });
                }
        }

        this.refUl.current.childNodes.forEach((item, i) => {
            if (i === this.currentItem) {
                item.classList.add('abc__select__list__item--highlight');
                this.refItems.current.scrollTo(0, item.offsetTop);
            } else {
                item.classList.remove('abc__select__list__item--highlight');
            }
        });

    }

    doSelect(value) {
        this.setCurrentValue(value);
        this.setState({});

        if (this.props.onChange) {
            const event = {
                target: this,
            };

            this.props.onChange(event);
        }
    }

    clear() {
        this.value = null;
        this.label = null;
    }

    render() {
        let {errorMessage, size, variant, ...rest} = this.props;

        let customClassName = '';

        if (this.props.disabled) {
            customClassName += ' abc__select--disabled';
        }

        switch (this.props.variant) {
            case 'underline':
                customClassName += ' abc__textfield--underline';
                break;

            case 'background':
                customClassName += ' abc__textfield--background';
                break;

            default:
                customClassName = '';
        }

        if (!this.props.label) {
            customClassName += ' abc__select--no-label';
        }

        if (this.props.size === 'small') {
            customClassName += ' abc__select--small';
        }

        if (this.props.className) {
            customClassName += ` ${this.props.className}`;
        }

        if (this.savedDefaultValue !== this.props.defaultValue) {
            this.savedDefaultValue = this.props.defaultValue;
            this.setCurrentValue(this.props.defaultValue);
        }

        return (
            <>
                <input type="hidden" name={this.props.name} value={this.value ? this.value : ''}/>
                <div
                    className={'abc__select' + customClassName + (this.value ? ' abc__select--filled' : '')}
                    onClick={this.onDropDown}
                    onBlur={this.onBlur}
                    ref={this.wrapperRef}// contentEditable={true}
                >
                    <div className="abc__select__box">
                        <div
                            className="abc__select__input"
                        >
                            {this.props.label ? <span className={`abc__select__label`}>{this.props.label}</span> : ''}
                            <span className="abc__select__selected-text">{this.label}</span>
                            <i className="abc__select__dropdown-icon"></i>
                            {this.props.underline || this.props.underline === undefined ? <div className="abc__select__underline"></div> : ''}
                        </div>
                    </div>
                    <div
                        className="abc__select__menu"
                        ref={this.refItems}
                        style={{display: "none"}}
                    >
                        <ul
                            className="abc__select__list"
                            ref={this.refUl}
                        >
                            {this.props.options ?
                                this.props.options.map(item => {
                                    return (
                                        <li className="abc__select__list__item" onClick={() =>{this.doSelect(item.value)}} key={item.value}>{item.label}</li>
                                    )
                                }) : ''
                            }
                        </ul>
                    </div>
                </div>
                {
                    errorMessage ?
                        <div className="abc__helper abc__helper--error-show">
                            <div className="abc__helper__text abc__helper__text--error">{errorMessage}</div>
                        </div> : ''
                }
            </>
        );
    }
};
