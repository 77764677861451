import {BaseModel} from "../components/form/model/BaseModel";
import {NotEmptyValidator} from "../components/form/validators/NotEmptyValidator";
import {apiRequest} from "../components/Globals";
import moment from "moment";

export class ReportExpensesModel extends BaseModel {
    id: number = null;
    dateFrom: string = null;
    dateTo: string = null;
    dateAdded: string = null;
    amount: string = null;
    creatorId: string = null;
    list: [] = [];


    constructor() {
        super();

        this.addValidator('amount', new NotEmptyValidator('Amount is required'))
        // .addValidator('lastName', new NotEmptyValidator('Lastname is required'))
        ;
    }

    toggleExpensesModal(isOpen: boolean) {
        this.expensesModalStatus.show = isOpen;

        if (isOpen) {
            this.setState({loading: true});
            this.expensesForm.getExpensesList(() => this.setState({loading: false}));
        } else {
            this.setState({});
        }
    }

    getExpensesList(cb: Function) {
        apiRequest('/report/expenses-list')
            .then(data => {
                if (!data || !data.custom) {
                    cb && cb();
                    this.list = [];
                    return;
                }
                const list = [];

                data.custom.forEach(item => {
                    const newExpensesItem = new ReportExpensesModel();
                    newExpensesItem.assign(item);
                    list.push(newExpensesItem);
                })
                this.list = list;

                cb && cb();
            })
            .catch(err => {
                console.error(err)
                cb && cb();
            });
    }

    removeExpenses(id, cb: Function) {
        if (!id) {
            return;
        }

        apiRequest('/report/expenses-delete', {id})
            .then(data => {
                this.list = this.list.filter(form => form.id !== id);
                cb && cb();
            })
            .catch(err => {
                console.error(err)
                cb && cb();
            });
    }

    addExpenses(cb: Function) {
        const payload = {
            date_from: moment(this.dateFrom ?? new Date()).format('YYYY-MM-DD'),
            date_to: moment(this.dateTo ?? new Date()).format('YYYY-MM-DD'),
            amount: this.amount
        }

        apiRequest('/report/expenses-add', payload)
            .then(data => {
                const newExpensesItem = new ReportExpensesModel();
                newExpensesItem.assign(payload);
                this.list.push(newExpensesItem);

                this.amount = null;
                this.dateFrom = null;
                this.dateTo = null;

                cb && cb();

            })
            .catch(err => console.error(err));
    }

    setProperty(name: string, value: *): this {
        switch (name) {

            case 'date_from':
                if (value) {
                    this.dateFrom = moment(value).format('DD MMMM');
                }

                break;

            case 'date_to':
                if (value) {
                    this.dateTo = moment(value).format('DD MMMM');
                }

                break;

            default:
                super.setProperty(name, value);
        }

        return this;
    }


    clean(): this {
        const list = Object.create(null, Object.getOwnPropertyDescriptors(this.list));
        super.clean();

        this.list = list;

        return this;
    }
}