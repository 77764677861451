import React from 'react';

export default class WayPoints extends React.Component {
    directionsRenderer;
    distance: [] = null;
    totalDistance: number = 0;
    totalTime: number = 0;
    markers: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    map;

    constructor(props) {
        super(props);

        this.refMain = React.createRef();
        this.refMap = React.createRef();
        this.refPoints = React.createRef();
        this.refStat = React.createRef();

        this.initMap = this.initMap.bind(this);
        this.updateStat = this.updateStat.bind(this);
        this.getLineNum = this.getLineNum.bind(this);
    }

    componentDidMount() {
        this.initMap();
    }

    initMap() {
        this.directionsRenderer = new window.google.maps.DirectionsRenderer();
        this.map = new window.google.maps.Map(this.refMap.current, {
            zoom: 12,
            center: {lat: -31.952, lng: 115.855},
        });
        this.directionsRenderer.setMap(this.map);
    }

    calculateAndDisplayRoute(addresses: []) {
        let onlyAddresses = addresses.map(adr => adr.addr);

        let addr = JSON.parse(JSON.stringify(onlyAddresses));

        const directionsService = new window.google.maps.DirectionsService();
        const waypts = [];

        const startPoint = addr.shift();

        if (!startPoint) {
            return;
        }


        if (addr.length === 0) {
            this.addSingleMarker(startPoint);
            // new window.google.maps.Marker({
            //     position: startPoint,
            //     map: this.map,
            //     title: "Hello World!",
            // });

            return;
        }


        const endPoint = addr.pop();

        let that = this;

        addr.forEach((address) => {
            waypts.push({
                location: address,
                stopover: true,
            });
        });

        directionsService
            .route({
                origin: startPoint,
                destination: endPoint,
                waypoints: waypts,
                // optimizeWaypoints: true,
                travelMode: window.google.maps.TravelMode.DRIVING,
            })
            .then((response) => {
                that.directionsRenderer.setDirections(response);

                this.distance = response.routes[0].legs;
                this.totalDistance = 0;
                this.totalTime = 0;

                this.distance.forEach((row, i) => {
                    this.totalDistance += row.distance.value;
                    this.totalTime += row.duration.value;
                });

                this.updateStat();
            })
            .catch((e) => console.error(e));
    }

    addSingleMarker(address) {
        let geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({'address': address}, (results, status) => {
            const latLng = {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()};

            if (status === 'OK') {
                let marker = new window.google.maps.Marker({
                    position: latLng,
                    map: this.map,
                    label: 'A'
                });

                this.map.setCenter(latLng);
            } else {
                console.log('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    getLineNum(i) {
        let res;
        let infoDates = this.props.points;

        infoDates.some((date, key) => {
            if (key === i) {
                date.sortLineNum.some((sortedLine, sortedKey) => {
                    if (sortedLine === date.infoDate) {
                        res = sortedKey + 1;
                        return true;
                    }
                    return false;
                })
            }
            return !!res;
        });

        return res;
    }

    updateStat() {
        let points = '';
        let orderInfo = this.props.points;

        this.distance.forEach((row, i) => {
            points += `<div>${this.markers[i]} to ${this.markers[i + 1]}: ${(row.distance.value / 1000).toFixed(2)} km, ${(i + 1) === this.distance.length ? orderInfo[i].info + ', ' + orderInfo[i + 1]?.info : orderInfo[i].info} - ${(i + 1) === this.distance.length ? `(${this.getLineNum(i)}. ${this.getLineNum(i + 1)}.)` : `${this.getLineNum(i)}.`}</div>`;
        });

        this.refPoints.current.innerHTML = points;
        this.refStat.current.innerHTML = 'Total: ' + (this.totalDistance / 1000).toFixed(2) + 'km<br/>' +
            'Total Time: ' + (this.totalTime / 3600).toFixed(2) + 'hrs';
    }

    render() {
        let {points, ...rest} = this.props;

        if (points) {
            this.calculateAndDisplayRoute(points);
        }

        return (
            <div ref={this.refMain} key={points.join}>
                <div className="map__runs">

                    <div ref={this.refMap} style={{width: '800px', height: '500px'}}></div>

                    <div className="map__runs__distance" key={this.totalDistance} style={{width: "100%"}}>
                        <div className="item" ref={this.refStat}></div>
                        <div className="item" ref={this.refPoints}></div>
                    </div>


                </div>

            </div>
        );
    }
}