import React, {Component} from 'react';

let notifyList = {
    items: [],
};

export class notifyItem {
    test: string;
    onClick: {
        event: null,
        label: string
    }
}

export function notify(notify: notifyItem) {
    notifyList.items.push(notify);
}

export default class Notify extends Component {
    timer = null;
    timeRemove = null;
    items = [];

    constructor(props) {
        super(props);

        this.onCloseItem = this.onCloseItem.bind(this);
        this.updateItems = this.updateItems.bind(this);
        this.removeItems = this.removeItems.bind(this);
    }

    componentDidMount(): void {
        this.time = setInterval(this.updateItems, 2000);
        this.timeRemove = setInterval(this.removeItems, 1000);
    }

    componentWillUnmount(): void {
        if (this.time) {
            clearInterval(this.time);
        }
        if (this.timeRemove) {
            clearInterval(this.timeRemove);
        }
    }

    updateItems() {
        while (notifyList.items.length > 0) {
            let item = notifyList.items.shift();
            item.id = Math.random();
            item.date = new Date();

            this.items.push(item);
        }

        this.setState({});
    }

    removeItems() {
        const date = new Date();

        for (let i = 0; i < this.items.length; i++) {
            if (date - this.items[i].date > 3000) {
                this.items.splice(i, 1);
            }
        }

        this.setState({});
    }

    onCloseItem() {

    }

    render() {
        return (
            <>
                {this.items.length > 0 ?
                    <div className="abc__notification">
                        {this.items.map(item => {
                            return (
                                <div className="abc__notification__content" key={Math.random()}>
                                    <span className="abc__notification__close" onClick={() => this.onCloseItem(item)}>X</span>
                                    <div className="abc__notification__box">
                                        <div className="abc__notifcaion__text">{item.text}</div>
                                        {item.onClick ?
                                            <div className="abc__notification__link"><span onClick={item.onClick.event}>{item.onClick.label}</span></div>
                                            : ''}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    : ''}
            </>
        );
    }
};
