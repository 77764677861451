import React from "react";
import {Link} from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import PropTypes from 'prop-types';
import {Auth} from "./Auth";
import Access from "./Access";
import Select from "./form/Select";
import {ROLES} from "../config/Globals";
import {apiRequest} from "./Globals";
import {notify} from "./Notify";
import moment from "moment";

export default class Menu extends React.Component {
    static propTypes = {
        onDateChange: PropTypes.func,
    };

    state = {
        menuItem: '',
        userList: []
    };

    selectedDay = null;
    backupColor = null;

    auth: Auth = new Auth();

    constructor(props) {
        super(props);

        this.onDateClick = this.onDateClick.bind(this);
        this.lodUsers = this.lodUsers.bind(this);
        this.doLoginAsUser = this.doLoginAsUser.bind(this);
        this.menuFromUrl = this.menuFromUrl.bind(this);
        this.allowTodayForCurMonth = this.allowTodayForCurMonth.bind(this);
    }

    componentDidMount(): void {
        let currentDate = moment(new Date()).format('YYYY-MM-DD');
        this.selectedDay = document.querySelector(`[data-date="${currentDate}"]`);

        if (this.selectedDay) {
            this.selectedDay.style.backgroundColor = '#9dba37';
        }

        this.allowTodayForCurMonth();
        this.lodUsers();
    }

    componentDidUpdate() {
        this.unDisableTodayBtn();
    }

    allowTodayForCurMonth() {
        const todayBtn = document.querySelector('.fc-today-button');
        const prevBtn = document.querySelector('.fc-prev-button');
        const nextBtn = document.querySelector('.fc-next-button');
        const dayToday = document.querySelector('.fc-day-today');
        const _this = this;

        if (todayBtn && prevBtn && nextBtn) {
            todayBtn.addEventListener('click', function () {
                _this.onDateClick({dayEl: dayToday, date: new Date()});
                dayToday.style.background = '#9dba37';
            });

            [prevBtn, nextBtn].forEach(btn => {
                btn.addEventListener('click', function () {
                    setTimeout(() => {
                        _this.unDisableTodayBtn();
                    }, 50)
                })
            });
        }
    }

    unDisableTodayBtn() {
        const todayBtn = document.querySelector('.fc-today-button');
        if (todayBtn) {
            todayBtn.disabled = false;
        }
    }

    doLoginAsUser(e) {
        apiRequest('/bs/user/login-as', {
            id: e.target.value
        })
            .then(response => {
                this.auth.role = response.custom.role;
                this.auth.name = response.custom.name;
                this.auth.admin = {
                    id: this.auth.userId
                };
                this.auth.userId = e.target.value;
                this.auth.save();

                document.location.reload();
            })
            .catch(response => {
                notify({text: response.message});
            });

    }

    onDateClick(info) {
        if (this.selectedDay) {
            this.selectedDay.style.backgroundColor = this.backupColor;
        }

        this.selectedDay = info.dayEl;
        this.backupColor = this.selectedDay.style.backgroundColor;
        this.selectedDay.style.backgroundColor = '#9dba37';

        if (this.props.onDateChange) {
            this.props.onDateChange(info);
        }
    }

    lodUsers() {
        if (this.auth.role !== ROLES.admin) {
            return;
        }

        apiRequest('/bs/user/reps')
            .then(data => {
                let list = [];

                if (data.custom) {
                    data.custom.forEach(item => {
                        list.push({value: item.id, label: item.full_name});
                    });
                }

                list.sort(function (a, b) {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                })

                this.setState({
                    userList: list,
                });
            });
    }

    menuFromUrl() {
        if (this.state.menuItem) {
            return this.state.menuItem;
        }

        let path = this.props.initPath;

        switch (path) {
            case '/measure-and-quote':
            case '/availability':
            case '/todays-run':
                return 'measure';

            case '/fitting-todays-run':
                return 'fitter';

            case '/routing':
                return 'settings';

            case '/calendar':
                return 'calendar';

            default:
                console.info('menu unknown', window.location.pathname);
        }
    }

    render() {
        this.auth.load();
        const path = this.menuFromUrl();

        return (
            <nav>
                <ul className="nav__main">
                    {Access.isAllowed('MeasureAndQuote', 'view') ?
                        <li className={path === 'measure' ? 'active' : ''}>
                            <Link to="/measure-and-quote" onClick={() => this.setState({menuItem: 'measure'})}>
                                <svg className="icon__reps" viewBox="0 0 22.47 21.46">
                                    <path
                                        d="M9.94,6a6.56,6.56,0,1,0,6.56,6.56A6.56,6.56,0,0,0,9.94,6Zm0,11.62A5.06,5.06,0,1,1,15,12.56,5.07,5.07,0,0,1,9.94,17.62Z"
                                        transform="translate(-0.26 -0.77)"/>
                                    <path
                                        d="M22,16.89a.74.74,0,0,0-.75.75v1.72H19.61V10c0-.2,0-.4,0-.59h0L21.3,7.64a1.58,1.58,0,0,0,0-2.21L17.07,1.21a1.59,1.59,0,0,0-2.2,0L13.16,2.92c-.21,0-.41,0-.62,0H7.33A7.07,7.07,0,0,0,.26,10v5.22a7.07,7.07,0,0,0,7.07,7.06h10.9a1.39,1.39,0,0,0,1.38-1.37H22a.76.76,0,0,0,.75-.75V17.64A.75.75,0,0,0,22,16.89ZM16,2.27l4.22,4.31-1,1a7.06,7.06,0,0,0-4.28-4.31Zm2.1,18.46H7.33a5.58,5.58,0,0,1-5.57-5.56V10A5.57,5.57,0,0,1,7.33,4.39h5.21A5.57,5.57,0,0,1,18.11,10Z"
                                        transform="translate(-0.26 -0.77)"/>
                                </svg>
                                <span className="text">Consultants</span>
                            </Link>
                            <div className="nav__main__sub">
                                {Access.isAllowed('TodaysRun', 'view') ?
                                    <Link to="/todays-run" onClick={() => this.setState({menuItem: 'measure'})}>Today's
                                        Run</Link>
                                    : ''
                                }
                                {Access.isAllowed('MeasureAndQuote', 'view') ?
                                    <Link to="/measure-and-quote" onClick={() => this.setState({menuItem: 'measure'})}>Measure
                                        & Quote</Link>
                                    : ''
                                }
                                {Access.isAllowed('MeasureAndQuote', 'availability') ?
                                    <Link to="/availability"
                                          onClick={() => this.setState({menuItem: 'measure'})}>Availability</Link>
                                    : ''
                                }
                            </div>
                        </li>
                        : ''
                    }
                    {Access.isAllowed('Calendar', 'view') ?
                        <li className={path === 'calendar' ? 'active' : ''}>
                            <Link to="/calendar" onClick={() => this.setState({menuItem: 'calendar'})}>
                                <svg className="icon__calendar" viewBox="0 0 32 32">
                                    <g id="aa86b327-eee7-4ce9-987d-e0de0e1d9610" data-name="Calendar">
                                        <path
                                            d="M29,3.11a1,1,0,1,0,0,2,1,1,0,0,1,1,1v5H2v-5a1,1,0,0,1,1-1,1,1,0,1,0,0-2,3,3,0,0,0-3,3v24a3,3,0,0,0,3,3H29a3,3,0,0,0,3-3v-24A3,3,0,0,0,29,3.11Zm1,27a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1v-17H30ZM6,7.11a1,1,0,0,0,1-1v-4a1,1,0,0,0-2,0v4A1,1,0,0,0,6,7.11Zm4,0a1,1,0,0,0,1-1v-4a1,1,0,1,0-2,0v4A1,1,0,0,0,10,7.11Zm4,0a1,1,0,0,0,1-1v-4a1,1,0,1,0-2,0v4A1,1,0,0,0,14,7.11Zm4,0a1,1,0,0,0,1-1v-4a1,1,0,1,0-2,0v4A1,1,0,0,0,18,7.11Zm4,0a1,1,0,0,0,1-1v-4a1,1,0,1,0-2,0v4A1,1,0,0,0,22,7.11Zm4,0a1,1,0,0,0,1-1v-4a1,1,0,1,0-2,0v4A1,1,0,0,0,26,7.11Z"
                                            transform="translate(0 -1.11)"/>
                                    </g>
                                </svg>
                                <span className="text">Calendar</span>
                            </Link>
                            <div className="nav__main__sub nav__main__sub--calendar">
                                <div className="calendar__box">
                                    <FullCalendar
                                        defaultView="dayGrid"
                                        // aspectRatio="1"
                                        defaultDate={new Date()}
                                        selectable="true"
                                        height="300px"
                                        plugins={[dayGridPlugin, interactionPlugin]}
                                        dateClick={this.onDateClick}
                                    />
                                </div>
                            </div>
                        </li>
                        : ''
                    }

                    {Access.isAllowed('Fitter', 'view') ?

                        <li className={path === 'fitter' ? 'active' : ''}>
                            <Link to="/fittings" onClick={() => this.setState({menuItem: 'fitter'})}>
                                <svg className="icon__fitters" viewBox="0 0 27.03 23.44">
                                    <path
                                        d="M13,23.72H1.6a1.21,1.21,0,0,1-1.22-1.2V19.38a1.2,1.2,0,0,1,.94-1.16,1.8,1.8,0,0,1,.05-.89l2-6.15a1.67,1.67,0,0,0-.77-1.61l-.48-.28c-.67-.36-1.6-.85-1.6-2V2.67A2.43,2.43,0,0,1,3,.28h14.3a2.47,2.47,0,0,1,2.07,1.11.64.64,0,0,1,.2,0h1.29c.58,0,2.3,1.93,2.3,2.58v.27H26.7a.72.72,0,0,1,0,1.44H23.13v.28c0,.65-1.72,2.58-2.3,2.58H19.54l-.15,0a2.47,2.47,0,0,1-2.12,1.18H13.62v0A3.32,3.32,0,0,0,14,11.83l.08.22a.68.68,0,0,1-.08.66.69.69,0,0,1-.59.31H10.63a14.1,14.1,0,0,0-.8,2.56A2.93,2.93,0,0,0,11,17.91a.71.71,0,0,1,.29.28H13a1.2,1.2,0,0,1,1.22,1.19v3.14A1.21,1.21,0,0,1,13,23.72ZM1.82,22.28H12.74V19.62H1.82ZM13,19.62h0ZM3.22,18.19h6a4.93,4.93,0,0,1-.83-2.61,17.61,17.61,0,0,1,1.34-4.25c.17-.41.29-.75.35-.91A3.08,3.08,0,0,1,12.7,8.23h4.57a1,1,0,0,0,1-.94V2.67a1,1,0,0,0-1-.95H3a1,1,0,0,0-1,1V7.29c0,.25.19.39.84.73l.56.32a3.06,3.06,0,0,1,1.38,3.28l-2,6.16a.27.27,0,0,0,0,.26A.69.69,0,0,1,3.22,18.19Zm8-6.61h1.15a4.56,4.56,0,0,1-.24-1.64,2.35,2.35,0,0,0-.65.94C11.4,11,11.3,11.26,11.18,11.58Zm8.55-4.51h.84a8.8,8.8,0,0,0,1.12-1.25V4A9.35,9.35,0,0,0,20.57,2.8h-.84Zm2-1.29ZM7.2,6.64a.76.76,0,0,1-.43-.14.72.72,0,0,1-.15-1L8.3,3.18A.72.72,0,0,1,9.47,4L7.78,6.34A.7.7,0,0,1,7.2,6.64Zm-2.82,0A.73.73,0,0,1,3.8,5.49L5.49,3.18a.71.71,0,0,1,1-.15.72.72,0,0,1,.16,1L5,6.34A.7.7,0,0,1,4.38,6.64Z"
                                        transform="translate(-0.38 -0.28)"/>
                                </svg>
                                <span className="text">Fitters</span>
                            </Link>
                            <div className="nav__main__sub">
                                {Access.isAllowed('Fitter', 'view') ?
                                    <Link to="/fitting-todays-run" onClick={() => this.setState({menuItem: 'fitter'})}>Today's
                                        Run</Link>
                                    : ''
                                }
                                {Access.isAllowed('Fitter', 'view') ?
                                    <Link to="/fittings"
                                          onClick={() => this.setState({menuItem: 'fitter'})}>Fittings</Link>
                                    : ''
                                }
                                {Access.isAllowed('Fitter', 'view') ?
                                    <Link to="/availability"
                                          onClick={() => this.setState({menuItem: 'fitter'})}>Availability</Link>
                                    : ''
                                }
                            </div>
                        </li>
                        : ''
                    }

                    {Access.isAllowed('Users', 'view') ?
                        <>
                            <li className={path === 'users' ? 'active' : ''}>
                                <Link to="/users" onClick={() => this.setState({menuItem: 'users'})}>
                                    <svg className="icon__users" viewBox="0 0 23.08 23.09">
                                        <path
                                            d="M11.54,15.87c3.58,0,6.49-3.56,6.49-7.93S15.12,0,11.54,0A6,6,0,0,0,6.9,2.38,8.92,8.92,0,0,0,5.05,7.94C5.05,12.31,8,15.87,11.54,15.87Zm0-14.43c2.79,0,5.05,2.92,5.05,6.5s-2.26,6.49-5.05,6.49-5-2.91-5-6.49S8.76,1.44,11.54,1.44Zm5.05,13a.72.72,0,1,0,0,1.44,5.06,5.06,0,0,1,5.05,5.05.72.72,0,0,1-.72.72H2.16a.72.72,0,0,1-.72-.72,5.06,5.06,0,0,1,5.05-5.05.72.72,0,0,0,0-1.44A6.5,6.5,0,0,0,0,20.92a2.16,2.16,0,0,0,2.16,2.16H20.92a2.16,2.16,0,0,0,2.16-2.16A6.5,6.5,0,0,0,16.59,14.43Z"/>
                                    </svg>
                                    <span className="text">Users</span>
                                </Link>
                            </li>

                        </>
                        : ''
                    }
                    {Access.isAllowed('Routing', 'view') ?
                        <li className={path === 'settings' ? 'active' : ''}>
                            <Link to="/settings" onClick={() => this.setState({menuItem: 'settings'})}>
                                <svg className="icon__settings" viewBox="0 0 23.72 23.72">
                                    <path
                                        d="M23.21,14.1a2.12,2.12,0,0,1,0-4.2.72.72,0,0,0,.52-.32.74.74,0,0,0,.11-.6A12.26,12.26,0,0,0,22.5,5.76.71.71,0,0,0,22,5.41a.76.76,0,0,0-.6.14,2.16,2.16,0,0,1-2.79-.17,2.11,2.11,0,0,1-.17-2.79.76.76,0,0,0,.14-.6.78.78,0,0,0-.35-.5A12.44,12.44,0,0,0,15,.16a.74.74,0,0,0-.6.11.72.72,0,0,0-.32.51,2.12,2.12,0,0,1-4.2,0A.72.72,0,0,0,9.58.27.74.74,0,0,0,9,.16,12.44,12.44,0,0,0,5.76,1.49a.78.78,0,0,0-.35.5.76.76,0,0,0,.14.6,2.11,2.11,0,0,1-.17,2.79,2.15,2.15,0,0,1-2.79.17.75.75,0,0,0-1.1.21A12.58,12.58,0,0,0,.16,9a.75.75,0,0,0,.63.92,2.12,2.12,0,0,1,0,4.2A.75.75,0,0,0,.16,15a12.58,12.58,0,0,0,1.33,3.22.75.75,0,0,0,.5.35.73.73,0,0,0,.59-.14,2.17,2.17,0,0,1,2.8.17,2.11,2.11,0,0,1,.17,2.79.76.76,0,0,0-.14.6.78.78,0,0,0,.35.5A12.44,12.44,0,0,0,9,23.84l.19,0a.69.69,0,0,0,.41-.13.74.74,0,0,0,.32-.52,2.12,2.12,0,0,1,4.2,0,.72.72,0,0,0,.32.52.74.74,0,0,0,.6.11,12.58,12.58,0,0,0,3.22-1.33.75.75,0,0,0,.21-1.1,2.11,2.11,0,0,1,.17-2.79,2.16,2.16,0,0,1,2.79-.17.76.76,0,0,0,.6.14.71.71,0,0,0,.49-.35A12.26,12.26,0,0,0,23.84,15a.74.74,0,0,0-.11-.6A.72.72,0,0,0,23.21,14.1Zm-1.63,2.73a3.6,3.6,0,0,0-4.75,4.76,10.53,10.53,0,0,1-1.47.6,3.59,3.59,0,0,0-6.72,0,10.53,10.53,0,0,1-1.47-.6,3.61,3.61,0,0,0-.74-4,3.56,3.56,0,0,0-2.54-1.05,3.78,3.78,0,0,0-1.48.31,9.44,9.44,0,0,1-.6-1.47,3.59,3.59,0,0,0,0-6.72,9.44,9.44,0,0,1,.6-1.47,3.78,3.78,0,0,0,1.48.31A3.54,3.54,0,0,0,6.43,6.43a3.61,3.61,0,0,0,.74-4,10.53,10.53,0,0,1,1.47-.6,3.59,3.59,0,0,0,6.72,0,10.53,10.53,0,0,1,1.47.6,3.61,3.61,0,0,0,.74,4,3.56,3.56,0,0,0,2.54,1.05,3.69,3.69,0,0,0,1.47-.31,9.48,9.48,0,0,1,.61,1.47,3.59,3.59,0,0,0,0,6.72A9.48,9.48,0,0,1,21.58,16.83ZM12,5.56A6.44,6.44,0,1,0,18.44,12,6.45,6.45,0,0,0,12,5.56ZM12,17A4.95,4.95,0,1,1,17,12,5,5,0,0,1,12,17Zm0-8.47A3.52,3.52,0,1,0,15.52,12,3.53,3.53,0,0,0,12,8.48Zm0,4.91A1.39,1.39,0,1,1,13.39,12,1.39,1.39,0,0,1,12,13.39Z"
                                        transform="translate(-0.14 -0.14)"/>
                                </svg>
                                <span className="text">Settings</span>
                            </Link>
                            <div className="nav__main__sub">
                                <Link to="/settings"
                                      onClick={() => this.setState({menuItem: 'settings'})}>Settings</Link>
                                <Link to="/routing" onClick={() => this.setState({menuItem: 'settings'})}>Routing</Link>
                                <Link to="/reports" onClick={() => this.setState({menuItem: 'settings'})}>Reports</Link>
                                <Link to="/marketing-report" onClick={() => this.setState({menuItem: 'settings'})}>Marketing
                                    Report</Link>
                                <Link to="/user-status" onClick={() => this.setState({menuItem: 'settings'})}>User
                                    status</Link>
                            </div>
                        </li>
                        : ''
                    }

                    {/*
                    <li className={path === 'wholesale' ? 'active' : ''}>
                        <Link to="/wholesale" onClick={() => this.setState({menuItem: 'wholesale'})}>
                            <svg className="icon__settings" viewBox="0 0 23.72 23.72">
                                <path
                                    d="M23.21,14.1a2.12,2.12,0,0,1,0-4.2.72.72,0,0,0,.52-.32.74.74,0,0,0,.11-.6A12.26,12.26,0,0,0,22.5,5.76.71.71,0,0,0,22,5.41a.76.76,0,0,0-.6.14,2.16,2.16,0,0,1-2.79-.17,2.11,2.11,0,0,1-.17-2.79.76.76,0,0,0,.14-.6.78.78,0,0,0-.35-.5A12.44,12.44,0,0,0,15,.16a.74.74,0,0,0-.6.11.72.72,0,0,0-.32.51,2.12,2.12,0,0,1-4.2,0A.72.72,0,0,0,9.58.27.74.74,0,0,0,9,.16,12.44,12.44,0,0,0,5.76,1.49a.78.78,0,0,0-.35.5.76.76,0,0,0,.14.6,2.11,2.11,0,0,1-.17,2.79,2.15,2.15,0,0,1-2.79.17.75.75,0,0,0-1.1.21A12.58,12.58,0,0,0,.16,9a.75.75,0,0,0,.63.92,2.12,2.12,0,0,1,0,4.2A.75.75,0,0,0,.16,15a12.58,12.58,0,0,0,1.33,3.22.75.75,0,0,0,.5.35.73.73,0,0,0,.59-.14,2.17,2.17,0,0,1,2.8.17,2.11,2.11,0,0,1,.17,2.79.76.76,0,0,0-.14.6.78.78,0,0,0,.35.5A12.44,12.44,0,0,0,9,23.84l.19,0a.69.69,0,0,0,.41-.13.74.74,0,0,0,.32-.52,2.12,2.12,0,0,1,4.2,0,.72.72,0,0,0,.32.52.74.74,0,0,0,.6.11,12.58,12.58,0,0,0,3.22-1.33.75.75,0,0,0,.21-1.1,2.11,2.11,0,0,1,.17-2.79,2.16,2.16,0,0,1,2.79-.17.76.76,0,0,0,.6.14.71.71,0,0,0,.49-.35A12.26,12.26,0,0,0,23.84,15a.74.74,0,0,0-.11-.6A.72.72,0,0,0,23.21,14.1Zm-1.63,2.73a3.6,3.6,0,0,0-4.75,4.76,10.53,10.53,0,0,1-1.47.6,3.59,3.59,0,0,0-6.72,0,10.53,10.53,0,0,1-1.47-.6,3.61,3.61,0,0,0-.74-4,3.56,3.56,0,0,0-2.54-1.05,3.78,3.78,0,0,0-1.48.31,9.44,9.44,0,0,1-.6-1.47,3.59,3.59,0,0,0,0-6.72,9.44,9.44,0,0,1,.6-1.47,3.78,3.78,0,0,0,1.48.31A3.54,3.54,0,0,0,6.43,6.43a3.61,3.61,0,0,0,.74-4,10.53,10.53,0,0,1,1.47-.6,3.59,3.59,0,0,0,6.72,0,10.53,10.53,0,0,1,1.47.6,3.61,3.61,0,0,0,.74,4,3.56,3.56,0,0,0,2.54,1.05,3.69,3.69,0,0,0,1.47-.31,9.48,9.48,0,0,1,.61,1.47,3.59,3.59,0,0,0,0,6.72A9.48,9.48,0,0,1,21.58,16.83ZM12,5.56A6.44,6.44,0,1,0,18.44,12,6.45,6.45,0,0,0,12,5.56ZM12,17A4.95,4.95,0,1,1,17,12,5,5,0,0,1,12,17Zm0-8.47A3.52,3.52,0,1,0,15.52,12,3.53,3.53,0,0,0,12,8.48Zm0,4.91A1.39,1.39,0,1,1,13.39,12,1.39,1.39,0,0,1,12,13.39Z"
                                    transform="translate(-0.14 -0.14)"/>
                            </svg>
                            <span className="text">Wholesale</span>
                        </Link>
                    </li>

                    <li className={path === 'pricing' ? 'active' : ''}>
                        <Link to="/pricing" onClick={() => this.setState({menuItem: 'pricing'})}>
                            <svg className="icon__settings" viewBox="0 0 23.72 23.72">
                                <path
                                    d="M23.21,14.1a2.12,2.12,0,0,1,0-4.2.72.72,0,0,0,.52-.32.74.74,0,0,0,.11-.6A12.26,12.26,0,0,0,22.5,5.76.71.71,0,0,0,22,5.41a.76.76,0,0,0-.6.14,2.16,2.16,0,0,1-2.79-.17,2.11,2.11,0,0,1-.17-2.79.76.76,0,0,0,.14-.6.78.78,0,0,0-.35-.5A12.44,12.44,0,0,0,15,.16a.74.74,0,0,0-.6.11.72.72,0,0,0-.32.51,2.12,2.12,0,0,1-4.2,0A.72.72,0,0,0,9.58.27.74.74,0,0,0,9,.16,12.44,12.44,0,0,0,5.76,1.49a.78.78,0,0,0-.35.5.76.76,0,0,0,.14.6,2.11,2.11,0,0,1-.17,2.79,2.15,2.15,0,0,1-2.79.17.75.75,0,0,0-1.1.21A12.58,12.58,0,0,0,.16,9a.75.75,0,0,0,.63.92,2.12,2.12,0,0,1,0,4.2A.75.75,0,0,0,.16,15a12.58,12.58,0,0,0,1.33,3.22.75.75,0,0,0,.5.35.73.73,0,0,0,.59-.14,2.17,2.17,0,0,1,2.8.17,2.11,2.11,0,0,1,.17,2.79.76.76,0,0,0-.14.6.78.78,0,0,0,.35.5A12.44,12.44,0,0,0,9,23.84l.19,0a.69.69,0,0,0,.41-.13.74.74,0,0,0,.32-.52,2.12,2.12,0,0,1,4.2,0,.72.72,0,0,0,.32.52.74.74,0,0,0,.6.11,12.58,12.58,0,0,0,3.22-1.33.75.75,0,0,0,.21-1.1,2.11,2.11,0,0,1,.17-2.79,2.16,2.16,0,0,1,2.79-.17.76.76,0,0,0,.6.14.71.71,0,0,0,.49-.35A12.26,12.26,0,0,0,23.84,15a.74.74,0,0,0-.11-.6A.72.72,0,0,0,23.21,14.1Zm-1.63,2.73a3.6,3.6,0,0,0-4.75,4.76,10.53,10.53,0,0,1-1.47.6,3.59,3.59,0,0,0-6.72,0,10.53,10.53,0,0,1-1.47-.6,3.61,3.61,0,0,0-.74-4,3.56,3.56,0,0,0-2.54-1.05,3.78,3.78,0,0,0-1.48.31,9.44,9.44,0,0,1-.6-1.47,3.59,3.59,0,0,0,0-6.72,9.44,9.44,0,0,1,.6-1.47,3.78,3.78,0,0,0,1.48.31A3.54,3.54,0,0,0,6.43,6.43a3.61,3.61,0,0,0,.74-4,10.53,10.53,0,0,1,1.47-.6,3.59,3.59,0,0,0,6.72,0,10.53,10.53,0,0,1,1.47.6,3.61,3.61,0,0,0,.74,4,3.56,3.56,0,0,0,2.54,1.05,3.69,3.69,0,0,0,1.47-.31,9.48,9.48,0,0,1,.61,1.47,3.59,3.59,0,0,0,0,6.72A9.48,9.48,0,0,1,21.58,16.83ZM12,5.56A6.44,6.44,0,1,0,18.44,12,6.45,6.45,0,0,0,12,5.56ZM12,17A4.95,4.95,0,1,1,17,12,5,5,0,0,1,12,17Zm0-8.47A3.52,3.52,0,1,0,15.52,12,3.53,3.53,0,0,0,12,8.48Zm0,4.91A1.39,1.39,0,1,1,13.39,12,1.39,1.39,0,0,1,12,13.39Z"
                                    transform="translate(-0.14 -0.14)"/>
                            </svg>
                            <span className="text">Pricing</span>
                        </Link>
                    </li>
                    */}

                    {Access.isAllowed('Users', 'loginAs') ?
                        <div className="switch__account__box">
                            <Select
                                label="Switch Account"
                                options={this.state.userList}
                                onChange={this.doLoginAsUser}
                                size="small"
                            />
                        </div>
                        : ''
                    }

                </ul>
            </nav>
        );
    }
}
