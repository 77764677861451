import React from 'react';
import {apiRequest} from "../../components/Globals";
import moment from "moment";
import PropTypes from "prop-types";
import TextField from "../../components/form/TextField";
import Location from "../../components/form/Location";
import CheckBox from "../../components/form/CheckBox";
import Select from "../../components/form/Select";
import TextArea from "../../components/form/TextArea";
import {BOOKING_STATUS, CUSTOMER_SOURCE, DEFAULT_PRODUCTS, ROLES} from "../../config/Globals";
import {User} from "../../components/form/model/User";
import {Booking} from "../../components/form/model/Booking";
import {DROPDOWN_TIMES} from "../../config/Times";
import {notify} from "../../components/Notify";
import Modal from "../../components/Modal";
import {Auth} from "../../components/Auth";
import Select2 from "react-select";

export default class BookingEvent extends React.Component {
    static propTypes = {
        calendarEvent: PropTypes.instanceOf(Booking),
        user: PropTypes.instanceOf(User),
        onChange: PropTypes.func,
        auth: PropTypes.instanceOf(Auth),
        doDelete: PropTypes.func
    };

    state = {
        logs: [],
    };

    hasBookingMessage: string = '';


    modalCancelConfirm = {
        show: false
    };

    booking: Booking;
    eventCheckHash: string;


    constructor(props) {
        super(props);

        // this.loadLogs = this.loadLogs.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);

        this.booking = this.props.calendarEvent;

        if (!this.booking) {
            this.booking = new Booking();
        }

        if (this.props.auth.role === ROLES.commercial) {
            let notRequiredForCommercial = ['firstName', 'lastName', "phone", 'email', "outdoorBlinds", "indoorShutters", "message", 'secure', "curtains", "howToKnow", "blinds"];
            notRequiredForCommercial.forEach(fieldName => {
                this.booking.removeValidator(fieldName);
            });
        } else if (this.props.auth.role !== ROLES.commercial) {
            this.booking.removeValidator('company');
        }

        this.eventCheckHash = this.booking.hash;
    }

    injectCustomTime(value) {
        let exists = false;

        DROPDOWN_TIMES.some(item => {
            if (item.value === value) {
                exists = true;

                return true;
            }

            return false;
        });

        let result = [...DROPDOWN_TIMES];

        if (!exists && value) {
            result.unshift({
                value: value,
                label: moment('2021-01-15 ' + value).format('h:mma')
            });
        }

        return result;
    }

    onChange() {
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    onLoading(show: boolean) {
        if (this.props.onLoading) {
            this.props.onLoading(show);
        }
    }

    onSave() {
        if (!this.validate()) {
            this.setState({});

            return;
        }

        this.onLoading(true);

        apiRequest(
            '/bs/booking/edit',
            {
                repId: this.props.user.id,
                booking: {
                    address: this.booking.address,
                    email: this.booking.email,
                    firstName: this.booking.firstName,
                    lastName: this.booking.lastName,
                    id: this.booking.id,
                    lat: this.booking.lat,
                    lng: this.booking.lng,
                    phone: this.booking.phone,
                    date: this.booking.date,
                    message: this.booking.message,
                    callBefore: this.booking.callBefore,
                    howToKnow: this.booking.howToKnow,
                    company: this.booking.company,
                    is_lead: this.booking.isLead ? 1 : 0,
                    send_sms: this.booking.smsReminder ? 1 : 0,

                    blinds: this.booking.blinds,
                    outdoorBlinds: this.booking.outdoorBlinds,
                    indoorShutters: this.booking.indoorShutters,
                    curtains: this.booking.curtains,
                    secure: this.booking.secure,
                    productList: this.booking.getProductsNames(),

                    postalCode: this.booking.postalCode,
                    suburb: this.booking.suburb,
                    timeFrom: this.booking.timeFrom,
                    timeTo: this.booking.timeTo,
                    unitNo: this.booking.unitNo,
                }
            }
        )
            .then(() => {
                // this.loading++;
                // this.bookingService
                //     .resetPagination()
                //     .loadList();

                this.onChange();

                this.showBookingPopup(false);
                // this.loadingHide();
            })
            .catch(data => {
                this.updateLastError(data);
                notify({text: data.message});
                this.onLoading(false);
                this.setState({});
            });
    }

    updateLastError(data) {
        this.lastErrors = data.fields ? data.fields : {};
    }

    doDelete() {
        if (this.props.doDelete) {
            return this.props.doDelete();
        }
    }

    onDelete() {
        this.showBookingPopup(false);
        this.onLoading(true);

        apiRequest('/bs/booking/change-status', {
            id: this.booking.id,
            status: BOOKING_STATUS.canceled
        })
            .then(() => {
                notify({
                    text: 'Booking has been cancelled.'
                });
                this.doDelete();
                this.onLoading(false);
            })
            .catch(response => {
                this.onLoading(false);
                notify({
                    text: response.message
                })
            });
    }

    showBookingPopup(show: boolean): void {
        if (!show) {
            this.modalCancelConfirm.show = false;
        } else {
            this.lastErrors = {};
        }

        this.setState({});
    }

    onAddressChange(event) {
        if (!event.target.currentLocation.streetNumber) {
            return;
        }

        this.booking.postalCode = event.target.currentLocation.postalCode;
        this.booking.suburb = event.target.currentLocation.locality;
        this.booking.address = event.target.currentLocation.streetNumber + ' ' + event.target.currentLocation.street;

        apiRequest('/bs/booking/check-booking-date', {
            date: this.booking.date,
            // address: event.target.currentLocation.text,
            address: this.booking.address,
            bookings: this.booking.id
        })
            .then((data) => {
                this.hasBookingMessage = '';
                data?.custom?.otherBookings.forEach(item => {
                    // 'fq.id',
                    //     'bd.date',
                    //     'bd.time_from',
                    //     'bd.time_to',
                    //     'u.full_name'

                    this.hasBookingMessage += item.date + ' '
                        + moment(item.date + ' ' + item.time_from).format('HH:mm') + ' - '
                        + moment(item.date + ' ' + item.time_to).format('HH:mm') + ' for '
                        + item.full_name + '; ';
                });

                this.setState({});
            });

        this.setState({});
    }

    validate() {
        this.lastErrors = [];
        if (!this.booking.isValid()) {
            this.booking.validationMessages.forEach(item => {
                this.lastErrors[item.fieldName] = item.message;
            });

            return false;
        }

        return true;
    }


    render() {
        if (!this.booking.id) {
            this.booking.isLead = !this.props.auth.reportExclude;
        }

        return (
            <>
                <div className="panel__top">
                    <div
                        className="panel__title">
                        {this.booking.id ? 'Booking #' + this.booking.id : 'Add M&Q'} for {this.props.user.firstName + ' ' + this.props.user.lastName}
                        {this.booking.creatorName ? ' by ' + this.booking.creatorName : ''}
                    </div>
                </div>

                <div className="panel__body">
                    <div className="calendar__booking__box">

                        <div className="item">
                            <div className="form__row">
                                <div className="form__label">Firstname</div>
                                <div className="form__field">
                                    <TextField
                                        label=""
                                        size="small"
                                        value={this.booking.firstName}
                                        onChange={(e) => {
                                            this.booking.firstName = e.target.value
                                        }}
                                        errorMessage={this.lastErrors ? this.lastErrors['firstName'] : ''}
                                    />
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__label">Lastname</div>
                                <div className="form__field"><TextField
                                    label=""
                                    size="small"
                                    value={this.booking.lastName}
                                    onChange={(e) => {
                                        this.booking.lastName = e.target.value
                                    }}
                                    errorMessage={this.lastErrors ? this.lastErrors['lastName'] : ''}
                                /></div>
                            </div>
                            <div className="form__row">
                                <div className="form__label">Company</div>
                                <div className="form__field"><TextField
                                    label=""
                                    size="small"
                                    value={this.booking.company}
                                    onChange={(e) => {
                                        this.booking.company = e.target.value
                                    }}
                                    errorMessage={this.lastErrors ? this.lastErrors['company'] : ''}
                                /></div>
                            </div>
                            <div className="form__row">
                                <div className="form__label">Email</div>
                                <div className="form__field"><TextField
                                    label=""
                                    size="small"
                                    value={this.booking.email}
                                    onChange={(e) => {
                                        this.booking.email = e.target.value
                                    }}
                                    errorMessage={this.lastErrors ? this.lastErrors['email'] : ''}
                                /></div>
                            </div>
                            <div className="form__row">
                                <div className="form__label">Phone</div>
                                <div className="form__field"><TextField
                                    label=""
                                    size="small"
                                    value={this.booking.phone}
                                    onChange={(e) => {
                                        this.booking.phone = e.target.value
                                    }}
                                    errorMessage={this.lastErrors ? this.lastErrors['phone'] : ''}
                                /></div>
                            </div>
                            <div className="form__row">
                                <div className="form__label">Unit No.</div>
                                <div className="form__field"><TextField
                                    label=""
                                    size="small"
                                    value={this.booking.unitNo}
                                    onChange={(e) => {
                                        this.booking.unitNo = e.target.value
                                    }}
                                    errorMessage={this.lastErrors ? this.lastErrors['unitNo'] : ''}
                                /></div>
                            </div>
                            <div className="form__row">
                                <div className="form__label">Address</div>
                                <div className="form__field"><Location
                                    size="small"
                                    label=""
                                    value={this.booking.address}
                                    errorMessage={this.lastErrors ? this.lastErrors['address'] : ''}
                                    onChange={(e) => {
                                        this.booking.address = e.target.value
                                    }}
                                    onPlaceChanged={this.onAddressChange}
                                />
                                    {this.hasBookingMessage &&
                                    <div style={{color: '#ff832b'}}>
                                        <div>The address you entered has a booking. Please check to avoid double booking.</div>
                                        <div>{this.hasBookingMessage}</div>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__label">Suburb</div>
                                <div className="form__field"><TextField
                                    label=""
                                    size="small"
                                    value={this.booking.suburb}
                                    onChange={(e) => {
                                        this.booking.suburb = e.target.value
                                    }}
                                    errorMessage={this.lastErrors ? this.lastErrors['suburb'] : ''}
                                /></div>
                            </div>
                            <div className="form__row">
                                <div className="form__label">Postcode</div>
                                <div className="form__field"><TextField
                                    label=""
                                    size="small"
                                    value={this.booking.postalCode}
                                    onChange={(e) => {
                                        this.booking.postalCode = e.target.value
                                    }}
                                    errorMessage={this.lastErrors ? this.lastErrors['postalCode'] : ''}
                                /></div>
                            </div>
                            <div className="form__row">
                                <div className="form__label">Date</div>
                                <div className="form__field"><TextField
                                    label=""
                                    size="small"
                                    value={moment(this.booking.date).format('DD-MM-YYYY')}
                                    onChange={(e) => {
                                        this.booking.date = e.target.value
                                    }}
                                    errorMessage={this.lastErrors ? this.lastErrors['date'] : ''}
                                /></div>
                            </div>
                            {this.props.auth.role !== ROLES.commercial && <div className="form__row">
                                <div className="form__label"></div>
                                <div className="form__fields"><CheckBox
                                    checked={this.booking.callBefore}
                                    onChange={(e) => {
                                        this.booking.callBefore = e.target.checked ? true : false
                                    }}
                                >Call customer before appointment</CheckBox>
                                </div>
                            </div>}
                        </div>

                        <div className="item">

                            {this.props.auth.role !== ROLES.commercial &&
                                <>
                                    <div className="form__row">
                                        <div className="form__label">Products:</div>

                                        <Select2
                                            label=""
                                            options={this.booking.productList ? this.booking.productList : DEFAULT_PRODUCTS}
                                            onChange={(e, options) => this.booking.chooseProducts(e, options)}
                                            isMulti
                                            errorMessage={this.lastErrors ? this.lastErrors['company'] : ''}
                                            defaultValue={this.booking.products}
                                            // theme={theme => ({
                                            //     ...theme,
                                            //     cursor: 'pointer',
                                            //     borderRadius: 2,
                                            //     fontSize: 14,
                                            //     colors: {
                                            //         ...theme.colors,
                                            //         primary: '#cccfd3',
                                            //         primary25: '#f2f2f2',
                                            //         primary50: '#f2f2f2'
                                            //     }
                                            // })}
                                            // styles={{
                                            //     control: styles => ({
                                            //         ...styles,
                                            //         border: '1px solid #dadcdf',
                                            //         height: 'auto',
                                            //         cursor: 'pointer',
                                            //     }),
                                            //     indicatorSeparator: styles => ({
                                            //         ...styles,
                                            //         display: 'none'
                                            //     }),
                                            //     indicatorsContainer: styles => ({
                                            //         ...styles,
                                            //         height: 39
                                            //     }),
                                            //     menuList: styles => ({
                                            //         ...styles,
                                            //         overflowY: 'auto',
                                            //         maxHeight: '300px'
                                            //     }),
                                            //     singleValue: styles => ({
                                            //         fontSize: 14,
                                            //     }),
                                            //     dropdownIndicator: styles => ({
                                            //         ...styles,
                                            //         marginRight: 8,
                                            //         width: 32
                                            //     }),
                                            //     valueContainer: styles => ({
                                            //         ...styles,
                                            //         // height: 32,
                                            //         padding: '2px 15px'
                                            //     }),
                                            //     multiValue: styles => ({
                                            //         ...styles,
                                            //         fontSize: 12
                                            //     })
                                            // }}
                                        />
                                    </div>
                                    <div className="form__row">
                                        <div className="form__label">Qty.</div>
                                        <div className="form__field"><TextField
                                            label="Blinds"
                                            ref={this.refQtyBlinds}
                                            size="small"
                                            value={this.booking.blinds}
                                            onChange={(e) => {
                                                this.booking.blinds = e.target.value;
                                            }}
                                            errorMessage={this.lastErrors ? this.lastErrors['blinds'] : ''}
                                        /></div>
                                    </div>
                                    <div className="form__row">
                                        <div className="form__label">Qty.</div>
                                        <div className="form__field"><TextField
                                            label="Indoor Shutters"
                                            ref={this.refQtyIndoorShutters}
                                            size="small"
                                            value={this.booking.indoorShutters}
                                            onChange={(e) => {
                                                this.booking.indoorShutters = e.target.value;
                                            }}
                                            errorMessage={this.lastErrors ? this.lastErrors['indoorShutters'] : ''}
                                        /></div>
                                    </div>
                                    <div className="form__row">
                                        <div className="form__label">Qty.</div>
                                        <div className="form__field"><TextField
                                            label="Curtains"
                                            ref={this.refQtyCurtains}
                                            size="small"
                                            value={this.booking.curtains}
                                            onChange={(e) => {
                                                this.booking.curtains = e.target.value;
                                            }}
                                            errorMessage={this.lastErrors ? this.lastErrors['curtains'] : ''}
                                        /></div>
                                    </div>
                                    <div className="form__row">
                                        <div className="form__label">Qty.</div>
                                        <div className="form__field"><TextField
                                            label="Outdoor (Blinds & Shutters)"
                                            ref={this.refQtyOutdoorBlinds}
                                            size="small"
                                            value={this.booking.outdoorBlinds}
                                            onChange={(e) => {
                                                this.booking.outdoorBlinds = e.target.value;
                                            }}
                                            errorMessage={this.lastErrors ? this.lastErrors['outdoorBlinds'] : ''}
                                        /></div>
                                    </div>
                                    <div className="form__row">
                                        <div className="form__label">Qty.</div>
                                        <div className="form__field"><TextField
                                            label="Security doors & window screens"
                                            // ref={this.refQtyOutdoorBlinds}
                                            size="small"
                                            value={this.booking.secure}
                                            onChange={(e) => {
                                                this.booking.secure = e.target.value;
                                            }}
                                            errorMessage={this.lastErrors ? this.lastErrors['secure'] : ''}
                                        /></div>
                                    </div>
                                </>
                            }

                            <div className="form__row">
                                <div className="form__label">Appointment Start</div>
                                <div className="form__field">
                                    <Select
                                        label=""
                                        size="small"
                                        options={this.injectCustomTime(this.booking.timeFrom)}
                                        defaultValue={this.booking.timeFrom}
                                        onChange={(e) => {
                                            this.booking.timeFrom = e.target.value
                                        }}
                                        errorMessage={this.lastErrors ? this.lastErrors['timeFrom'] : ''}
                                    />
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__label">Appointment End</div>
                                <div className="form__field">
                                    <Select
                                        label=""
                                        size="small"
                                        options={this.injectCustomTime(this.booking.timeTo)}
                                        defaultValue={this.booking.timeTo}
                                        onChange={(e) => {
                                            this.booking.timeTo = e.target.value
                                        }}
                                        errorMessage={this.lastErrors ? this.lastErrors['timeTo'] : ''}
                                    />
                                </div>
                            </div>

                            <div className="form__row">
                                <div className="form__label">Notes</div>
                                <div className="form__fields"><TextArea
                                    label=""
                                    size="small"
                                    value={this.booking.message}
                                    onChange={(e) => {
                                        this.booking.message = e.target.value
                                    }}
                                    errorMessage={this.lastErrors ? this.lastErrors['message'] : ''}
                                /></div>
                            </div>


                            {this.props.auth.role !== ROLES.commercial && <div className="form__row">
                                <div className="form__label">Referral</div>
                                <div className="form__field">
                                    <Select
                                        label=""
                                        size="small"
                                        options={CUSTOMER_SOURCE}
                                        defaultValue={this.booking.howToKnow}
                                        onChange={(e) => {
                                            this.booking.howToKnow = e.target.value
                                        }}
                                        errorMessage={this.lastErrors ? this.lastErrors['howToKnow'] : ''}
                                    />
                                </div>
                            </div>}

                        </div>

                    </div>
                </div>
                <div className="panel__bottom">
                    <div className="bh bh--right">
                        <button className="btn" onClick={this.onSave}>{this.booking.id ? 'Update' : 'Add'} M&Q</button>
                        {this.booking.id ?
                            <button
                                className="btn btn--grey"
                                onClick={() => {
                                    this.modalCancelConfirm.show = true;
                                    this.setState({})
                                }}
                            >Cancel Booking</button> : ''}
                        <span className="mq__lead__box">
                                <CheckBox
                                    checked={this.booking.isLead}
                                    onChange={(e) => {
                                        this.booking.isLead = e.target.checked;
                                    }}
                                >This is a M&Q Lead</CheckBox>
                            </span>

                        <span className="mq__lead__box">
                                <CheckBox
                                    checked={this.booking.smsReminder}
                                    onChange={(e) => {
                                        this.booking.smsReminder = e.target.checked;
                                    }}
                                >Send SMS Reminder</CheckBox>
                            </span>
                    </div>

                </div>


                <Modal
                    status={this.modalCancelConfirm}
                >

                    <div className="panel">
                        <div className="panel__top">
                            <div className="panel__title">Cancel Booking</div>
                        </div>
                        <div className="panel__body">
                            Are you sure you want to cancel this booking?

                        </div>
                        <div className="panel__bottom">
                            <div className="bh">
                                <button className="btn" onClick={this.onDelete}>Yes</button>
                                <button
                                    className="btn"
                                    onClick={() => {
                                        this.modalCancelConfirm.show = false;
                                        this.setState({})
                                    }}
                                >No
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>


            </>
        );
    }
}
