import React from 'react';

export default class Pricing extends React.Component {

    render() {

        return (
            <>
                Price list
            </>
        );
    }
}