import React from 'react';
import moment from "moment";
import PropTypes from "prop-types";
import {apiRequest} from "../components/Globals";
import Pagination from "../components/Pagination";

export default class UserLogs extends React.Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date),
        userId: PropTypes.number
    };

    state = {
        logs: [],
    };
    pagination: Pagination = new Pagination();
    scrollingDebounce = null;
    scrollBarrier: number = 850;

    constructor(props) {
        super(props);

        this.handleScroll = this.handleScroll.bind(this);

    }

    componentDidMount(): void {
        this.pagination.setPageSize(9);
        this.doLoadLogs();

        let searchList = document.querySelector('.userLogs');

        searchList.addEventListener("scroll", () => {
            this.scrollOnload(this.handleScroll, 100);
        });

    }


    doLoadLogs() {
        if (!this.pagination.hasMore) {
            return;
        }

        let payload = {
            userId: this.props.userId,
            pagination: this.pagination,
        };

        apiRequest("/bs/user/get-log", payload)
            .then(result => {

                if (result) {
                    this.setState({logs: result.custom});
                }

                if (this.onDataChange) {
                    this.onDataChange();
                }
            });
    }


    handleScroll() {
        let searchList = document.querySelector('.userLogs');

        if (searchList.scrollTop >= this.scrollBarrier) {
            this.scrollBarrier += 850;
            this.loadMoreLogs();
        }
    }


    scrollOnload(callback, time) {
        if (this.scrollingDebounce) {
            clearTimeout(this.scrollingDebounce);
        }

        this.scrollingDebounce = setTimeout(
            () => {
                callback();
            },
            time
        );
    };

    loadMoreLogs() {
        this.pagination.page++;
        this.doLoadLogs();
    };

    render() {

        return (
            <>
                <div className="abc__tabs">
                    <div className="abc__tabs__item abc__tabs__item--active">Logs</div>
                </div>

                <div className="item panel__body" key={Math.random()}>

                    {this.state.logs.map(item => {
                        let shortcat = '', fullName;

                        if (item.full_name) {
                            const shortcatParts = item.full_name.split(' ');
                            shortcat += shortcatParts[0].substr(0, 1).toUpperCase();

                            if (shortcatParts[1]) {
                                shortcat += shortcatParts[1].substr(0, 1).toUpperCase();
                            }
                            fullName = item.full_name;

                        }

                        return (
                            <div className="logs" key={Math.random()}>
                                <div className="logs__column">
                                    <div className="logs__avatar">{shortcat}</div>
                                </div>
                                <div className="logs__column">
                                    <div className="logs__info">
                                        <span className="logs__info__name">{fullName}</span>
                                        <span
                                            className="logs__info__time">{moment(item.date_add).format('D MMMM Y h:mma')}</span>
                                    </div>
                                    <div className="logs__details">
                                        <span className="logs__info__name">Description</span>
                                        <span>IP: {item.ip}; HOST: {item.host}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </>
        )
            ;
    }
}