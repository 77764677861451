import React, {Component} from 'react';

import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom"

import './App.scss';
import Forms from "./pages/Forms";
import Login from "./pages/Login";
import Header from "./components/Header";
import Menu from "./components/Menu";
import Availability from "./pages/Availability";
import Calendar from "./pages/Calendar";
import MeasureAndQuote from "./pages/MeasureAndQuote";
import Routing from "./pages/Routing";
import TodaysRun from "./pages/TodaysRun";
import Users from "./pages/Users";
import Notify from "./components/Notify";
import {Auth} from "./components/Auth";
import Access from "./components/Access";
import Settings from "./pages/Settings";
import ConfigService from "./service/ConfigService";
import UserStatus from "./pages/UserStatus";
import FittingTodaysRun from "./pages/FittingTodaysRun";
import Fittings from "./pages/Fittings";
import Wholesale from "./pages/Wholesale";
import Pricing from "./pages/Pricing";
import Modal from "./components/Modal";
import {apiRequest} from "./components/Globals";
import Reports from "./pages/Reports";
import MarketingReport from "./pages/MarketingReport";


export default class App extends Component {
    state = {
        app: {
            isLoading: false
        },
        calendarDate: null
    };

    auth: Auth = new Auth();
    config: ConfigService = null;
    initTries: 5;

    checkApi;
    defaultPingApiInterval = 30000;
    modalApiError = {
        show: false
    };

    constructor(props) {
        super(props);

        this.onStateChanged = this.onStateChanged.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.checkConfigLoaded = this.checkConfigLoaded.bind(this);
        this.loadConfiguration = this.loadConfiguration.bind(this);
        this.setCheckingApi = this.setCheckingApi.bind(this);

        this.config = new ConfigService(true);
    }


    componentDidMount(): void {
        this.loadConfiguration();
    }

    setCheckingApi() {
        let pingInterval = this.config.data.pingInterval ? this.config.data.pingInterval : this.defaultPingApiInterval;

        if (!this.checkApi) {
            this.checkApi = setInterval(() => {
                this.checkOnlineApi();
            }, pingInterval)
        }
    }

    checkOnlineApi() {
        this.setCheckingApi();

        apiRequest('/bs/user/ping')
            .then(result => {
                if (this.modalApiError.show === true) {
                    this.modalApiError.show = false;
                    this.setState({});
                }
            })
            .catch(error => {
                this.modalApiError.show = true;
                this.setState({});
                console.error('err', error);
            });

        if (window.navigator.onLine === false) {
            this.modalApiError.show = true;
            this.setState({});
        }
    }

    /**
     * Manage changes from children to children
     */
    onStateChanged(changes) {
        let app = Object.assign(this.state.app, changes);
        this.setState({app: app});
    }

    onDateChange(date) {
        this.setState({calendarDate: date});
    }

    checkConfigLoaded() {
        if (!this.config.data || !this.config.data.apiUrl) {
            setTimeout(this.checkConfigLoaded, 500);

            return;
        }

        this.setState({});
    }

    loadConfiguration(): void {
        if (!this.config.data) {
            this.initTries--;

            if (this.initTries === 0) {
                throw new Error('Can not load configuration');
            }

            setTimeout(() => {
                this.loadConfiguration()
            }, 500);

            return;
        }

        if (this.config.data && this.auth.isAuthorized()) {
            this.checkOnlineApi();
        }

        this.loadGoogleApi();
    }


    loadGoogleApi() {
        if (document.getElementById('abc-google-maps-api')) {
            return;
        }

        let googleKey = this.config.data.googleKey;

        const script = document.createElement("script");
        script.src = 'https://maps.googleapis.com/maps/api/js?key=' + googleKey + '&libraries=places&language=en&callback=Function.prototype';
        script.async = true;
        script.id = 'abc-google-maps-api';
        // script.onload = () => this.onGoogleScriptLoaded();
        document.body.appendChild(script);
    }

    render() {
        this.auth.load();

        if (!this.config.data || !this.config.data.apiUrl) {
            this.checkConfigLoaded();

            return '';
        }

        return (
            <>
                <Modal status={this.modalApiError}>
                    <div className="panel">
                        <div className="panel__top">
                            <div className="panel__title"> Couldn't connect to server, API is offline now.
                            </div>
                        </div>
                    </div>
                </Modal>

                <BrowserRouter>
                    {!this.auth.isAuthorized()  || this.modalApiError.show === true ? <Login onLogin={() => {
                            this.checkOnlineApi();
                            this.setState({calendarDate: null});
                        }}/> :
                        <>
                            <Header onLogout={() => {
                                this.setState({})
                            }}/>
                            <div className="layout">
                                <div className="layout__col1">
                                    <Menu onDateChange={this.onDateChange} initPath={this.auth.startUrl}/>
                                </div>
                                <div className="layout__col2">
                                    <Switch>
                                        <Route
                                            exact path="/" render={
                                            () => (<Redirect to={this.auth.startUrl}/>)
                                        }
                                        />

                                        <Route exect path="/forms" component={Forms}/>
                                        <Route exect path="/login" component={Login}/>

                                        {Access.isAllowed('MeasureAndQuote', 'availability') ?
                                            <Route exect path="/availability" component={Availability}/>
                                            : ''
                                        }

                                        {Access.isAllowed('Calendar', 'view') ?
                                            <Route exect path="/calendar" render={(props) => (
                                                <Calendar date={this.state.calendarDate} {...props} />)}/>
                                            : ''
                                        }

                                        {Access.isAllowed('MeasureAndQuote', 'view') ?
                                            <Route exect path="/measure-and-quote" component={MeasureAndQuote}/>
                                            : ''
                                        }

                                        {Access.isAllowed('Fitter', 'view') ?
                                            <Route exect path="/fittings" component={Fittings}/>
                                            : ''
                                        }

                                        {Access.isAllowed('Routing', 'view') ?
                                            <Route exect path="/routing" component={Routing}/>
                                            : ''
                                        }

                                        {Access.isAllowed('Routing', 'view') ?
                                            <Route exect path="/settings" component={Settings}/>
                                            : ''
                                        }

                                        {Access.isAllowed('Routing', 'view') ?
                                            <Route exect path="/reports" component={Reports}/>
                                            : ''
                                        }

                                        {Access.isAllowed('Routing', 'view') ?
                                            <Route exect path="/marketing-report" component={MarketingReport}/>
                                            : ''
                                        }

                                        {Access.isAllowed('TodaysRun', 'view') ?
                                            <Route exect path="/todays-run" component={TodaysRun}/>
                                            : ''
                                        }

                                        {Access.isAllowed('Fitter', 'view') ?
                                            <Route exect path="/fitting-todays-run" component={FittingTodaysRun}/>
                                            : ''
                                        }

                                        {Access.isAllowed('Users', 'view') ?
                                            <Route exect path="/users" component={Users}/>
                                            : ''
                                        }

                                        {Access.isAllowed('Users', 'view') ?
                                            <Route exect path="/user-status" component={UserStatus}/>
                                            : ''
                                        }
                                        <Route exect path="/wholesale" component={Wholesale}/>
                                        <Route exect path="/pricing" component={Pricing}/>
                                    </Switch>
                                </div>
                            </div>
                        </>
                    }
                    <Notify/>
                </BrowserRouter>
            </>
        );
    }
}