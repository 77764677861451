export default class Pagination {
    pageSize: number = 15;
    page: number = 0;
    hasMore: boolean = true;

    reset() {
        this.page = 0;
        this.hasMore = true;
    }

    assign(data: {}) {
        if (data.pageSize) {
            this.pageSize = data.pageSize;
        }

        if (data.start) {
            this.start = data.start;
        }
    }

    setPageSize(size: number): this {
        this.pageSize = size;

        return this;
    }

    setPage(page: number): this {
        this.page = page;

        return this;
    }
}