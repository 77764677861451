import React, {Component} from 'react';
import PropTypes from "prop-types";
import {BaseModel} from "./model/BaseModel";

export default class CheckBox extends Component {
    checked: boolean = false;
    value: string = '';

    static propTypes = {
        name: PropTypes.string,
        underline: PropTypes.bool,
        disabled: PropTypes.bool,
        defaultChecked: PropTypes.bool,
        controller: PropTypes.instanceOf(BaseModel)
    };

    constructor(props) {
        super(props);

        this.refMainContainer = React.createRef();
        this.checked = this.props.checked;
        this.value = props.value;

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        this.checked = event.target.checked;

        if (this.props.onChange) {
            let ev = {
                target: this
            };

            this.props.onChange(ev);
        }
    }

    render() {
        let {id, children, checked, inline, onChange, ...rest} = this.props;

        if (!id) {
            id = 'tf' + Math.random();
        }

        let customClassName = '';

        if (this.props.disabled) {
            customClassName += ' abc__checkbox__box--disabled';
        }

        return (
            <>
                <div className={'abc__checkbox__box' + customClassName}>
                    <input
                        type="checkbox"
                        className="abc__checkbox__input"
                        id={id}
                        defaultChecked={checked}
                        onChange={this.onChange}
                        {...rest}
                    />
                    <label htmlFor={id} className="abc__checkbox__option">
                            <span className="abc__checkbox__button">
                                <svg className="svg__checkbox__checkmark" viewBox="0 0 20 20">
                                    <path className="svg__checkmark__path" fill="none" d="M19.3,4.1L7,16.4l-6.1-6.1"/>
                                </svg>
                            </span>
                        <span className="abc__checkbox__text">{children}</span>
                    </label>
                </div>
            </>
        );
    }
};
