import {BaseModel} from "./BaseModel";
import {NotEmptyValidator} from "../validators/NotEmptyValidator";
import {RegExpValidator} from "../validators/RegExpValidator";
import {EmailValidator} from "../validators/EmailValidator";

export class User extends BaseModel {
    address: string = null;
    email: string = null;
    firstName: string = null;
    lastName: string = null;
    id: number = null;
    lat: number = null;
    lng: number = null;
    measuring: [] = [];
    priority: [] = [];
    showrooms: [] = [];
    phone: string = null;
    rating: number = null;
    reset: number = null;
    role: number = null;
    showroom: number = null;
    isSpecificArea: boolean = null;
    specificAreas: [] = [];
    status: number = null;
    company: string = null;
    available: boolean = true;
    onlineStatus: string = null;
    isOverlap: boolean = false;
    isHolding: boolean = false;
    reports: boolean = false;
    receiveConsultantRuns: boolean = true;
    receiveFitterRuns: boolean = true;

    constructor() {
        super();

        this.addValidator('firstName', new NotEmptyValidator('Firstname is required'))
            .addValidator('lastName', new NotEmptyValidator('Lastname is required'))
            // .addValidator('phone', new RegExpValidator(/^\d{6,15}$/, '@fieldName is required'))
            // .addValidator('phone',  new RegExpValidator(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s\\./0-9]*$/g, 'Incorrect phone number'))
            .addValidator('phone', new RegExpValidator(/^(\d{8}|\d{10})$/, 'Incorrect phone number'))
            .addValidator('email', new EmailValidator())
            .addValidator('role', new NotEmptyValidator('User type is required'))
        ;
    }

    measuringHas(value: string): boolean {
        if (!this.measuring) {
            return false
        }

        return this.measuring.includes(value);
    }

    addMeasuring(value: string): this {
        if (!this.measuringHas(value)) {
            this.measuring.push(value);
        }

        return this;
    }

    removeMeasuring(value: string): this {
        let ind = this.measuring.indexOf(value);

        if (ind !== -1) {
            this.measuring.splice(ind, 1);
        }

        return this;
    }

    priorityHas(value: string): boolean {
        if (!this.priority) {
            return false
        }

        return this.priority.includes(value);
    }

    addPriority(value: string): this {
        if (!this.priorityHas(value)) {
            this.priority.push(value);
        }

        return this;
    }

    removePriority(value: string): this {
        let ind = this.priority.indexOf(value);

        if (ind !== -1) {
            this.priority.splice(ind, 1);
        }

        return this;
    }

    showroomHas(value: string): boolean {
        if (!this.showrooms) {
            return false
        }

        return this.showrooms.includes(value);
    }

    addShowroom(value: string): this {
        if (!this.showroomHas(value)) {
            this.showrooms.push(value);
        }

        return this;
    }

    removeShowroom(value: string): this {
        let ind = this.showrooms.indexOf(value);

        if (ind !== -1) {
            this.showrooms.splice(ind, 1);
        }

        return this;
    }

    setProperty(name: string, value: *): this {
        switch (name) {
            case 'full_name':
                const userName = value.split(' ');
                this.firstName = userName[0];

                if (userName[2]) {
                    this.lastName = `${userName[1]} ${userName[2]}`;
                } else {
                    this.lastName = userName[1];
                }
                break;

            case 'measuring':
                if (typeof value === 'string') {
                    this.measuring = value.split(',');
                } else if (Array.isArray(value)) {
                    this.measuring = value;
                }

                // this.measuring.forEach((o, i, array) => array[i] = parseInt(array[i]));

                break;

            case 'measuring_priority':
                if (value) {
                    if (typeof value === 'string') {
                        this.priority = value.split(',');
                    } else if (Array.isArray(value)) {
                        this.priority = value;
                    }
                } else {
                    this.priority = [];
                }
                break;

            case 'specific_areas':
                if (typeof value === 'string') {
                    this.specificAreas = value.split(',');
                } else if (Array.isArray(value)) {
                    this.specificAreas = value;
                }

                break;

            case 'specific_area_status':
                this.isSpecificArea = !!value;

                break;

            case 'role':
                this.role = value ? value.toString() : '';

                break;

            case 'rating':
                this.rating = value ? value.toString() : '';

                break;

            case 'is_holding':
                this.isHolding = value === 1;

                break;

            case 'receive_consultant_runs':
                this.receiveConsultantRuns = value === 1;
                break;

            case 'receive_fitter_runs':
                this.receiveFitterRuns = value === 1;
                break;

            case 'report_exclude':
                this.reports = value === 1;

                break;

            default:
                super.setProperty(name, value);
        }

        return this;
    }


    clean(): this {
        super.clean();

        this.measuring = [];
        this.showrooms = [];
        this.specificAreas = [];
        this.receiveFitterRuns = true;
        this.receiveConsultantRuns = true;

        return this;
    }
}