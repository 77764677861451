import React from 'react';
import TextField from "../components/form/TextField";
import Select from "../components/form/Select";
import CheckBox from "../components/form/CheckBox";
import BoxGroup from "../components/form/BoxGroup";
import {BOOKING_COUNT, MEASUREMENTS, ROLES, SHOWROOMS, USER_ROLES} from "../config/Globals";
import {apiRequest} from "../components/Globals";
import {daysOfWeek, DROPDOWN_TIMES, getDefaultTimes} from "../config/Times";
import Switch from "../components/form/Switch";
import {User} from "../components/form/model/User";
import {notify} from "../components/Notify";
import {Auth} from "../components/Auth";
import Modal from "../components/Modal";
import UserLogs from "./UserLogs";


export default class Users extends React.Component {
    state = {
        loaded: false,
        userList: null,
        isSimpleView: false,
        showTerritories: false,
        link: '',
        saveBtnDisable: false,
    };

    workingTime: [] = getDefaultTimes();
    currentUser: User = new User();
    userLogModal = {
        show: false
    };

    constructor(props) {
        super(props);

        this.refAddress = React.createRef();
        this.refPostCodes = React.createRef();
        this.refWorkingHours = React.createRef();

        this.chooseRep = this.chooseRep.bind(this);
        this.getSchedule = this.getSchedule.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.doSave = this.doSave.bind(this);
        this.onDaySwitch = this.onDaySwitch.bind(this);
        this.onRoleChange = this.onRoleChange.bind(this);
        this.doUpdateSelectRating = this.doUpdateSelectRating.bind(this);
        this.doUpdateMeasureType = this.doUpdateMeasureType.bind(this);
        this.doUpdatePriority = this.doUpdatePriority.bind(this);
        this.onTimeChange = this.onTimeChange.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
        this.doIsSpecific = this.doIsSpecific.bind(this);
        this.onChangeTextField = this.onChangeTextField.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onChangeShowRoom = this.onChangeShowRoom.bind(this);
        this.doGetResetToken = this.doGetResetToken.bind(this);
        this.checkAdditions = this.checkAdditions.bind(this);
        this.showLogsModal = this.showLogsModal.bind(this);
        this.showUserLogs = this.showUserLogs.bind(this);
    }

    componentDidMount() {
        this.lodUsers();
    }

    showLogsModal() {
        this.userLogModal.show = true;
        this.setState({});
    }

    showUserLogs() {
        this.showLogsModal();
    }


    lodUsers() {
        apiRequest('/bs/user/get-reps-dropdown?all=1')
            .then(data => {
                if (!data || !data.custom) {
                    return
                }
                this.setState({
                    userList: data.custom,
                });
            })
    }

    doGetResetToken() {
        if (!this.currentUser.id) {
            return;
        }
        apiRequest('/bs/user/manager-reset-password', {
            id: this.currentUser.id
        })
            .then(data => {
                this.setState({link: document.location.origin + '?reset-code=' + data.custom.key});
            })

    }

    chooseRep(event) {
        apiRequest(
            '/bs/user/get-single-reps',
            {
                id: event.target.value
            },
            'GET'
        )
            .then(data => {
                this.currentUser
                    .clean()
                    .assign(data.custom);

                this.checkAdditions();
                this.getSchedule(this.currentUser.id);
            });
    }

    checkAdditions() {
        this.setState({
            isSimpleView: ![ROLES.reps, ROLES.fitter].includes(parseInt(this.currentUser.role)),
            showTerritories: [ROLES.showroom, ROLES.reps].includes(parseInt(this.currentUser.role))
        });
    }

    onTimeChange(event) {
        let element = event.target.name.split('.');

        this.workingTime[element[1]][element[0]] = event.target.value;
    }

    onRoleChange(event) {
        this.currentUser.role = event.target.value;
        this.checkAdditions();
    }

    getSchedule(rep_id) {
        apiRequest(
            "/bs/user/get-reps-schedule",
            {rep_id: rep_id}
        )
            .then(data => {
                if (!data) {
                    return;
                }

                let schedule = data.custom;
                let selectedValues = {};

                daysOfWeek.forEach((day, key) => {
                    let scheduleDay = schedule[parseInt(key)];

                    if (scheduleDay !== undefined) {
                        selectedValues[key] = {
                            from: scheduleDay['time_from'],
                            to: scheduleDay['time_to'],
                            enabled: scheduleDay['status'] === 1,
                        };
                    } else {
                        selectedValues[key] = getDefaultTimes()[key];
                    }

                });

                this.workingTime = selectedValues;

                this.setState({});

            });
    }

    resetForm() {
        this.currentUser.clean();
        this.workingTime = getDefaultTimes();

        this.setState({});
    }

    validate() {
        let valid = this.currentUser.isValid();

        if (!valid) {
            console.log(['Form has errors', this.currentUser.validationMessages]);
        }

        return valid;
    }

    doIsSpecific(event) {
        this.currentUser.isSpecificArea = event.target.checked;
        this.setState({});
    }

    doSave() {
        let valid = this.validate();

        if (!valid) {
            window.scrollTo(0, 0);
            this.setState({});

            return;
        }
        this.setState({saveBtnDisable: true});

        let days = {};

        for (const [index, day] of Object.entries(this.workingTime)) {
            if (day.enabled && (!day.from || !day.to)) {
                notify({text: 'Check working hours'});

                window.scrollTo(0, this.refWorkingHours.current.offsetTop);

                return;
            }

            days[index] = day.enabled ? 1 : 0;
        }

        const isNew = !this.currentUser.id;

        let payload = {
            id: this.currentUser.id,
            firstname: this.currentUser.firstName,
            lastname: this.currentUser.lastName,
            phone: this.currentUser.phone,
            email: this.currentUser.email,
            role: this.currentUser.role,
            rating: this.currentUser.rating,
            baseAddress: this.currentUser.address,
            measuring: this.currentUser.measuring,
            measuring_priority: this.currentUser.priority,
            status: this.currentUser.status,
            showrooms: this.currentUser.showrooms,
            specific_area_status: this.currentUser.isSpecificArea ? 1 : 0,
            specific_areas: this.currentUser.specificAreas ? this.currentUser.specificAreas.join() : '',
            day_statuses: days,
            times: this.workingTime,
            is_holding: this.currentUser.isHolding === true ? 1 : 0,
            company: this.currentUser.company,
            report_exclude: this.currentUser.reports === true ? 1 : 0,
            receive_consultant_runs: this.currentUser.receiveConsultantRuns === true ? 1 : 0,
            receive_fitter_runs: this.currentUser.receiveFitterRuns === true ? 1 : 0,
        };

        apiRequest(
            this.currentUser.id ? '/bs/user/edit-reps' : '/bs/user/add-reps',
            payload
        )
            .then(data => {
                this.resetForm();
                this.lodUsers();

                // update self  session
                let auth = new Auth();
                auth.load();

                if (payload.id === auth.userId) {
                    auth.reportExclude = payload.report_exclude === 1;
                    auth.save();
                }

                notify({
                    text: isNew ? 'User has been created' : 'User has been updated'
                });

                this.setState({saveBtnDisable: false});
                console.log(this.currentUser)
            }).catch(response => {
            notify({text: response.message});
            this.setState({saveBtnDisable: false});

        });
    }

    onDaySwitch(e) {
        this.workingTime[e.target.value].enabled = e.target.checked;
        this.setState({});
    }

    onChangeTextField(e) {
        this.currentUser.setProperty(e.target.name, e.target.value);
    }

    onSelectChange(e) {
        this.currentUser.setProperty(e.target.name, e.target.value);
        this.setState({});
    }

    doUpdateSelectRating(event) {
        this.currentUser.rating = event.target.value;
    }

    doUpdateMeasureType(e) {
        if (e.target.checked) {
            this.currentUser.addMeasuring(e.target.value);
        } else {
            this.currentUser.removeMeasuring(e.target.value);
        }

        this.setState({});
    }

    doUpdatePriority(e) {
        if (e.target.checked) {
            this.currentUser.addPriority(e.target.value);
        } else {
            this.currentUser.removePriority(e.target.value);
        }

        this.setState({});
    }

    onChangeShowRoom(e) {
        if (this.currentUser.role === ROLES.reps.toString()) {
            this.currentUser.showrooms = [];
        }

        if (e.target.checked) {
            this.currentUser.addShowroom(e.target.value);
        } else {
            this.currentUser.removeShowroom(e.target.value);
        }

        this.setState({});
    }

    onAddressChange(event) {
        this.currentUser.address = event.target.value;
    }

    render() {

        return (
            <>
                <h1>Users</h1>

                <div className="panel">
                    <div className="panel__top">
                        <div
                            className="panel__title">{this.currentUser.id ? 'Edit: ' + this.currentUser.firstName + ' ' + this.currentUser.lastName : 'New'}</div>
                        <div className="panel__action"><span className="btn btn--grey btn--s"
                                                             onClick={this.resetForm}>Add</span></div>
                    </div>
                    <div className="panel__body">

                        <div className="form__box form__box--users">
                            <div className="ih">
                                <Select
                                    label="Select User"
                                    options={this.state.userList}
                                    defaultValue={this.currentUser.id}
                                    onChange={this.chooseRep}
                                />
                            </div>

                            {this.currentUser.id ?
                                <>
                                    <p>To reset password, click the "Reset Password" button and send the generated
                                        password link to the user manually via email. Link will expire in 24 hours.</p>
                                    < div className="ih">
                                        < button className="btn btn--grey" onClick={this.doGetResetToken}>Reset
                                            Password
                                        </button>
                                        < button className="btn" onClick={this.showUserLogs}>
                                            Logs
                                        </button>
                                    </div>
                                    <div className="ih">
                                        <TextField
                                            label="Reset Password Link"
                                            value={this.state.link}
                                            readOnly={true}
                                        />
                                    </div>
                                    <Modal
                                        status={this.userLogModal}
                                        className="modal__add__booking abc__modal--top userLogs"
                                    >
                                        <div className="panel">
                                            <UserLogs userId={this.currentUser.id}/>
                                        </div>
                                    </Modal>
                                </>
                                : ''
                            }
                            <div className="ih">
                                <TextField
                                    label="Firstname"
                                    value={this.currentUser.firstName}
                                    name={'firstName'}
                                    onChange={this.onChangeTextField}
                                    errorMessage={this.currentUser.getFieldError('firstName')}
                                />
                            </div>

                            <div className="ih"><TextField
                                label="Lastname"
                                value={this.currentUser.lastName}
                                name={'lastName'}
                                onChange={this.onChangeTextField}
                                errorMessage={this.currentUser.getFieldError('lastName')}
                            /></div>


                            <div className="ih"><Select
                                label="Status"
                                name={'status'}
                                options={[
                                    {
                                        value: 1,
                                        label: 'Active'
                                    },
                                    {
                                        value: 2,
                                        label: 'Inactive'
                                    },
                                ]}
                                defaultValue={this.currentUser.status}
                                onChange={(e) => {
                                    this.currentUser.status = e.target.value;
                                }}
                                errorMessage={this.currentUser.getFieldError('status')}
                            /></div>

                            {[ROLES.reps, ROLES.fitter, ROLES.wholesale].includes(parseInt(this.currentUser.role)) ?
                                <div className="ih"><TextField
                                    key={this.currentUser.role}
                                    label="Company"
                                    value={this.currentUser.company}
                                    name={'company'}
                                    onChange={this.onChangeTextField}
                                    errorMessage={this.currentUser.getFieldError('company')}
                                /></div>
                                : ''
                            }

                            <div className="ih"><TextField
                                label="Phone"
                                value={this.currentUser.phone}
                                name={'phone'}
                                onChange={this.onChangeTextField}
                                errorMessage={this.currentUser.getFieldError('phone')}
                            /></div>

                            <div className="ih"><TextField
                                label="Email"
                                value={this.currentUser.email}
                                name={'email'}
                                onChange={this.onChangeTextField}
                                errorMessage={this.currentUser.getFieldError('email')}
                            /></div>


                            <div className="ih">
                                <Select
                                    label="User type"
                                    name={'role'}
                                    options={USER_ROLES}
                                    defaultValue={this.currentUser.role}
                                    onChange={this.onRoleChange}
                                    errorMessage={this.currentUser.getFieldError('role')}
                                />
                            </div>

                            <div className="ih">
                                <CheckBox
                                    key={Math.random()}
                                    onChange={(e) => this.currentUser.isHolding = e.target.checked}
                                    checked={this.currentUser.isHolding}
                                >Holding User</CheckBox>
                            </div>

                            <div className="ih">
                                <CheckBox
                                    key={Math.random()}
                                    onChange={(e) => this.currentUser.reports = e.target.checked}
                                    checked={this.currentUser.reports}
                                >Do not track as M&Q Leads as default option</CheckBox>
                            </div>

                            <div className="ih">
                                <CheckBox
                                    key={Math.random()}
                                    checked={this.currentUser.receiveFitterRuns}
                                    onChange={e => this.currentUser.receiveFitterRuns = e.target.checked}
                                >
                                    Receive Fitter Runs Email
                                </CheckBox>

                                <CheckBox
                                    key={Math.random()}
                                    checked={this.currentUser.receiveConsultantRuns}
                                    onChange={e => this.currentUser.receiveConsultantRuns = e.target.checked}
                                >
                                    Receive Consultant Runs Email
                                </CheckBox>
                            </div>

                            {/*

                            {this.state.isSimpleView ? '' :
                                <div className="ih">
                                    <Select
                                        label="Consultants Rating 3 = Highest | 1 = Lowest"
                                        options={RATES}
                                        name={'rating'}
                                        defaultValue={this.currentUser.rating}
                                        onChange={this.onSelectChange}
                                    />
                                    <p>1 is the bookable reps | 0 = WILL NOT get any booking.</p>
                                </div>
                            }

                            */}

                            {this.state.showTerritories ?
                                <div className="ih">
                                    <BoxGroup
                                        label="Showrooms"
                                    >
                                        {Object.entries(SHOWROOMS).map(([key, showRoomName]) => {
                                            return (
                                                <React.Fragment
                                                    key={key + '.' + (this.currentUser.showroomHas(key.toString()) ? 'Y' : 'N')}>
                                                    <CheckBox
                                                        onChange={this.onChangeShowRoom}
                                                        value={key}
                                                        key={key}
                                                        checked={this.currentUser.showroomHas(key.toString())}
                                                    >{showRoomName}</CheckBox>
                                                </React.Fragment>
                                            )
                                        })}
                                    </BoxGroup>
                                </div>
                                : ''
                            }

                            {this.state.isSimpleView ? '' :
                                <div className="ih">
                                    <div style={{display: 'flex'}}>
                                        <BoxGroup label="Type">
                                            <CheckBox
                                                name="measure_type"
                                                key={'m1' + (this.currentUser.measuringHas(MEASUREMENTS.IndoorBlinds) ? 'YES' : 'NO')}
                                                checked={this.currentUser.measuringHas(MEASUREMENTS.IndoorBlinds)}
                                                value={'1'}
                                                onChange={this.doUpdateMeasureType}
                                            >Indoor Blinds</CheckBox>
                                            <CheckBox
                                                name="measure_type"
                                                key={'m2' + (this.currentUser.measuringHas(MEASUREMENTS.IndoorShutters) ? 'YES' : 'NO')}
                                                checked={this.currentUser.measuringHas(MEASUREMENTS.IndoorShutters)}
                                                value={'2'}
                                                onChange={this.doUpdateMeasureType}
                                            >Indoor Shutters</CheckBox>
                                            <CheckBox
                                                name="measure_type"
                                                key={'m3' + (this.currentUser.measuringHas(MEASUREMENTS.Curtains) ? 'YES' : 'NO')}
                                                checked={this.currentUser.measuringHas(MEASUREMENTS.Curtains)}
                                                value={'3'}
                                                onChange={this.doUpdateMeasureType}
                                            >Curtains</CheckBox>
                                            <CheckBox
                                                name="measure_type"
                                                key={'m4' + (this.currentUser.measuringHas(MEASUREMENTS.OutdoorBlinds) ? 'YES' : 'NO')}
                                                checked={this.currentUser.measuringHas(MEASUREMENTS.OutdoorBlinds)}
                                                value={'4'}
                                                onChange={this.doUpdateMeasureType}
                                            >Outdoor (Blinds & Shutters)</CheckBox>
                                            <CheckBox
                                                name="measure_type"
                                                key={'m5' + (this.currentUser.measuringHas(MEASUREMENTS.Secure) ? 'YES' : 'NO')}
                                                checked={this.currentUser.measuringHas(MEASUREMENTS.Secure)}
                                                value={'5'}
                                                onChange={this.doUpdateMeasureType}
                                            >Security doors & window screens</CheckBox>
                                            <CheckBox
                                                name="measure_type"
                                                key={'m6' + (this.currentUser.measuringHas(MEASUREMENTS.Commercial) ? 'YES' : 'NO')}
                                                checked={this.currentUser.measuringHas(MEASUREMENTS.Commercial)}
                                                value={'6'}
                                                onChange={this.doUpdateMeasureType}
                                            >Commercial</CheckBox>
                                        </BoxGroup>

                                        <BoxGroup label="Priority">
                                            <CheckBox
                                                name="is_priority"
                                                key={'m1' + (this.currentUser.priorityHas(MEASUREMENTS.IndoorBlinds) ? 'YES' : 'NO')}
                                                checked={this.currentUser.priorityHas(MEASUREMENTS.IndoorBlinds)}
                                                value={'1'}
                                                onChange={this.doUpdatePriority}
                                            >Priority</CheckBox>
                                            <CheckBox
                                                name="is_priority"
                                                key={'m2' + (this.currentUser.priorityHas(MEASUREMENTS.IndoorShutters) ? 'YES' : 'NO')}
                                                checked={this.currentUser.priorityHas(MEASUREMENTS.IndoorShutters)}
                                                value={'2'}
                                                onChange={this.doUpdatePriority}
                                            >Priority</CheckBox>
                                            <CheckBox
                                                name="is_priority"
                                                key={'m3' + (this.currentUser.priorityHas(MEASUREMENTS.Curtains) ? 'YES' : 'NO')}
                                                checked={this.currentUser.priorityHas(MEASUREMENTS.Curtains)}
                                                value={'3'}
                                                onChange={this.doUpdatePriority}
                                            >Priority</CheckBox>
                                            <CheckBox
                                                name="is_priority"
                                                key={'m4' + (this.currentUser.priorityHas(MEASUREMENTS.OutdoorBlinds) ? 'YES' : 'NO')}
                                                checked={this.currentUser.priorityHas(MEASUREMENTS.OutdoorBlinds)}
                                                value={'4'}
                                                onChange={this.doUpdatePriority}
                                            >Priority</CheckBox>
                                            <CheckBox
                                                name="is_priority"
                                                key={'m5' + (this.currentUser.priorityHas(MEASUREMENTS.Secure) ? 'YES' : 'NO')}
                                                checked={this.currentUser.priorityHas(MEASUREMENTS.Secure)}
                                                value={'5'}
                                                onChange={this.doUpdatePriority}
                                            >Priority</CheckBox>
                                            <CheckBox
                                                name="is_priority"
                                                key={'m6' + (this.currentUser.priorityHas(MEASUREMENTS.Commercial) ? 'YES' : 'NO')}
                                                checked={this.currentUser.priorityHas(MEASUREMENTS.Commercial)}
                                                value={'6'}
                                                onChange={this.doUpdatePriority}
                                            >Priority</CheckBox>
                                        </BoxGroup>
                                    </div>

                                </div>

                            }

                            {this.state.isSimpleView ? '' :
                                <div className="ih"><TextField
                                    label="Base address"
                                    value={this.currentUser.address}
                                    name={'address'}
                                    ref={this.refAddress}
                                    onChange={this.onAddressChange}
                                />
                                </div>
                            }

                            {this.state.isSimpleView ? '' :
                                <div className="ih">
                                    <BoxGroup label="Specific Postcodes" inline={false}>
                                        <CheckBox
                                            defaultChecked={this.currentUser.isSpecificArea}
                                            name={'isSpecificArea'}
                                            onClick={this.doIsSpecific}
                                        >Consultant will only work on specific postcodes.</CheckBox>
                                    </BoxGroup>
                                </div>
                            }

                            {this.state.isSimpleView || !this.currentUser.isSpecificArea ? '' :
                                <div className="ih">
                                    <TextField
                                        label="Post codes"
                                        value={this.currentUser.specificAreas.join(',')}
                                        name={'postcodes'}
                                        ref={this.refPostCodes}
                                        onChange={(e) => {
                                            this.currentUser.setProperty('specific_areas', e.target.value)
                                        }}
                                    />
                                    {/*
                                <CreatableSelect
                                    components={{
                                        DropdownIndicator: null,
                                    }}
                                    // inputValue={this.state.inputValue}
                                    isClearable
                                    isMulti
                                    menuIsOpen={false}
                                    // onChange={this.handleChangePostCode}
                                    // onInputChange={this.handleInputChange}
                                    // onKeyDown={this.handleKeyDown}
                                    placeholder="Add new Postcode"
                                    // value={this.state.value}
                                    className={"form__select select__underline select__underline--tags"}
                                    classNamePrefix="select"
                                    // options={}
                                />
*/}
                                    {/*<div className="helper error" ref={this.refPostCodeError}></div>*/}
                                </div>
                            }

                            {this.state.isSimpleView ? '' :
                                <>
                                    <h3 ref={this.refWorkingHours}>Available Hours</h3>
                                    {daysOfWeek.map((day, dayIndex) => {
                                        const curWorkDay = this.workingTime && this.workingTime[dayIndex] ? this.workingTime[dayIndex] : null;

                                        return (
                                            <div className="working__hours" key={dayIndex}>
                                                <div className="working__hours__days">
                                                    {day}
                                                </div>
                                                <div className="working__hours__switch">
                                                    <Switch
                                                        checked={curWorkDay ? curWorkDay.enabled : false}
                                                        onChange={this.onDaySwitch}
                                                        value={dayIndex}
                                                    />
                                                </div>
                                                <div className="working__hours__from">
                                                    <Select
                                                        label="From"
                                                        name={'from.' + dayIndex}
                                                        options={DROPDOWN_TIMES}
                                                        defaultValue={curWorkDay ? curWorkDay.from : getDefaultTimes()[dayIndex].from}
                                                        disabled={curWorkDay && !curWorkDay.enabled}
                                                        onChange={this.onTimeChange}
                                                    />
                                                </div>
                                                <div className="working__hours__to">
                                                    <Select
                                                        label="To"
                                                        name={'to.' + dayIndex}
                                                        options={DROPDOWN_TIMES}
                                                        defaultValue={curWorkDay ? curWorkDay.to : getDefaultTimes()[dayIndex].to}
                                                        disabled={curWorkDay && !curWorkDay.enabled}
                                                        onChange={this.onTimeChange}
                                                    />
                                                </div>
                                                <div className="working__hours__to">
                                                    <Select
                                                        label="Max Booking"
                                                        name={'max_booking.' + dayIndex}
                                                        options={BOOKING_COUNT}
                                                        defaultValue={curWorkDay.max_booking ? curWorkDay.max_booking : '0'}
                                                        disabled={curWorkDay && !curWorkDay.enabled}
                                                        onChange={this.onTimeChange}
                                                    />
                                                </div>
                                            </div>

                                        )
                                    })}
                                </>
                            }

                        </div>

                    </div>


                    <div className="panel__bottom">
                        <div className="bh">
                            <button className="btn"
                                    disabled={this.state.saveBtnDisable}
                                    onClick={this.doSave}>{this.currentUser.id ? 'Update' : 'Add'}</button>
                        </div>
                    </div>

                </div>

            </>
        );
    }
}