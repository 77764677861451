import {BaseModel} from "./BaseModel";
import {NotEmptyValidator} from "../validators/NotEmptyValidator";

export class LoginModel extends BaseModel {
    email: string = null;
    password: string = null;

    constructor() {
        super();

        this.addValidator('email', new NotEmptyValidator())
            .addValidator('password', new NotEmptyValidator())
        ;
    }
}