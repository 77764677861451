import React from 'react';
import PropTypes from 'prop-types';
import BaseField from "./BaseField";
import {apiRequest} from "../Globals";
import {Booking} from "./model/Booking";
import Pagination from "../Pagination";

export default class SearchCustomerBooking extends BaseField {
    static propTypes = {
        name: PropTypes.string,
        label: PropTypes.string,
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        underline: PropTypes.bool,
        disabled: PropTypes.bool,
        variant: PropTypes.oneOf(['underline', 'background']),
        value: PropTypes.string,
        onChange: PropTypes.func,
        errorMessage: PropTypes.string,
        size: PropTypes.string,
        isPassword: PropTypes.bool
    };

    pagination: Pagination = new Pagination();
    searchParams = {
        search: null,
        dateFrom: null,
        dateTo: null
    };
    searchInput: string = '';

    savedPropValue = '';
    value: string | number = null;
    label: string = null;
    savedDefaultValue = null;
    name: string = null;
    currentItem: number = -1;
    options: Booking[] = [];

    scrollingDebounce = null;
    scrollBarrier: number = 370;

    constructor(props) {
        super(props);

        this.name = props.name;

        this.refMainContainer = React.createRef();
        this.refItems = React.createRef();
        this.wrapperRef = React.createRef();

        this.onFocus = this.onFocus.bind(this);
        this.onFocusLost = this.onFocusLost.bind(this);
        this.onChange = this.onChange.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.clearSearchTimeOut = this.clearSearchTimeOut.bind(this);
    }

    onFocus() {
        this.refMainContainer.current.classList.add('abc__textfield--focused');
    }

    onFocusLost() {
        this.refMainContainer.current.classList.remove('abc__textfield--focused');
    }

    onChange(event) {
        this.setCurrentValue(event.target.value);

        if (event.target.value !== '') {
            this.refMainContainer.current.classList.add('abc__textfield--filled');
        } else {
            this.refMainContainer.current.classList.remove('abc__textfield--filled');
        }

        if (this.props.onChange) {
            const event = {
                target: this,
            };

            this.props.onChange(event);
        }
    }


    doSearch(event) {
        let searchList = document.querySelector('.abc__textfield__suggestion__box');
        searchList.scrollTop = 0;

        this.clearSearchTimeOut();

        this.searchInput = event.target.value;

        this.pagination.reset();
        this.scrollBarrier = 370;

        this.searchChangedTimeout = setTimeout(
            () => {
                this.options = [];
                this.doLoad();
            },
            800
        );

        return true;
    }


    doLoad() {
        if (!this.pagination.hasMore) {
            return;
        }

        this.searchParams.search = this.searchInput;

        let payload = {
            searchParams: this.searchParams,
            pagination: this.pagination,
        };

        apiRequest("/bs/fitting/bookings", payload)
            .then(result => {

                if (result) {
                    result.custom.forEach((row) => {
                        let booking = new Booking();
                        booking.assign(row);

                        this.options.push(booking);
                    });

                    this.showDropDown(true);
                    this.setState({});
                }

                if (this.onDataChange) {
                    this.onDataChange();
                }
            });
    }

    clearSearchTimeOut() {
        if (this.searchChangedTimeout) {
            clearTimeout(this.searchChangedTimeout);
        }
    }

    setCurrentValue(value: string): void {
        this.value = value;
    }

    showDropDown(show) {
        if (show) {
            this.refItems.current.style.display = "block";
            this.wrapperRef.current.classList.add('abc__select--activated');

            document.addEventListener('keydown', this.onKeyDown);
        } else {
            this.refItems.current.style.display = "none";
            this.wrapperRef.current.classList.remove('abc__select--activated');

            document.removeEventListener('keydown', this.onKeyDown);
        }
    }

    doSelect(booking: Booking) {
        this.value = booking.id;
        this.label = booking.firstName + ' ' + booking.lastName;

        // this.setCurrentValue(value);
        this.setState({});

        if (this.props.onChange) {
            const event = {
                target: this,
            };

            this.props.onChange(event, booking);
        }

        this.showDropDown(false);
    }

    componentDidMount() {
        this.pagination.setPageSize(20);
        let searchList = document.querySelector('.abc__textfield__suggestion__box');

        searchList.addEventListener("scroll", () => {
            this.scrollOnload(this.handleScroll, 100);
        });

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.showDropDown(false);
        }
    }

    loadSearchResults() {
        this.pagination.page++;
        this.doLoad();
    };

    handleScroll() {
        let searchList = document.querySelector('.abc__textfield__suggestion__box');

        if (searchList.scrollTop >= this.scrollBarrier) {
            this.scrollBarrier += 800;
            this.loadSearchResults();
        }
    }

    scrollOnload(callback, time) {
        if (this.scrollingDebounce) {
            clearTimeout(this.scrollingDebounce);
        }

        this.scrollingDebounce = setTimeout(
            () => {
                callback();
            },
            time
        );
    };

    render() {
        let {id, value, onChange, errorMessage, variant, label, isPassword, size, ...rest} = this.props;

        if (this.savedPropValue !== value) {
            this.savedPropValue = value;
            this.setCurrentValue(value);
        }

        if (!id) {
            id = 'tf' + Math.random();
        }

        let customClassName = '';

        switch (variant) {
            case 'underline':
                customClassName += ' abc__textfield--underline';
                break;

            case 'background':
                customClassName += ' abc__textfield--background';
                break;

            default:
                customClassName = '';
        }

        if (this.props.disabled) {
            customClassName += ' abc__textfield--disabled';
        }

        if (this.value) {
            customClassName += ' abc__textfield--filled';
        }

        if (!this.props.label) {
            customClassName += ' abc__textfield--no-label';
        }

        if (this.props.size === 'small') {
            customClassName += ' abc__textfield--small';
        }

        return (
            <>
                <div className={'abc__textfield abc__textfield--suggestion' + customClassName}
                     ref={this.refMainContainer} key={value}>
                    <div
                        className="abc__textfield__box"

                        ref={this.wrapperRef}// contentEditable={true}
                    >
                        <input
                            name={this.props.name}
                            type={this.props.isPassword ? 'password' : 'text'}
                            className="abc__textfield__input"
                            id={id}
                            onFocus={this.onFocus}
                            onBlur={this.onFocusLost}
                            defaultValue={this.value}
                            // onChange={this.onChange}
                            onChange={this.doSearch}
                            {...rest}
                        />
                        {this.props.label ?
                            <label htmlFor={id} className="abc__textfield__label">{this.props.label}</label> : ''}
                        {this.props.prefix ?
                            <i className={'abc__textfield__prefix abc__textfield__prefix--' + this.props.prefix}></i> : ''}
                        {this.props.suffix ?
                            <i className={'abc__textfield__suffix abc__textfield__prefix--' + this.props.suffix}></i> : ''}
                        {this.props.underline || this.props.underline === undefined ?
                            <div className="abc__textfield__underline"></div> : ''}
                    </div>

                    <div
                        className="abc__textfield__suggestion__box"
                        ref={this.refItems}
                        style={{display: "none"}}
                    >


                        <div className="suggestion__results">
                            <div className="suggestion__results__heading">
                                <div className="item">Customers Name</div>
                                <div className="item">Consultant</div>
                                <div className="item">M&Q Date</div>
                                <div className="item">Address</div>
                                <div className="item">Company Name</div>
                            </div>
                            <div ref={this.refUl}>
                                {this.options ?
                                    this.options.map(item => {
                                        return (
                                            <div
                                                className="suggestion__results__content"
                                                onClick={() => {
                                                    this.doSelect(item)
                                                }}
                                                key={item.id}
                                            >
                                                <div className="item">{item.firstName + ' ' + item.lastName}</div>
                                                <div className="item">{item.repsName}</div>
                                                <div className="item">{item.date}</div>
                                                <div className="item">{item.address}</div>
                                                <div className="item">{item.company}</div>
                                            </div>
                                        )
                                    }) : ''
                                }
                            </div>
                        </div>


                    </div>

                </div>
                {
                    errorMessage && errorMessage.length > 0 ?
                        <div className="abc__helper abc__helper--error-show">
                            <div className="abc__helper__text abc__helper__text--error">{errorMessage}</div>
                        </div> : ''
                }
            </>
        );
    }
};