import {Auth} from "./Auth";
import ConfigService from "../service/ConfigService";

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
};

/**
 * Main API request
 *
 * @param url
 * @param method
 * @param data
 * @returns {Promise<unknown>}
 */
export function apiRequest(url: string, data: null, method: string) {
    let readPromise = new Promise((resolve, reject) => {
        method = method ? method.toUpperCase() : 'POST';
        let auth = new Auth();
        auth.load();

        let init = {
            method: method,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
                'X-Api-Key': 'Bearer ' + auth.token
            }
        };

        if (data) {
            if (method === 'POST') {
                const replacer = (key, value) =>
                    typeof value === 'undefined' ? null : value;

                    init.body = JSON.stringify(data, replacer);
            } else {
                let addUrl = '';
                for (const [key, val] of Object.entries(data)) {
                    addUrl += key + '=' + val + '&';
                }

                if (addUrl.length > 0) {
                    if (url.indexOf('?') !== -1) {
                        url += '&';
                    } else {
                        url += '?';
                    }

                    url += addUrl.substr(0, addUrl.length -1);
                }
            }
        }

        let configService = new ConfigService();
        let apiUrl = configService.data.apiUrl;

        fetch(apiUrl + url, init)
            .then(response => {
                // response only can be ok in range of 2XX
                if (response.ok) {
                    // you can call response.json() here too if you want to return json
                    let data = response.json();
                    resolve(data);
                } else {
                    //handle errors in the way you want to
                    switch (response.status) {
                        case 401:
                            // browserHistory.push("/not-found");

                            let auth = new Auth();
                            auth.save();
                            document.location.reload();

                            return;
                        case 404:
                            // browserHistory.push("/not-found");
                            console.log('Object not found');
                            return;
                        case 500:
                            console.log('Internal server error');
                            break;
                        default:
                            console.log('Some error occured');
                            break;
                    }

                    //here you also can thorow custom error too
                    reject(response);
                }
            })
            .catch(error => {
                //it will be invoked mostly for network errors
                //do what ever you want to do with error here
                console.log(['Here: ', error]);
                reject(error);
            });
    });

    return new Promise((resolve, reject) => {
        readPromise.then(data => {
            if (data.success) {
                resolve(data.data);

                return;
            }

            reject({
                message: data.message,
                fields: data.data ? data.data.custom : []
            });
        })
    });
}

/**
 * Parse category with filter params into object
 *
 * @param url
 * @returns {{}}
 */
export function parseCategoryUrl(url) {
    let result = {};

    const colorRegEx = /-color-([\w;]+)/;
    const fabricRegEx = /-fabric-([\w;]+)/;

    let color = colorRegEx.exec(url);

    if (color) {
        result.color = color[1].split(';');
    }

    let fabric = fabricRegEx.exec(url);

    if (fabric) {
        result.fabric = fabric[1].split(';');
    }

    url = url.replace(colorRegEx, '');
    url = url.replace(fabricRegEx, '');

    result.url = url;

    return result;
}

export function categoryFilterToUrl(url, params) {
    let colors = '';

    for (let key in params.colors) {
        if (params[key]) {
            colors += ";" + key;
        }
    }

    if (colors) {
        url += "-color-" + colors.substr(1);
    }

    return url;
}

export function fileExists(path) {
    try {
        require(`${path}`);
        console.log(['exists', path]);

        return true;
    } catch (err) {
        console.log(['not', path], err);
        return false;
    }
}

/**
 *
 * @param name
 * @returns {string}
 */
export function nameToAlias(name: string) {
    return name.toLowerCase()
        .replace(' ', '-');
}
