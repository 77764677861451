import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Modal extends Component {
    static propTypes = {
        variant: PropTypes.string,
        closeButton: PropTypes.bool,
        position: PropTypes.string,
        status: PropTypes.shape({
            show: PropTypes.bool,
        }),
        addedCloseFunc: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.refMain = React.createRef();
        this.doClose = this.doClose.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown(e) {
        if (e.key === 'Escape') {
            this.doClose();
        }
    }

    doClose() {
        if (this.props.addedCloseFunc) {
            this.props.addedCloseFunc();
        }

        if (this.props.status) {
            this.props.status.show = false;
        }

        this.setState({});
    }

    render() {
        document.body.classList.remove('no__scroll--modal');
        document.body.classList.remove('no__scroll--full');

        if (!this.props.status || !this.props.status.show) {
            return '';
        }

        let {variant, closeButton, className, position, status, ...rest} = this.props;

        document.body.classList.add(variant === 'full' ? 'no__scroll--full' : 'no__scroll--modal');

        if (!className) {
            className = '';
        }

        if (variant === 'full' && !className.includes('abc__modal--full')) {
            className += ' abc__modal--full';
        }

        if (position && !className.includes('abc__modal--' + position)) {
            className += ' abc__modal--' + position;
        }

        return (
            <div
                ref={this.refMain}
                className={'abc__modal abc__modal--show ' + className}
                {...rest}
            >
                <div className="abc__modal__box">
                    <div className="abc__modal__main__content">
                        {closeButton === undefined || closeButton ?
                            <span className="abc__modal__close" onClick={this.doClose}><svg className="icon__close"
                                                                                            viewBox="0 0 24.06 24.06"><line
                                x1="0.53" y1="0.53" x2="23.53" y2="23.53" fill="none" stroke="#000"
                                strokeMiterlimit="10" strokeWidth="1.5"/><line x1="23.53" y1="0.53" x2="0.53" y2="23.53"
                                                                               fill="none" stroke="#000"
                                                                               strokeMiterlimit="10" strokeWidth="1.5"/></svg></span> : ''}
                        {/*<span className="abc__modal__close" onClick={this.doClose}>X</span>*/}
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}