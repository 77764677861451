import React from 'react';
import PropTypes from 'prop-types';
import TextField from "./TextField";
import ConfigService from "../../service/ConfigService";

export default class Location extends TextField {
    static propTypes = {
        onPlaceChanged: PropTypes.func,
    };

    autoComplete;
    currentLocation: {
        streetNumber: number,
        postalCode: number,
        locality: string,
        street: string,
        text: string
    } = {
        streetNumber: null,
        postalCode: null,
        locality: null,
        street: null,
        text: null
    };

    constructor(props) {
        super(props);

        this.name = props.name;

        this.refMainContainer = React.createRef();
        this.refInput = React.createRef();

        this.onGoogleScriptLoaded = this.onGoogleScriptLoaded.bind(this);
        this.onPlaceChanged = this.onPlaceChanged.bind(this);
        this.onKeyUp = this.onChange.bind(this);
    }

    componentDidMount(): void {
        // this.loadGoogleApi();
        this.onGoogleScriptLoaded()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        this.onGoogleScriptLoaded();
    }

    // loadGoogleApi() {
    //     if (document.getElementById('abc-google-maps-api')) {
    //         this.onGoogleScriptLoaded();
    //
    //         return;
    //     }
    //
    //     let configService = new ConfigService();
    //     let googleKey = configService.data.googleKey;
    //
    //
    //     const script = document.createElement("script");
    //     script.src = 'https://maps.googleapis.com/maps/api/js?key=' + googleKey + '&libraries=places&language=en';
    //     script.async = true;
    //     script.id = 'abc-google-maps-api';
    //     script.onload = () => this.onGoogleScriptLoaded();
    //     document.body.appendChild(script);
    // }

    onGoogleScriptLoaded() {
        // if (!document.getElementById('abc-google-maps-api')) {
        //     return;
        // }

        const options = {
            componentRestrictions: {
                country: "au",
            },
            // fields: ["formatted_address", "geometry", "name"],
            fields: ["address_components", "formatted_address"],
        };

        this.autoComplete = new window.google.maps.places.Autocomplete(
            this.refInput.current,
            options
        );

        // Western Australia
        //left bottom: -35.682469106577095, 113.22943096432923 ; right top: -13.352467612172893, 128.9746130060621
        const southwest = { lat: -35.682469106577095, lng: 113.22943096432923 };
        const northeast = { lat: -13.352467612172893, lng: 128.9746130060621 };
        const newBounds = new window.google.maps.LatLngBounds(southwest, northeast);
        this.autoComplete.setBounds(newBounds);

        window.google.maps.event.addListener(this.autoComplete, "place_changed", this.onPlaceChanged);
    }

    onPlaceChanged() {
        const addressObject = this.autoComplete.getPlace();
        // const query = addressObject.formatted_address;
        //
        // [
        //     {
        //         "long_name": "5",
        //         "short_name": "5",
        //         "types": [
        //             "street_number"
        //         ]
        //     },
        //     {
        //         "long_name": "Hector Street West",
        //         "short_name": "Hector St W",
        //         "types": [
        //             "route"
        //         ]
        //     },
        //     {
        //         "long_name": "Osborne Park",
        //         "short_name": "Osborne Park",
        //         "types": [
        //             "locality",
        //             "political"
        //         ]
        //     },
        //     {
        //         "long_name": "City of Stirling",
        //         "short_name": "Stirling",
        //         "types": [
        //             "administrative_area_level_2",
        //             "political"
        //         ]
        //     },
        //     {
        //         "long_name": "Western Australia",
        //         "short_name": "WA",
        //         "types": [
        //             "administrative_area_level_1",
        //             "political"
        //         ]
        //     },
        //     {
        //         "long_name": "Australia",
        //         "short_name": "AU",
        //         "types": [
        //             "country",
        //             "political"
        //         ]
        //     },
        //     {
        //         "long_name": "6017",
        //         "short_name": "6017",
        //         "types": [
        //             "postal_code"
        //         ]
        //     }
        // ]

        this.currentLocation = {
            streetNumber: null,
            postalCode: null,
            locality: null,
            street: null,
            text: addressObject.formatted_address,
        };

        // console.log(['DDDDD', JSON.parse(JSON.stringify(addressObject))]);

        if (addressObject) {
            addressObject.address_components.forEach(item => {
                if (item.types.includes('postal_code')) {
                    this.currentLocation.postalCode = item.long_name;
                } else if (item.types.includes('locality')) {
                    this.currentLocation.locality = item.long_name;
                } else if (item.types.includes('street_number')) {
                    this.currentLocation.streetNumber = item.long_name;
                } else if (item.types.includes('route')) {
                    this.currentLocation.street = item.long_name;
                }
            });
        }

        if (this.props.onPlaceChanged) {
            const event = {
                target: this,
            };

            this.props.onPlaceChanged(event);
        }
    }

    onKeyUp(e) {
        this.currentLocation = {
            streetNumber: null,
            postalCode: null,
            locality: null,
            street: null,
            text: null
        };
    }

    render() {
        let {id, value, onPlaceChanged, errorMessage, label, ...rest} = this.props;

        if (this.savedPropValue !== value) {
            this.savedPropValue = value;
            this.setCurrentValue(value);
        }

        if (!id) {
            id = 'tf' + Math.random();
        }

        let customClassName = '';

        switch (this.props.variant) {
            case 'underline':
                customClassName += ' abc__textfield--underline';
                break;

            case 'background':
                customClassName += ' abc__textfield--background';
                break;

            default:
                customClassName = '';
        }

        if (this.props.disabled) {
            customClassName += ' abc__textfield--disabled';
        }

        if (this.value) {
            customClassName += ' abc__textfield--filled';
        }

        if (this.props.size === 'small') {
            customClassName += ' abc__textfield--small';
        }

        return (
            <>
                <div className={'abc__textfield' + customClassName} ref={this.refMainContainer} key={value}>
                    <div className="abc__textfield__box">
                        <input
                            name={this.props.name}
                            type="text"
                            className="abc__textfield__input"
                            id={id}
                            onFocus={this.onFocus}
                            onBlur={this.onFocusLost}
                            onKeyUp={this.onKeyUp}
                            defaultValue={this.value}
                            ref={this.refInput}
                            {...rest}
                        />
                        {this.props.label ? <label htmlFor={id} className="abc__textfield__label">{this.props.label}</label> : ''}
                        {this.props.prefix ? <i className={'abc__textfield__prefix abc__textfield__prefix--' + this.props.prefix}></i> : ''}
                        {this.props.suffix ? <i className={'abc__textfield__suffix abc__textfield__prefix--' + this.props.suffix}></i> : ''}
                        {this.props.underline || this.props.underline === undefined ? <div className="abc__textfield__underline"></div> : ''}
                    </div>
                </div>
                {
                    errorMessage ?
                        <div className="abc__helper abc__helper--error-show">
                            <div className="abc__helper__text abc__helper__text--error">{errorMessage}</div>
                        </div> : ''
                }
            </>
        );
    }
};
