import React, {Component} from 'react';

export default class RadioBox extends Component {
    errorMessage = 'Some error';

    constructor(props) {
        super(props);

        this.refMainContainer = React.createRef();

        this.onFocus = this.onFocus.bind(this);
        this.onFocusLost = this.onFocusLost.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onFocus() {
        this.refMainContainer.current.classList.add('abc__textfield--focused');
    }

    onFocusLost() {
        this.refMainContainer.current.classList.remove('abc__textfield--focused');
    }

    onKeyUp(event) {
        if (event.target.value !== '') {
            this.refMainContainer.current.classList.add('abc__textfield--filled');
        } else {
            this.refMainContainer.current.classList.remove('abc__textfield--filled');
        }
    }

    onClick() {

    }

    render() {
        let {id, children, ...rest} = this.props;

        if (!id) {
            id = 'tf' + Math.random();
        }

        let customClassName = '';

        if (this.props.disabled) {
            customClassName += ' abc__checkbox__box--disabled';
        }

        return (
            <>
                <div className={'abc__radio__box' + customClassName}>
                    <input
                        type="radio"
                        className="abc__radio__input"
                        id={id}
                        {...rest}
                    />
                    <label htmlFor={id} className="abc__radio__option">
                        <span className="abc__radio__button"></span>
                        <span className="abc__radio__text">{children}</span>
                    </label>
                </div>
            </>
        );
    }
};
