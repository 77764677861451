import React from 'react';
import {Auth} from "../components/Auth";
import {apiRequest} from "../components/Globals";
import TextField from "../components/form/TextField";
import {LoginModel} from "../components/form/model/LoginModel";
import PropTypes from "prop-types";
import {notify} from "../components/Notify";
import {ROLES} from "../config/Globals";

export default class Login extends React.Component {
    static propTypes = {
        onLogin: PropTypes.func,
    };

    state = {
        part: 'login'
    };

    auth: Auth = new Auth();
    login: LoginModel = new LoginModel();
    key: number = null;
    userId: number = null;

    constructor(props) {
        super(props);

        this.refLogin = React.createRef();
        this.refPassword = React.createRef();


        this.doLogin = this.doLogin.bind(this);
        this.doStartReset = this.doStartReset.bind(this);
        this.setNewUserPass = this.setNewUserPass.bind(this);
        this.checkResetCode = this.checkResetCode.bind(this);
    }

    componentDidMount(): void {
        this.checkParams();
    }

    setNewUserPass() {
        apiRequest('/bs/user/new-password', {
            pass: this.refPassword.current.value,
            key: this.key,
            id: this.userId
        })
            .then(result => {
                this.setState({part: 'login'});
            })
            .catch(error => {
                notify({text: error.message});
            });
    }

    doStartReset(key: number) {
        apiRequest('/bs/user/reset-password', {
            email: this.refLogin.current.value
        })
            .then(result => {
                notify({text: "Please, check your email box for farther instructions"});

                this.setState({part: 'new-password'});
            })
            .catch(error => {
                notify({text: error.message});
            });
    }

    checkResetCode(key: number) {
        apiRequest('/bs/user/check-reset-code', {
            key: key
        })
            .then(result => {
                this.userId = result.custom.id;

                this.setState({part: 'confirm-reset'});
            })
            .catch(error => {
                notify({text: error.message});
            });
    }

    doLogin() {
        this.key = null;
        this.userId = null;

        if (!this.login.isValid()) {
            this.setState({});

            return;
        }

        let payload = {
            email: this.login.email,
            password: this.login.password
        };

        apiRequest('/bs/user/login', payload)
            .then(data => {
                this.auth.userId = data.custom.id;
                this.auth.role = data.custom.role;
                this.auth.token = data.custom.token;
                this.auth.name = data.custom.name;
                this.auth.showrooms = data.custom.showrooms;
                this.auth.reportExclude = data.custom.reportExclude === 1;

                if (this.auth.role === ROLES.reps) {
                    this.auth.startUrl = '/todays-run';
                }

                if ([ROLES.showroom, ROLES.manager, ROLES.admin, ROLES.service, ROLES.commercial].includes(this.auth.role)) {
                    this.auth.startUrl = '/calendar';
                }

                if ([ROLES.fitter].includes(this.auth.role)) {
                    this.auth.startUrl = '/fitting-todays-run';
                }

                this.auth.save();

                if (this.props.onLogin) {
                    this.props.onLogin();
                }
            })
            .catch(error => {
                if (error instanceof Error) {
                    console.error(error);

                    notify({
                        text: error.message
                    });

                    return;
                }

                notify({
                    text: error.message ? error.message : 'Unknown error'
                });
            });
    }

    checkParams() {
        let queryParams = document.location.search;
        if (queryParams.substr(0,1) === '?') {
            queryParams = queryParams.substr(1);
        }

        let data = queryParams.split('&');

        data.some(item => {
            let param = item.split('=');

            if (param[0] === 'reset-code') {
                this.key = param[1];
                this.checkResetCode(param[1]);

                return true;
            }

            return false;
        });

    }

    render() {
        return (
            <div className="login">
                <div className="login--box">
                    {this.state.part === 'confirm-reset' ?
                        <>
                            <h1 className="login__title">New Password</h1>
                            <p>Please enter a new password that you will use to login to your account.</p>

                            <div className="ih">
                                <TextField
                                    label={'New Password'}
                                    ref={this.refPassword}
                                />

                            </div>

                            <div className="login__link">
                                <a
                                    href={'/'}
                                    onClick={
                                        (e) => {
                                            e.preventDefault();
                                            this.setState({part: 'login'})
                                        }
                                    }
                                >Back to login</a>
                            </div>


                            <div className="bh">
                                <button className="btn" onClick={this.setNewUserPass}>Submit</button>
                            </div>


                        </>

                        :
                        (this.state.part === 'new-password' ?
                                <>
                                    <h1 className="login__title">Reset Password</h1>
                                    <p>Enter your email below and you will receive an email with an instructions for your new password.</p>

                                    <div className="ih">

                                            <TextField
                                                label={'Email'}
                                                ref={this.refLogin}
                                            />

                                    </div>

                                    <div className="login__link">
                                        <a
                                            href={'/'}
                                            onClick={
                                                (e) => {
                                                    e.preventDefault();
                                                    this.setState({part: 'login'})
                                                }
                                            }
                                        >Back to login</a>
                                    </div>

                                    <div className="bh">
                                        <button className="btn" onClick={this.doStartReset}>Reset Password</button>
                                    </div>
                                </>
                                :
                                <>


                                    <h1 className="login__title">Login </h1>

                                    <div className="ih">
                                        <TextField
                                            name={'email'}
                                            label={'Email / Username'}
                                            value={this.login.email}
                                            onChange={(e) => {
                                                this.login.email = e.target.value
                                            }}
                                            errorMessage={this.login.getFieldError('email')}
                                            autoComplete={'off'}
                                        />
                                    </div>

                                    <div className="ih">
                                        <TextField
                                            name={'password'}
                                            label={'Password'}
                                            value={this.login.password}
                                            onChange={(e) => {
                                                this.login.password = e.target.value
                                            }}
                                            errorMessage={this.login.getFieldError('password')}
                                            isPassword={true}
                                        />
                                    </div>

                                    {this.state.isError &&
                                    <div className="form__row">
                                        <div className="form__ih--error"></div>
                                        <div className="helper error error--show">Incorrect login details</div>
                                    </div>
                                    }

                                    <div className="login__link">
                                        <a
                                            href={'/'}
                                            onClick={
                                                (e) => {
                                                    e.preventDefault();
                                                    this.setState({part: 'new-password'})
                                                }
                                            }
                                        >Forgot your password?</a>
                                    </div>


                                    <div className="bh">
                                        <button className="btn" onClick={this.doLogin}>Login</button>
                                    </div>


                                </>
                        )
                    }


                </div>
            </div>
        );
    }
}