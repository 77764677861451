import DatePicker from "react-datepicker";
import TextField from "../components/form/TextField";
import {currencyFormat} from "../components/currencyFormatter";
import Modal from "../components/Modal";
import React, {Component} from "react";
import {ReportExpensesModel} from "./ReportExpensesModel";

class ReportExpensesModal extends Component {
    state = {
        loading: false,
        startExpensesDate: new Date(),
        endExpensesDate: new Date()
    }

    expensesModalStatus = {show: false};
    expensesForm: ReportExpensesModel = new ReportExpensesModel();


    constructor(props) {
        super(props);

        this.changeExpensesDate = this.changeExpensesDate.bind(this);
        this.addExpense = this.addExpense.bind(this);
        this.removeExpense = this.removeExpense.bind(this);
    }

    toggleExpensesModal(isOpen: boolean) {
        this.expensesModalStatus.show = isOpen;

        if (isOpen) {
            this.setState({loading: true});
            this.expensesForm.getExpensesList(() => this.setState({loading: false}));
        } else {
            this.setState({});
        }
    }

    addExpense() {
        if (!this.expen)
        this.setState({loading: true});

        this.expensesForm.addExpenses(() => this.setState(
            {
                startExpensesDate: new Date(),
                endExpensesDate: new Date(),
                loading: false
            }))
    }

    removeExpense(id) {
        if (!id) {
            return;
        }
        this.setState({loading: true});
        this.expensesForm.removeExpenses(id, () => this.setState({loading: false}));
    }

    changeExpensesDate(date, dd) {
        this.expensesForm.dateFrom = date[0];
        this.expensesForm.dateTo = date[1];

        this.setState({
            startExpensesDate: date[0],
            endExpensesDate: date[1],
        });
    }

    render() {
        return (
            <Modal status={this.expensesModalStatus}>
                <div className="panel">
                    <div className="panel__top">
                        <div className="panel__title">
                            Expenses
                        </div>
                    </div>
                    <div className="panel__body">
                        <div className="form__row">
                            <div className="form__label">Data Range:</div>
                            <div className="form__field">
                                <DatePicker
                                    className="datepicker"
                                    placeholder="Expenses range"
                                    dateFormat="dd MMMM yyyy"
                                    selectsRange={true}
                                    startDate={this.state.startExpensesDate}
                                    endDate={this.state.endExpensesDate}
                                    maxDate={new Date()}
                                    selected={this.state.startExpensesDate}
                                    onChange={this.changeExpensesDate}
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__label">Amount:</div>
                            <div className="form__field">
                                <TextField
                                    size={'small'}
                                    label="Amount"
                                    value={this.expensesForm.amount}
                                    onChange={e => this.expensesForm.amount = e.target.value}
                                    errorMessage={this.expensesForm.getFieldError('amount')}
                                />
                            </div>
                        </div>
                        <button className={'btn ih'}
                                disabled={this.state.loading}
                                onClick={_ => this.addExpense()}
                        >Add
                        </button>

                        {Array.isArray(this.expensesForm.list) && this.expensesForm.list.map((form, key) => {
                            return (
                                <div style={{display: 'flex'}} key={key}>
                                    <div>{form.dateFrom} to {form.dateTo}: {currencyFormat(form.amount)}</div>
                                    <button className={'link'}
                                            disabled={this.state.loading}
                                            onClick={() => this.removeExpense(form.id)}
                                    > Remove
                                    </button>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ReportExpensesModal;
