import React from 'react';

export default class Wholesale extends React.Component {

    render() {

        return (
            <>
                Wholesale
            </>
        );
    }
}