import React, {Component} from 'react';

export default class BoxGroup extends Component {
    constructor(props) {
        super(props);

        this.refMainContainer = React.createRef();
    }

    render() {
        // let id = this.props.id;
        //
        // if (!id) {
        //     id = 'tf' + Math.random();
        // }
        //
        let customClassName = '';

        if (this.props.inline) {
            customClassName += ' abc__checkbox--inline';
        }

        return (
            <>
                <div className={'abc__checkbox' + customClassName} ref={this.refMainContainer}>
                    {this.props.label ? <div className="abc__checkbox__label">{this.props.label}</div> : ''}
                    {this.props.children}
                </div>

                {
                    this.errorMessage ?
                        <div className="abc__helper abc__helper--error-show">
                            <div className="abc__helper__text abc__helper__text--error">{this.errorMessage}</div>
                        </div> : ''
                }

            </>
        );
    }
};
