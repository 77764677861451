import React from 'react';
import Popup from "../components/Popup";
import TextField from "../components/form/TextField";
import Select from "../components/form/Select";
import Modal from "../components/Modal";
import Moment from "react-moment";
import {Booking} from "../components/form/model/Booking";
import BookingService from "../service/BookingService";
import moment from "moment";
import {Auth} from "../components/Auth";
import Access from "../components/Access";
import {apiRequest} from "../components/Globals";
import Tabs from "../components/form/Tabs";
import Logs from "./calendar/Logs";
import {BOOKING_STATUS} from "../config/Globals";
import {notify} from "../components/Notify";

export default class MeasureAndQuote extends React.Component {
    state = {
        loaded: false,
        showLoadMore: true,
        logs: []
    };

    bookingService: BookingService = new BookingService(() => {
        this.setState({})
    });
    filterValue: null;

    deletingBookingId = null;

    modalStatusRep = {
        show: false
    };

    modalCancelConfirm = {
        show: false
    };

    modalStatusPdf = {
        show: false
    };

    selectedBooking: Booking = {};
    auth: Auth = new Auth();

    constructor(props) {
        super(props);

        this.refPopup = React.createRef();

        this.doShowRepModal = this.doShowRepModal.bind(this);
        this.doShowPdfModal = this.doShowPdfModal.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.loadComments = this.loadComments.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.preConfirmDeleteBooking = this.preConfirmDeleteBooking.bind(this);


        // default search all statuses
        this.bookingService.searchParams.status = [];

        this.auth.load();
    }

    doShowRepModal(event) {
        this.modalStatusRep.show = true;
        this.selectedBooking = this.bookingService.getOne(event.target.dataset.id);
        this.loadComments(event.target.dataset.id);

        this.setState({});
    }

    doShowPdfModal() {
        this.modalStatusPdf.show = true;
        this.setState({});
    }


    componentDidMount(): void {
        let search = {};

        if (!Access.isAllowed('MeasureAndQuote', 'fullList')) {
            search.repId = this.auth.userId;
        }

        this.bookingService.setSearchParams(search);

        // this.bookingService.loadList();
    }

    onChangeSearch(event) {
        let search = {
            search: event.target.value
        };

        if (!Access.isAllowed('MeasureAndQuote', 'fullList')) {
            search.repId = this.auth.userId;
        }

        this.bookingService.setSearchParams(search);
    }

    loadMore() {
        this.bookingService.loadMore();
    }

    onChangeFilter(event) {
        let params = {
            status: []
        };

        switch (event.target.value) {
            case 'all':
                params.dateFrom = null;
                params.dateTo = null;

                break;

            case 'today':
                params.dateFrom = moment(new Date(), 'YYYY-MM-DD');
                params.dateTo = params.dateFrom;
                params.status = [1];

                break;

            case 'future':
                params.dateFrom = moment(new Date(), 'YYYY-MM-DD hh:mm:ss');
                params.dateTo = null;
                params.status = [1];
                break;

            case 'canceled':
                params.status = [2];
                break;
        }

        if (!Access.isAllowed('MeasureAndQuote', 'fullList')) {
            params.repId = this.auth.userId;
        }

        this.bookingService.setSearchParams(params);
    }

    loadComments(id: number) {
        if (!id) {
            this.setState({logs: []});
            return;
        }

        apiRequest('/bs/booking/comment-list', {
            'bookingId': id
        })
            .then(response => {
                this.setState({logs: response && response.custom ? response.custom : []})
            });

    }

    onLoading(show: boolean) {
        if (this.props.onLoading) {
            this.props.onLoading(show);
        }
    }

    preConfirmDeleteBooking(bookingId) {
        this.modalCancelConfirm.show = true;
        this.deletingBookingId = bookingId;
        this.setState({});
    }

    deletingBooking(show: boolean): void {
        if (!show) {
            this.modalCancelConfirm.show = false;
        } else {
            this.lastErrors = {};
        }

        this.setState({});
    }

    onDelete() {
        this.deletingBooking(false);
        this.onLoading(true);
        apiRequest('/bs/booking/change-status', {
            id: this.deletingBookingId,
            status: BOOKING_STATUS.canceled
        })
            .then(() => {
                notify({
                    text: 'Booking has been cancelled.'
                });
                this.bookingService.resetPagination();
                this.bookingService.loadList();
                this.onLoading(false);
            })
            .catch(response => {
                this.onLoading(false);
                notify({
                    text: response.message
                })
            });
    }

    render() {
        return (
            <>
                <h1>Measure &amp; Quote</h1>

                <div className="panel">
                    <div className="panel__top">
                        <div className="panel__title">Appointment List</div>
                        <div className="action">
                            <div className="panel__filter">
                                {/* <div className="booking__filter__runsheet"><span className="btn" onClick={this.doShowPdfModal}>PDF Runsheet</span></div> */}
                                <div className="item filter__search"><TextField label="Search Name / Location / Phone / Date / Consultant" size="small"
                                                                 onChange={this.onChangeSearch}/></div>
                                <div className="item">
                                    <Select
                                        label="Show"
                                        size="small"
                                        options={[
                                            {
                                                value: 'all',
                                                label: 'All'
                                            },
                                            {
                                                value: 'today',
                                                label: 'Today'
                                            },
                                            {
                                                value: 'future',
                                                label: 'Future'
                                            },
                                            {
                                                value: 'canceled',
                                                label: 'Cancelled'
                                            },

                                        ]}
                                        defaultValue={this.filterValue}
                                        onChange={this.onChangeFilter}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="panel__body panel__body--no-padding">

                        <ul className="booking__list">
                            <li className="booking__list__heading">
                                <div className="booking__list__name">Customer Name</div>
                                <div className="booking__list__address">Location</div>
                                <div className="booking__list__address">Phone</div>
                                <div className="booking__list__date">Date</div>
                                <div className="booking__list__time">Time</div>
                                <div className="booking__list__reps">Assigned to</div>
                                <div className="booking__list__action"></div>
                            </li>
                            {this.bookingService.list.map((row, i) => {
                                return (
                                    <li key={i} className={row.status === '2' ? 'cancelled' : ''}>
                                        <div className="booking__list__name"><span className="booking__list__job__info"
                                                                                   onClick={this.doShowRepModal}
                                                                                   data-id={row.id}>{row.firstName + ' ' + row.lastName}</span>
                                        </div>
                                        <div
                                            className="booking__list__address">{(row.unitNo ? row.unitNo + ', ' : '') + row.address + ', ' + row.suburb}</div>
                                        <div className="booking__list__phone">{row.phone}</div>
                                        <div className="booking__list__date"><Moment
                                            format={'DD MMMM YYYY'}>{row.date}</Moment></div>
                                        <div className="booking__list__time"><Moment
                                            format={'LT'}>{row.date + ' ' + row.timeFrom}</Moment> to <Moment
                                            format={'LT'}>{row.date + ' ' + row.timeTo}</Moment></div>
                                        <div className="booking__list__reps">{row.repsName}</div>

                                        <div className="booking__list__action">
                                            {Access.isAllowed('MeasureAndQuote', 'dottedLink') ?
                                                <Popup
                                                    ref={this.refPopup}
                                                    container={'header'}
                                                    trigger={
                                                        <div className="menu__dots__trigger">
                                                            <div className="menu__dots__trigger__dots"></div>
                                                            <div className="menu__dots__trigger__dots"></div>
                                                            <div className="menu__dots__trigger__dots"></div>
                                                        </div>
                                                    }
                                                    action={'click'}
                                                    className={'menu__dots menu__dots--booking-list abc__toggle'}
                                                >
                                                    <div className="menu__dots__dropdown">
                                                        <div className="menu__dots__list">
                                                            <div className="menu__dots__list__item"
                                                                 onClick={this.doShowRepModal} data-id={row.id}>View
                                                            </div>
                                                            <div onClick={() => this.preConfirmDeleteBooking(row.id)}
                                                                 className="menu__dots__list__item">Cancel
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Popup>
                                                : ''
                                            }
                                        </div>

                                    </li>
                                )
                            })}
                            <Modal status={this.modalCancelConfirm}>
                                <div className="panel">
                                    <div className="panel__top">
                                        <div className="panel__title">Cancel Booking</div>
                                    </div>
                                    <div className="panel__body">
                                        Are you sure you want to cancel this booking?

                                    </div>
                                    <div className="panel__bottom">
                                        <div className="bh">
                                            <button className="btn" onClick={this.onDelete}>Yes</button>
                                            <button
                                                className="btn"
                                                onClick={() => {
                                                    this.modalCancelConfirm.show = false;
                                                    this.setState({})
                                                }}
                                            >No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </ul>

                    </div>

                    {
                        this.bookingService.pagination.hasMore ?
                            <div className="panel__bottom">
                                <div className="pagination">
                                    <input className="btn" type={'button'} value={'Load more'} onClick={this.loadMore}/>
                                </div>
                            </div>
                            : ''
                    }

                </div>

                <Modal
                    status={this.modalStatusRep}
                    className="modal__booking__information"
                >


                    <div className="abc__modal__content">
                        <Tabs
                            items={
                                [
                                    'Booking Information',
                                    'Logs',
                                ]
                            }
                        >


                            <div className="panel">
                                <div className="panel__top">
                                    <div className="panel__title">Booking Information</div>
                                </div>
                                <div className="panel__body">

                                    <div className="modal__booking__information__column">

                                        <div className="item">
                                            <div className="form__row">
                                                <div className="form__label">Customer's Name</div>
                                                <div
                                                    className="form__field">{this.selectedBooking.firstName} {this.selectedBooking.lastName}</div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__label">Email</div>
                                                <div className="form__field">{this.selectedBooking.email}</div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__label">Company</div>
                                                <div className="form__field">{this.selectedBooking.company}</div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__label">Phone</div>
                                                <div className="form__field">{this.selectedBooking.phone}</div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__label">Call Customer Before Appointment</div>
                                                <div
                                                    className="form__field">{this.selectedBooking.callBefore ? 'Yes' : 'No'}</div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__label">Unit No.</div>
                                                <div className="form__field">{this.selectedBooking.unitNo}</div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__label">Address</div>
                                                <div
                                                    className="form__field">{this.selectedBooking.address}, {this.selectedBooking.suburb}, {this.selectedBooking.postalCode}</div>
                                            </div>

                                            <div className="form__row">
                                                <div className="form__label">Date</div>
                                                <div
                                                    className="form__field">{moment(this.selectedBooking.date).format('DD MMMM YYYY dddd')}</div>
                                            </div>

                                            <div className="form__row">
                                                <div className="form__label">Qty: Blinds</div>
                                                <div
                                                    className="form__field">{this.selectedBooking.blinds ? this.selectedBooking.blinds : 0}</div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__label">Qty: Indoor Shutters</div>
                                                <div
                                                    className="form__field">{this.selectedBooking.shutters ? this.selectedBooking.shutters : 0}</div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__label">Qty: Curtains</div>
                                                <div
                                                    className="form__field">{this.selectedBooking.curtains ? this.selectedBooking.curtains : 0}</div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__label">Qty: Outdoor</div>
                                                <div
                                                    className="form__field">{this.selectedBooking.outerBlinds ? this.selectedBooking.outerBlinds : 0}</div>
                                            </div>

                                            <div className="form__row">
                                                <div className="form__label">Qty: Security doors & window screens</div>
                                                <div
                                                    className="form__field">{this.selectedBooking.secure ? this.selectedBooking.secure : 0}</div>
                                            </div>

                                            <div className="form__row">
                                                <div className="form__label">Time</div>
                                                <div className="form__field">
                                                    {moment(this.selectedBooking.date + ' ' + this.selectedBooking.timeFrom).format('h:mm a').toUpperCase()} - {moment(this.selectedBooking.date + ' ' + this.selectedBooking.timeTo).format('h:mm a').toUpperCase()}
                                                </div>
                                            </div>

                                            <div className="form__row">
                                                <div className="form__label">Notes</div>
                                                <div className="form__fields">{this.selectedBooking.message}</div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div>
                                <div className="panel">
                                    <div className="panel__top">
                                        <div className="panel__title">Logs</div>
                                    </div>
                                    <div className="panel__body">
                                        <Logs calendarEvent={{booking: this.selectedBooking}}/>
                                    </div>

                                </div>
                            </div>
                        </Tabs>

                    </div>


                    <div className="abc__modal__content">
                        {this.state.logs.length > 0 ?
                            <div className="panel">
                                <div className="panel__top">
                                    <div className="panel__title">Logs</div>
                                </div>
                                <div className="panel__body">

                                    {this.state.logs.map(item => {
                                        let shortcat = '';

                                        if (item.full_name) {
                                            const shortcatParts = item.full_name.split(' ');
                                            shortcat += shortcatParts[0].substr(0, 1).toUpperCase();

                                            if (shortcatParts[1]) {
                                                shortcat += shortcatParts[1].substr(0, 1).toUpperCase();
                                            }
                                        }

                                        return (
                                            <div className="logs" key={Math.random()}>
                                                <div className="logs__column">
                                                    <div className="logs__avatar">{shortcat}</div>
                                                </div>
                                                <div className="logs__column">
                                                    <div className="logs__info">
                                                        <span className="logs__info__name">{item.full_name}</span>
                                                        <span
                                                            className="logs__info__time">{moment(item.event_date).format('DD MMM YYYY h:mma')}</span>
                                                    </div>
                                                    <div className="logs__details">{item.caption} | {item.message}</div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                            : ''}

                    </div>

                </Modal>
                <Modal
                    status={this.modalStatusPdf}
                >

                    <div className="abc__modal__content">
                        <div className="panel">
                            <div className="panel__top">
                                <div className="panel__title">Booking Information</div>
                            </div>
                            <div className="panel__body">

                                <div className="calendar__booking__box">

                                    <div className="item">
                                        <div className="form__row">
                                            <div className="form__label">Quote Date:</div>
                                            <div className="form__field">Datepicker</div>
                                        </div>
                                        <div className="form__row">
                                            <div className="form__label">Customer's Name</div>
                                            <div className="form__field">Autocomplete List Customers Name</div>
                                        </div>
                                        <div className="form__row">
                                            <div className="form__label">Email</div>
                                            <div className="form__field">email@email.com</div>
                                        </div>
                                        <div className="form__row">
                                            <div className="form__label">Phone</div>
                                            <div className="form__field">{this.selectedBooking.phone}</div>
                                        </div>
                                        <div className="form__row">
                                            <div className="form__label">Unit No.</div>
                                            <div className="form__field">{this.selectedBooking.unitNo}</div>
                                        </div>
                                        <div className="form__row">
                                            <div className="form__label">Address</div>
                                            <div
                                                className="form__field">{this.selectedBooking.address}, {this.selectedBooking.suburb}, {this.selectedBooking.postalCode}</div>
                                        </div>

                                        <div className="form__row">
                                            <div className="form__label">M&Q By</div>
                                            <div className="form__field">Consultant Name</div>
                                        </div>

                                        <div className="form__row">
                                            <div className="form__label">Customer Number:</div>
                                            <div className="form__field">TEXTBOX</div>
                                        </div>

                                        <div className="form__row">
                                            <div className="form__label">Balance Due:</div>
                                            <div className="form__field">TEXTBOX</div>
                                        </div>

                                        <div className="form__row">
                                            <div className="form__label"></div>
                                            <div className="form__field">[ ] All windows to be cleared</div>
                                        </div>

                                        <div className="form__row">
                                            <div className="form__label">Notes</div>
                                            <div className="form__field">Textarea</div>
                                        </div>

                                        <div className="form__row">
                                            <div className="form__label">Type:</div>
                                            <div className="form__field">Fitting</div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </Modal>
            </>
        );
    }
}