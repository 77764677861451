import {BaseModel} from "./BaseModel";
import {NotEmptyValidator} from "../validators/NotEmptyValidator";
import {RegExpValidator} from "../validators/RegExpValidator";
import {CallbackValidator} from "../validators/CallbackValidator";
import {EmailValidator} from "../validators/EmailValidator";
import {DEFAULT_PRODUCTS} from "../../../config/Globals";

export class Booking extends BaseModel {
    address: string = null;
    email: string = null;
    firstName: string = null;
    lastName: string = null;
    id: number = null;
    lat: number = null;
    lng: number = null;
    measuring: [] = [];
    phone: string = null;
    rating: number = null;
    reset: number = null;
    role: number = null;
    showroom: number = null;
    isSpecificArea: boolean = null;
    specificAreas: [] = [];
    status: number = null;
    customerNotified: boolean = false;
    callBefore: boolean = false;
    howToKnow: number = null;
    company: string = null;
    isLead: boolean = null;
    smsReminder: boolean = true;

    dateCreated: string = null;
    date: string = null;
    message: string = null;

    blinds: number = null;
    outdoorBlinds: number = null;
    indoorShutters: number = null;
    curtains: number = null;
    secure: number = null;

    products: [{
        label: string,
        value: string
    }] = [];
    productList: [] = null;


    postalCode: string = null;
    repsName: string = null;
    suburb: string = null;
    timeFrom: string = null;
    timeTo: string = null;

    userId: number = null;
    isDone: boolean = false;

    creatorId: number = null;
    creatorName: string = null;
    unitNo: string = null;

    constructor() {
        super();

        const $this = this;

        this.addValidator('firstName', new NotEmptyValidator('Firstname is required'))
            .addValidator('lastName', new NotEmptyValidator('Lastname is required'))
            // .addValidator('phone',  new RegExpValidator(/^\d{6,15}$/, '@fieldName is required'))
            .addValidator('phone', new RegExpValidator(/^(\d{8}|\d{10})$/, 'Incorrect phone number'))
            .addValidator('email', new EmailValidator())
            .addValidator('postalCode', new NotEmptyValidator('Postcode is required'))
            .addValidator('timeFrom', new NotEmptyValidator('Start Time is required'))
            .addValidator('timeTo', new NotEmptyValidator('End Time is required'))
            .addValidator('address', new NotEmptyValidator())
            .addValidator('suburb', new NotEmptyValidator())
            .addValidator('howToKnow', new NotEmptyValidator('Referral is required'))
            .addValidator('blinds', new CallbackValidator(() => {
                return $this.validateCount()
            }, 'Must be at least 1 or more value on any item'))
            .addValidator('outdoorBlinds', new CallbackValidator(() => {
                return $this.validateCount()
            }, 'Must be at least 1 or more value on any item'))
            .addValidator('indoorShutters', new CallbackValidator(() => {
                return $this.validateCount()
            }, 'Must be at least 1 or more value on any item'))
            .addValidator('curtains', new CallbackValidator(() => {
                return $this.validateCount()
            }, 'Must be at least 1 or more value on any item'))
            .addValidator('secure', new CallbackValidator(() => {
                return $this.validateCount()
            }, 'Must be at least 1 or more value on any item'))
            .addValidator('message', new NotEmptyValidator())
            .addValidator('company', new NotEmptyValidator('Company is required'))

        ;
    }

    measuringHas(value: string): boolean {
        if (!this.measuring) {
            return false
        }

        return this.measuring.includes(value);
    }

    addMeasuring(value: string): this {
        if (!this.measuringHas(value)) {
            this.measuring.push(value);
        }

        return this;
    }

    removeMeasuring(value: string): this {
        let ind = this.measuring.indexOf(value);

        if (ind !== -1) {
            this.measuring.splice(ind, 1);
        }

        return this;
    }

    chooseProducts(e, option) {
        switch (option.action) {
            case "select-option":
                this.products.push({
                    label: option.option.label,
                    value: option.option.value,
                });
                break;

            case "remove-value":
                this.removeProduct(option.removedValue.value);
                break;

            case "clear":
                this.products = [];
                break;

            default:
        }
    }

    removeProduct(name: string) {
        let removeIndex = null;

        this.products.some((product, i) => {
            if (product.value === name) {
                removeIndex = i.toString();
                return true;
            }

            return false;
        })

        if (removeIndex) {
            this.products.splice(removeIndex, 1);
        }
    }

    getProductsNames() {
        let list = [];
        this.products.forEach(prod => list.push(prod.value));
        return list;
    }

    setProperty(name: string, value: *): this {
        switch (name) {
            case 'full_name':
                if (value) {
                    const userName = value.split(' ');
                    this.firstName = userName.shift();
                    this.lastName = userName.join(' ');
                }
                break;

            case 'measuring':
                if (typeof value === 'string') {
                    this.measuring = value.split(',');
                } else if (Array.isArray(value)) {
                    this.measuring = value;
                }

                // this.measuring.forEach((o, i, array) => array[i] = parseInt(array[i]));

                break;

            case 'specific_areas':
                if (typeof value === 'string') {
                    this.specificAreas = value.split(',');
                } else if (Array.isArray(value)) {
                    this.specificAreas = value;
                }

                break;

            case 'specific_area_status':
                this.isSpecificArea = !!value;

                break;

            case 'role':
                this.role = value.toString();

                break;
            case 'customer_notified':
                this.customerNotified = value === '1';
                break;

            case 'call_before':
                this.callBefore = value === '1';
                break;

            case 'is_done':
                this.isDone = value === '1';
                break;

            case 'know_from_id':
                this.howToKnow = parseInt(value);
                break;

            case 'security_door_screen':
                this.secure = value;
                break;

            case 'showroom':
                this.showroom = value;
                break;

            case 'is_lead':
                this.isLead = parseInt(value) === 1;

                break;

            case 'send_sms':
                this.smsReminder = parseInt(value) === 1;

                break;

            case 'product_list':
                if (value && typeof value === 'string') {
                    let products = [];
                    const parsedValue = JSON.parse(value);

                    if(!Array.isArray(parsedValue)) {
                        return;
                    }

                    parsedValue.forEach(product => {
                        products.push({
                            label: product,
                            value: product
                        })
                    })

                    let selectedListWithSpace = [];
                    let productList = [];

                    DEFAULT_PRODUCTS.forEach(defProd => {
                        let findProd = products.find(prod => prod.value === defProd.value);

                        if (findProd) {
                            return selectedListWithSpace.push(defProd);
                        }
                        productList.push(defProd);
                    })

                    this.productList = productList;
                    this.products = selectedListWithSpace;
                }

                break;

            default:
                super.setProperty(name, value);
        }

        return this;
    }


    clean(): this {
        super.clean();

        this.measuring = [];
        this.specificAreas = [];

        return this;
    }

    validateCount() {
        let total = 0;

        if (this.blinds) {
            total += Number(this.blinds);
        }

        if (this.outdoorBlinds) {
            total += Number(this.outdoorBlinds);
        }

        if (this.indoorShutters) {
            total += Number(this.indoorShutters);
        }

        if (this.curtains) {
            total += Number(this.curtains);
        }

        if (this.secure) {
            total += Number(this.secure);
        }

        return total > 0;
    }

    getMeasuringsString() {
        let result = [];


        if (this.blinds > 0) {
            result.push(this.blinds + ' Blinds');
        }

        if (this.outdoorBlinds > 0) {
            result.push(this.outdoorBlinds + ' Outdoor (Blinds & Shutters)');
        }

        if (this.indoorShutters > 0) {
            result.push(this.indoorShutters + ' Indoor Shutters');
        }

        if (this.curtains > 0) {
            result.push(this.curtains + ' Curtains');
        }

        if (this.secure > 0) {
            result.push(this.secure + ' Security doors & window screens');
        }


        return result.join(', ');
    }

    getName() {
        return this.company ? this.company : this.firstName + ' ' + this.lastName;
    }
}
