import {BaseValidator} from "./BaseValidator";

export class OnlyNumberValidator extends BaseValidator {
    constructor(message: string = null) {
        if (!message) {
            message = '@fieldName is not a number or value is less than 0';
        }
        super(message);
    }

    isValid(value: *): boolean {
        return /^[\d.,]+$/.test(value);
    }
}