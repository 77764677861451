import React, {Component} from 'react';
import TextField from "../components/form/TextField";
import TextArea from "../components/form/TextArea";
import Select from "../components/form/Select";
import CheckBox from "../components/form/CheckBox";
import BoxGroup from "../components/form/BoxGroup";
// import BoxOption from "../components/form/BoxOption";
import RadioBox from "../components/form/RadioBox";
import Popup from "../components/Popup";
import {Link} from "react-router-dom";
import Modal from "../components/Modal";
import {Controller} from "../components/Globals";
import Switch from "../components/form/Switch";
// import {BrowserRouter, Route, Switch,} from "react-router-dom"

export default class Forms extends Component {
    state = {
        toggle1: false,
        toggle2: false,
        toggle3: false,
        showModal: false,
        showCartModal: false
    };

    modalOne = {
        show: false
    };

    modalTwo = {
        show: false
    };

    constructor(props) {
        super(props);

        this.refToggle2 = React.createRef();
        this.refTarget2 = React.createRef();
        this.refToggle3 = React.createRef();
        this.refTarget3 = React.createRef();

        this.refTrigger1 = React.createRef();
        this.refTarget1 = React.createRef();

       this.showToggle1 = this.showToggle1.bind(this);
       this.hideToggle1 = this.hideToggle1.bind(this);
       this.doToggle = this.doToggle.bind(this);
       this.handleClickOutside = this.handleClickOutside.bind(this);
       this.onClick = this.onClick.bind(this);

       this.onCartClick = this.onCartClick.bind(this);
    }

    showToggle1(event) {
        console.log(['show']);
        let params = {};
        params[event.target.dataset.target] = true;
        event.target.classList.add('abc__toggle__trigger--active');
        this.setState(params);
        console.log(['t', this.state.toggle1])
    }

    hideToggle1(event) {

         let params = {};
        params[event.target.dataset.target] = false;
        event.target.classList.remove('abc__toggle__trigger--active');
        // this.refTarget1.current.style.display = "none";
        this.setState(params);
        // this.refToggle1.current.classList.remove('abc__toggle__content--show');
        console.log(['t', this.state.toggle1])
    }

    doToggle(event) {
        let params = {};
        params[event.target.dataset.target] = !this.state[event.target.dataset.target];

        if (params[event.target.dataset.target]) {
            event.target.classList.add('abc__toggle__trigger--active');
        } else {
            event.target.classList.remove('abc__toggle__trigger--active');
        }
        this.setState(params);
        // this.refToggle1.current.classList.remove('abc__toggle__content--show');
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (!this.refTarget2.current) {
            return;
        }

        if (this.refToggle2 && !this.refToggle2.current.contains(event.target) && (!this.refTarget2.current.contains(event.target))) {
            this.setState({toggle2: false});
        }
    }

    onClick() {
        this.modalTwo.show = true;
        this.setState({});
    }

    onCartClick() {
        this.modalOne.show = true;
        this.setState({});
    }

    render() {
        return (
            <React.Fragment>

                <div className="abc__notification">
                    <div className="abc__notification__content">
                        <span className="abc__notification__close">X</span>
                        <div className="abc__notification__box">
                            <div className="abc__notifcaion__text">Foundation Blockout Fossil sample was added your cart.</div>
                            <div className="abc__notification__link"><a href="#">View Cart</a></div>
                        </div>
                    </div>
                    <div className="abc__notification__content">
                        <span className="abc__notification__close">X</span>
                        <div className="abc__notification__box">
                            <div className="abc__notifcaion__text">Message 2</div>
                            <div className="abc__notification__link"></div>
                        </div>
                    </div>
                    <div className="abc__notification__content">
                        <span className="abc__notification__close">X</span>
                        <div className="abc__notification__box">
                            <div className="abc__notifcaion__text">Message 3</div>
                            <div className="abc__notification__link"></div>
                        </div>
                    </div>
                </div>

                <button className="btn btn--add-to-cart">
                    <span className="btn__text">Add to Cart</span>  
                    <span className="btn__animation">
                        <span className="icon">
                            <svg className="icon__cart"><rect width="28" height="24" fill="none"/><circle cx="12.6" cy="21.65" r="2.35" fill="#ffffff"/><circle cx="24.69" cy="21.65" r="2.35" fill="#ffffff"/><path d="M27.18,5.14a.93.93,0,0,0-.74-.36H11.68a.94.94,0,1,0,0,1.87H25.23L23,15.2H10.4L7.26.74A1,1,0,0,0,6.34,0H1.12a.94.94,0,1,0,0,1.87H5.59L8.73,16.33a1,1,0,0,0,.92.74H23.72a1,1,0,0,0,.34-.07l.1-.05a.94.94,0,0,0,.18-.12l.09-.09a1.63,1.63,0,0,0,.11-.17l.06-.13,0-.07L27.35,6A.94.94,0,0,0,27.18,5.14Z" fill="#ffffff"/><polyline class="icon__cart__check" points="20.4 8.01 15.4 13.01 13 11" fill="none" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>
                        </span>
                    </span>
                </button>
                
                <p>&nbsp;</p>
                <button className="btn btn--loading loading">
                    <span className="btn__text">Add to Cart</span>
                    <span className="btn__loading">
                        <svg className="icon__loading">
                            <circle cx="13" cy="13" r="13"></circle>
                        </svg>
                    </span>
                </button>


                

                <p style={{paddingTop: '100px'}}>

                    <input type="button" onClick={this.onCartClick} value={"myCart"}/>
                </p>


                <Modal
                    className={'my-customClass'}
                    status={this.modalOne}
                    //closeButton={false}
                >
                    <div className={'abc__modal__content'}>
                        GO GO
                    </div>
                </Modal>


                <p style={{paddingTop: '100px'}}>

                    <input type="button" onClick={this.onClick} value={"modal"}/>
                </p>

                <Modal
                    status={this.modalTwo}
                    // variant={'full'}
                    closeButton={true}
                    // className={'abc__modal--full'}
                    className={'abc__modal--top'}
                    // position={'top'}
                >
                    <div className="abc__modal__content">
                        <p>dadadadada</p>
                        <p>ddasdas dada</p>
                        <p>Need a way to close the modal via link <span>close modal</span></p>
                    </div>
                </Modal>

                <Popup
                    container={'header'}
                    trigger={<a href="/">testo</a>}
                    action={'click'}
                    className={'abc__toggle'}
                >
                    <div style={{background: "magenta"}}>I will be shown</div>
                </Popup>

                <div className="form__test">
                    <div className="form__test__col1">
                        
                        <h1>Toggle</h1>
                        <ul className="testmenu">
                            <li><a href="Link1">No Toggle</a></li>                      
                            <Popup
                                container={'li'}
                                trigger={<a href="/">Click</a>}
                                action={'click'}
                                className={'abc__toggle'}
                            >
                                <div>I will be shown</div>
                            </Popup>
                            <Popup
                                container={'li'}
                                trigger={<a href="/">Hover</a>}
                                action={'hover'}
                                className={'abc__toggle'}
                            >
                                <div>I will be shown</div>
                            </Popup>
                        </ul>
                        <p>sadasd asda da dsda das dasd asd</p>
                            
                        

                        <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>

                        {/* 
                        <h1>Modal</h1>
                        <p><span>Modal Box</span></p>
                        <div class="abc__modal abc__modal--customlass">
                            <div class="abc__modal__box">
                                <span class="abc__modal__close">X</span>
                                <div class="abc__modal__content">
                                    <p>dadadadada</p>
                                    <p>ddasdas dada</p>
                                    <p>Need a way to close the modal via link <span>close modal</span></p>
                                </div>
                            </div>
                        </div>

                        <p><span>Modal Full</span></p>
                        <div class="abc__modal abc__modal--full abc__modal--customlass">
                            <div class="abc__modal__box">
                                <span class="abc__modal__close">X</span>
                                <div class="abc__modal__content">
                                    Content goes here
                                </div>
                            </div>
                        </div>

                        */}


                        <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>

                        <h1>Sliding Tabs</h1>

                        <div className="tabs">
                            <nav className="tabs__nav tabs--blue tabs--small">
                                <span className="item active">Blinds</span>
                                <span className="item">Curtains</span>
                                <span className="item">Outdoor</span>
                            </nav>
                            <div className="tabs__content">
                                
                            </div>
                        </div>

                        <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>

                        <div>
                            <div className="abc__checkbox abc__checkbox--inline">
                                <div className="abc__checkbox__label">Label Goes Here</div>
                                <div className="abc__checkbox__box abc__checkbox__box--disabled">
                                    <input type="checkbox" className="abc__checkbox__input" id="checkbox1" name="checkbox_groupname"/>
                                    <label htmlFor="checkbox1" className="abc__checkbox__option">
                                        <span className="abc__checkbox__button">
                                            <svg className="svg__checkbox__checkmark" viewBox="0 0 20 20">
                                                <path className="svg__checkmark__path" fill="none" d="M19.3,4.1L7,16.4l-6.1-6.1"/>
                                            </svg>
                                        </span>
                                        <span className="abc__checkbox__text">Yes <a href="#">Link Here</a></span>
                                    </label>
                                </div>

                                <div className="abc__checkbox__box">
                                    <input type="checkbox" className="abc__checkbox__input" id="checkbox2" name="checkbox_groupname"/>
                                    <label htmlFor="checkbox2" className="abc__checkbox__option">
                                        <span className="abc__checkbox__button">
                                            <svg className="svg__checkbox__checkmark" viewBox="0 0 20 20">
                                                <path className="svg__checkmark__path" fill="none" d="M19.3,4.1L7,16.4l-6.1-6.1"/>
                                            </svg>
                                        </span>
                                        <span className="abc__checkbox__text">No</span>
                                    </label>
                                </div>

                            </div>
                            <div className="abc__helper">
                                <div className="abc__helper__text abc__helper__text--error">Please select.</div>
                            </div>
                        </div>

                        <div>
                            <div className="abc__checkbox abc__checkbox--inline">
                                <div className="abc__checkbox__label">Label Goes Here</div>
                                <div className="abc__checkbox__box">
                                    <input type="checkbox" className="abc__checkbox__input" id="checkbox3" name="checkbox_groupname2"/>
                                    <label htmlFor="checkbox3" className="abc__checkbox__option">
                                        <span className="abc__checkbox__button">
                                            <svg className="svg__checkbox__checkmark" viewBox="0 0 20 20">
                                                <path className="svg__checkmark__path" fill="none" d="M19.3,4.1L7,16.4l-6.1-6.1"/>
                                            </svg>
                                        </span>
                                        <span className="abc__checkbox__text">Yes <a href="#">Link Here</a> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo invent.</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="abc__radio abc__radio--inline">

                                <div className="abc__radio__label">Radio Label</div>

                                <div className="abc__radio__box">
                                    <input type="radio" className="abc__radio__input" id="radio1" name="option1"/>
                                    <label htmlFor="radio1" className="abc__radio__option">
                                        <span className="abc__radio__button"></span>
                                        <span className="abc__radio__text">Text 1</span>
                                    </label>
                                </div>

                                <div className="abc__radio__box">
                                    <input type="radio" className="abc__radio__input" id="radio2" name="option1"/>
                                    <label htmlFor="radio2" className="abc__radio__option">
                                        <span className="abc__radio__button"></span>
                                        <span className="abc__radio__text">Text 2</span>
                                    </label>
                                </div>

                            </div>
                        </div>

                        <p>
                            <div className="abc__switch">
                                <div className="abc__switch__label">Switch Label</div>
                                <input type="checkbox" className="abc__switch__input" id="checkbox4" name="checkbox4"/>
                                <label htmlFor="checkbox4" className="abc__switch__label">
                                    <div className="abc__switch__box">
                                        <span className="abc__switch__button"></span>
                                        <span className="abc__switch__track"></span>
                                    </div>
                                </label>
                            </div>
                            <Switch />
                        </p>

                    </div>
                    <div className="form__test__col2">
                        <h1>ABC Textbox</h1>

                        <div className="ih">
                            <TextField
                                // label="Width2"
                                suffix="mm"
                                size={'small'}
                                underline={false}
                            />
                        </div>

                        <p>&lt;TextField label="Label"/&gt;</p>
                        <p>variant=&quot;outline&quot; (outline is default | background | underline) <br/> 
                        label=&quot;Placeholder&quot; <br/>
                        prefix=&quot;class&quot; (control icon or text in the css) <br/>
                        suffix=&quot;class&quot; (control icon or text in the css) <br/>
                        underline=&#123;false&#125; (to disable the underline animation on focus)<br/> 
                        disabled=&#123;true&#125; <br/> 
                        </p>

                        <h1>Textarea</h1>
                        <div className="ih">
                            <TextArea label="Message"/>
                        </div>

                        <p>&lt;TextArea label="Message"/&gt;</p>

                        <p>Same options with textbox just without prefix and suffix.</p>

                        <h1>Select</h1>

                        <div className="ih">
                            <Select
                                label="Select"
                                options={[
                                    {
                                        value: 1,
                                        label: 'Option 1'
                                    },
                                    {
                                        value: 2,
                                        label: 'Option 2'
                                    },

                                ]}
                            />
                        </div>

                        <p>
                            &lt;Select<br />
                            label=&quot;Select&quot;<br />
                            options=&#123;&#91;<br />
                            &#123;<br />
                            &#160;&#160; value: 1,<br />
                            &#160;&#160; label: 'Option 1'<br />
                                &#125;,<br />
                                &#123;<br />
                            &#160;&#160; value: 2,<br />
                            &#160;&#160; label: 'Option 2'<br />
                                &#125;,<br />
                            <br />
                            &#93;&#125;<br />
                        /&gt;
                    </p>




                        <p>variant=&quot;outline&quot; (outline is default | background | underline) <br/> 
                        label=&quot;Placeholder&quot; <br/>
                        underline=&#123;false&#125; (to disable the underline animation on focus)<br/> 
                        disabled=&#123;true&#125; <br/> 
                        </p>


                        <h1>Checkbox</h1>
                        <div className="ih">
                            <BoxGroup label="This is the Label" inline={true}>
                                <CheckBox name="test1">Option 1</CheckBox>
                                <CheckBox name="test1">Option 2</CheckBox>
                            </BoxGroup>
                        </div>
                        
                        <h1>Radio</h1>
                        <div className="ih">
                            <BoxGroup label="This is the Label" inline={false}>
                                <RadioBox name="test1" disabled={true}> Yes <a href="#" onClick={this.onClick}>Link Here</a> Sed ut perspiciatis unde illo invent.</RadioBox>
                                <RadioBox name="test1">Option 2</RadioBox>
                                <RadioBox name="test1">Option 3</RadioBox>
                            </BoxGroup>
                        </div>



                    </div>
                </div>


                <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>
                <div>
                    <div className="abc__textfield abc__textfield--background">
                        <div className="abc__textfield__box">
                            <input type="text" className="abc__textfield__input"/>
                            <label htmlFor="" className="abc__textfield__label">Names</label>
                            <div className="abc__textfield__underline"></div>
                        </div>
                    </div>
                    <div className="abc__helper">
                        <div className="abc__helper__text abc__helper__text--error">This is an error.</div>
                    </div>
                </div>

                <div>
                    <div className="abc__textfield abc__textfield--focused">
                        <div className="abc__textfield__box">
                            <input type="text" className="abc__textfield__input"/>
                            <label htmlFor="" className="abc__textfield__label">Lastname</label>
                            <div className="abc__textfield__underline"></div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="abc__textfield abc__textfield--filled">
                        <div className="abc__textfield__box">
                            <input type="text" className="abc__textfield__input" defaultValue="With content"/>
                            <label htmlFor="" className="abc__textfield__label">Name</label>
                            <div className="abc__textfield__underline"></div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="abc__textfield">
                        <div className="abc__textfield__box">
                            <input type="text" className="abc__textfield__input"/>
                            <label htmlFor="" className="abc__textfield__label">Width</label>
                            <i className="abc__textfield__prefix">$</i>
                            <i className="abc__textfield__suffix">mm</i>
                        </div>
                    </div>
                </div>

                <p>&nbsp;</p>
                <hr/>
                <p>&nbsp;</p>

                <h1>ABC Select</h1>
                <div>
                    <div className="abc__select abc__select--underline">
                        <div className="abc__select__box">
                            <div className="abc__select__input">
                                <span className="abc__select__label">State</span>
                                <i className="abc__select__dropdown-icon"></i>
                                <div className="abc__select__underline"></div>
                            </div>
                        </div>
                        {/* 
                        <div className="abc__select__menu">
                            <ul className="abc__select__list">
                                <li className="abc__select__list__item">Western Australia</li>
                                <li className="abc__select__list__item">New South Wales</li>
                                <li className="abc__select__list__item">Victoria</li>
                            </ul>
                        </div>
                        */}
                    </div>
                </div>

                <div>
                    <div className="abc__select abc__select--activated">
                        <div className="abc__select__box">
                            <div className="abc__select__input">
                                <span className="abc__select__label">State</span>
                                <span className="abc__select__selected-text">Western Australia</span>
                                <i className="abc__select__dropdown-icon"></i>
                                <div className="abc__select__underline"></div>
                            </div>
                        </div>
                        <div className="abc__select__menu">
                            <ul className="abc__select__list">
                                <li className="abc__select__list__item">Western Australia</li>
                                <li className="abc__select__list__item">New South Wales</li>
                                <li className="abc__select__list__item">Victoria</li>
                                <li className="abc__select__list__item">1</li>
                                <li className="abc__select__list__item">2</li>
                                <li className="abc__select__list__item">4</li>
                                <li className="abc__select__list__item">3</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>
                <hr/>
                <p>&nbsp;</p>

                <h1>ABC Textarea</h1>
                <div>
                    <div className="abc__textarea abc__textarea--background">
                        <div className="abc__textarea__box">
                            <textarea className="abc__textarea__input"></textarea>
                            <label htmlFor="" className="abc__textarea__label">Name</label>
                            <div className="abc__textarea__underline"></div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="abc__textarea abc__textarea--focused">
                        <div className="abc__textarea__box">
                            <textarea className="abc__textarea__input"></textarea>
                            <label htmlFor="" className="abc__textarea__label">Message</label>
                            <div className="abc__textarea__underline"></div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="abc__textarea abc__textarea--filled">
                        <div className="abc__textarea__box">
                            <textarea className="abc__textarea__input" defaultValue="If theres content"></textarea>
                            <label htmlFor="" className="abc__textarea__label">Message</label>
                            <div className="abc__textarea__underline"></div>
                        </div>
                    </div>
                </div>

                <p>&nbsp;</p>
                <hr/>
                <p>&nbsp;</p>


                <h1>Toggle Hover</h1>
                <div className="toggle__test">
                    <div className="abc__toggle__trigger">Toggle</div>
                    <div className="abc__toggle__content">Content</div>
                </div>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
                <h1>Toggle HoverX</h1>
                <div  onMouseLeave={this.hideToggle1}  data-target="toggle1">
                    <div className="trigger"  ref={this.refTrigger1} onMouseOver={this.showToggle1} data-target="toggle1">Toggle</div>
                    {this.state.toggle1 ? <div className="content" ref={this.refTarget1}>Content Here</div> : ''}
                </div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <h1>Toggle HoverY</h1>
{/*
                <Hover
                    trigger={<>Go & <span>Show</span></>}
                >
                    <div style={{backgroundColor: "red"}}>AAAAAA</div>
                    <div style={{backgroundColor: "blue"}}>BBBBBB</div>
                </Hover>
*/}

                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
{/*

                <h1>Toggle HoverY 2</h1>
                <Hover
                    trigger={<>Stop & <span>Drop</span></>}
                >
                    <div style={{backgroundColor: "green"}}>CCCCCC</div>
                    <div style={{backgroundColor: "yellow"}}>DDDDDD</div>
                </Hover>

*/}
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>

                <h1>Toggle Click</h1>

                <div className="abc__toggle__trigger" onClick={this.doToggle} data-target="toggle2" ref={this.refToggle2}>Toggle 2</div>
                <p>Something separate the trigger and content1</p>
                {this.state.toggle2 ? <div className="abc__toggle__content" ref={this.refTarget2}>Content2</div> : ''}

                <h1>Toggle Click 2</h1>

                <div className="abc__toggle__trigger" onClick={this.doToggle} data-target="toggle3" ref={this.refToggle3}>Toggle 3</div>
                <p>Something separate the trigger and content2</p>
                {this.state.toggle3 ? <div className="abc__toggle__content" ref={this.refTarget3}>Content3</div> : ''}



            </React.Fragment>

        );
    }
}