import React from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {apiRequest} from "../components/Globals";
import moment from "moment";
import {BOOKING_STATUS, MEASUREMENTS_SELECT, ROLES} from "../config/Globals";
import PropTypes from "prop-types";
import Modal from "../components/Modal";
import {Booking} from "../components/form/model/Booking";
import BookingService from "../service/BookingService";
import {User} from "../components/form/model/User";
import {notify} from "../components/Notify";
import ContextMenu from "../components/ContextMenu";
import ContextTarget from "../components/ContextTarget";
import EventItem from "../components/EventItem";
import {Auth} from "../components/Auth";
import CalendarService from "../service/CalendarService";
import Logs from "./calendar/Logs";
import BookingEvent from "./calendar/BookingEvent";
import Tabs from "../components/form/Tabs";
import FittingEvent from "./calendar/FittingEvent";
import {Fitting} from "../components/form/model/Fitting";
import FittingService from "../service/FittingService";
import Access from "../components/Access";
import AvailabilityEvent from "./calendar/AvailabilityEvent";

export default class HoldingCalendar extends React.Component {
    static propTypes = {
        date: PropTypes.any,
        onEventReceive: PropTypes.func
    };

    minTime = '07:00:00';
    maxTime = '19:00:00';

    state = {
        reps: null,
        currentRep: 1,
    };

    curDate: Date = new Date();
    lastDate: Date = null;

    calendarItems: {
        events: EventItem[],
        user: User
    } = {};
    hoverEvent = null;

    modalStatusRep = {
        show: false
    };

    modalDeleteConfirm = {
        show: false
    };

    modalOutsideConfirm = {
        show: false
    };

    loading = 0;

    selectedEvent: {
        user: User,
        booking: Booking,
        fitting: Fitting
    };

    bookingService: BookingService = new BookingService(() => {
        this.updateCalendar()
    });
    fittingService: FittingService = new FittingService(() => {
        this.updateCalendar()
    });

    reps: User[] = [];
    calendarRefs = [];

    // region contextMenu

    copiedEvent = null;
    triggeredEvent = null;
    deleteOnMove = false;

    contextTarget: ContextTarget = null;

    auth = new Auth();
    calendarService: CalendarService = new CalendarService();

    // endregion

    constructor(props) {
        super(props);

        this.holdingRefLoading = React.createRef();
        this.refContextMenu = React.createRef();

        this.showBookingPopup = this.showBookingPopup.bind(this);
        this.onEventClick = this.onEventClick.bind(this);
        this.loadReps = this.loadReps.bind(this);
        this.updateCalendar = this.updateCalendar.bind(this);
        this.addCalendarItem = this.addCalendarItem.bind(this);
        this.onEventChangeTime = this.onEventChangeTime.bind(this);
        this.onMoveEventToOtherRep = this.onMoveEventToOtherRep.bind(this);
        this.loadingShow = this.loadingShow.bind(this);
        this.loadingHide = this.loadingHide.bind(this);
        this.contextMenu = this.contextMenu.bind(this);
        this.onMouseEnterEvent = this.onMouseEnterEvent.bind(this);
        this.onMouseLeaveEvent = this.onMouseLeaveEvent.bind(this);
        this.onCopyEvent = this.onCopyEvent.bind(this);
        this.onPasteEvent = this.onPasteEvent.bind(this);
        this.findUser = this.findUser.bind(this);
        this.onLoading = this.onLoading.bind(this);
        this.onChange = this.onChange.bind(this);
        this.canShowUserCalendar = this.canShowUserCalendar.bind(this);
        this.onDeleteEvent = this.onDeleteEvent.bind(this);
        this.getBookingDefaultTab = this.getBookingDefaultTab.bind(this);

        this.auth.load();
        this.calendarService.load();
    }

    componentDidMount(): void {
        const filterTypes = this.getTypeList();
        let firstType = null;
        let found = false;

        filterTypes.forEach(item => {
            firstType = item.value;
            if (this.props.calendarType === item.value) {
                found = true;
            }
        });

        if (!found) {
            this.props.calendarType = firstType;
        }

        this.loadReps();
        this.loadData();
    }

    loadingShow() {
        this.holdingRefLoading.current.style.display = 'block';
    }

    loadingHide() {
        this.holdingRefLoading.current.style.display = 'none';
    }

    componentDidUpdate(prevProps): void {
        this.loadData();

        if (this.props.movedHoldingEvent) {
            this.deleteUserEvent(this.props.movedHoldingEvent.userId, this.props.movedHoldingEvent.calendarEventId);
        }
    }

    /**
     *  @return User|null
     */

    checkDuplicateEvents(oldEvent) {
        if (!Array.isArray(this.calendarItems)) {
            return;
        }

        this.calendarItems.forEach((calItem, userId) => {
            if (!calItem) {
                return;
            }

            calItem.events.some((eventItem, key) => {
                if (eventItem.id === oldEvent.id) {
                    this.calendarItems[userId].events.splice(key, 1);
                    return true;
                }
                return false;
            })
        })
    }

    findUser(userId) {
        let user = null;

        this.reps.some(item => {
            if (item.id === parseInt(userId)) {
                user = item;

                return true;
            }

            return false;
        });

        return user;
    }

    getUserEvent(userId: number, eventElementId: number) {
        if (!this.calendarItems[userId]) {
            return null;
        }

        let eventIndex = null;

        this.calendarItems[userId].events.some((eventItem, i) => {
            if (eventItem.id === eventElementId) {
                eventIndex = i;

                return true;
            }

            return false;
        });

        return eventIndex !== null ? this.calendarItems[userId].events[eventIndex] : null;
    }

    deleteUserEvent(userId: number, bookingId: number) {
        if (!this.calendarItems[userId]) {
            return;
        }

        let eventIndex = null;

        this.calendarItems[userId].events.some((eventItem, i) => {
            if (eventItem.id === bookingId) {
                eventIndex = i;

                return true;
            }

            return false;
        });

        if (eventIndex !== null) {
            this.calendarItems[userId].events.splice(eventIndex, 1);
        }
    }

    eventChangeUser(oldEvent: EventItem, newEvent: EventItem) {
        this.loadingShow();

        /**
         clean duplicate after item has moved from main calendar
         */
        this.checkDuplicateEvents(oldEvent);

        if (newEvent.extendedProps.booking) {
            this.bookingEventChangeUser(oldEvent, newEvent);
        } else {
            this.fittingEventChangeUser(oldEvent, newEvent);
        }
    }

    bookingEventChangeUser(oldEvent: EventItem, newEvent: EventItem) {
        apiRequest('/bs/booking/change-time', {
            id: newEvent.extendedProps.booking.id,
            repId: newEvent.extendedProps.user.id,
            date: newEvent.extendedProps.booking.date,
            timeFrom: moment(newEvent.start).format('HH:mm') + ':00',
            timeTo: moment(newEvent.end).format('HH:mm') + ':00',
        }).then(() => {
            newEvent.extendedProps.booking.timeFrom = moment(newEvent.start).format('HH:mm') + ':00';
            newEvent.extendedProps.booking.timeTo = moment(newEvent.end).format('HH:mm') + ':00';

            this.addCalendarItem(newEvent.extendedProps.user.id, newEvent);
            this.props.onEventReceive(oldEvent.extendedProps.user.id, oldEvent.extendedProps.booking.id);

            this.loadingHide();
            this.setState({});

        }).catch(response => {
            this.loadingHide();
            notify({text: response.message});
        });
    }

    fittingEventChangeUser(oldEvent: EventItem, newEvent: EventItem) {
        apiRequest('/bs/fitting/change-time', {
            id: newEvent.extendedProps.fitting.id,
            userId: newEvent.extendedProps.user.id,
            oldUserId: oldEvent.extendedProps.user.id,
            oldUserList: oldEvent.extendedProps.fitting.userList,
            date: newEvent.extendedProps.fitting.date,
            timeFrom: moment(newEvent.start).format('HH:mm') + ':00',
            timeTo: moment(newEvent.end).format('HH:mm') + ':00',
        }).then(() => {
            newEvent.extendedProps.fitting.timeFrom = moment(newEvent.start).format('HH:mm') + ':00';
            newEvent.extendedProps.fitting.timeTo = moment(newEvent.end).format('HH:mm') + ':00';

            this.addCalendarItem(newEvent.extendedProps.user.id, newEvent);
            this.props.onEventReceive(oldEvent.extendedProps.user.id, 'fi' + oldEvent.extendedProps.fitting.id);

            this.loadingHide();
            this.setState({});

        }).catch(response => {
            this.loadingHide();
            notify({text: response.message});
        });
    }

    bookingEventChangeTime(event) {
        apiRequest('/bs/booking/change-time', {
            id: event.extendedProps.booking.id,
            repId: event.extendedProps.user.id,
            date: event.extendedProps.booking.date,
            timeFrom: moment(event.start).format('HH:mm') + ':00',
            timeTo: moment(event.end).format('HH:mm') + ':00',
        }).then(() => {
            let testEvent = this.getUserEvent(event.extendedProps.user.id, event.extendedProps.booking.id);

            if (testEvent) {
                testEvent.start = moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD') + ' ' + moment(event.start).format('HH:mm') + ':00';
                testEvent.end = moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD') + ' ' + moment(event.end).format('HH:mm') + ':00';

                testEvent.extendedProps.booking.timeFrom = moment(event.start).format('HH:mm') + ':00';
                testEvent.extendedProps.booking.timeTo = moment(event.end).format('HH:mm') + ':00';
            }

            this.loadingHide();

        }).catch(response => {
            this.loadingShow();
            notify({text: response.message});
        });
    }

    fittingEventChangeTime(event) {
        this.loadingShow();

        apiRequest('/bs/fitting/change-time', {
            id: event.extendedProps.fitting.id,
            userId: event.extendedProps.user.id,
            date: moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD'),
            timeFrom: moment(event.start).format('HH:mm') + ':00',
            timeTo: moment(event.end).format('HH:mm') + ':00',
        }).then(() => {
            let testEvent = this.getUserEvent(event.extendedProps.user.id, 'fi' + event.extendedProps.fitting.id);

            if (testEvent) {
                testEvent.start = moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD') + ' ' + moment(event.start).format('HH:mm') + ':00';
                testEvent.end = moment(this.curDate, "YYYY-MM-DD").format('YYYY-MM-DD') + ' ' + moment(event.end).format('HH:mm') + ':00';

                testEvent.extendedProps.fitting.timeFrom = moment(event.start).format('HH:mm') + ':00';
                testEvent.extendedProps.fitting.timeTo = moment(event.end).format('HH:mm') + ':00';
            }

            this.loadingHide();

        }).catch(response => {
            this.loadingShow();
            notify({text: response.message});
        });
    }

    onMoveEventToOtherRep(info, newUser: User) {
        let newEvent = new EventItem();
        newEvent.id = info.event.id;
        newEvent.title = info.event.title;
        newEvent.constraint = info.event.constraint;

        newEvent.className = info.event.classNames.join(' ');

        if (info.event.extendedProps.fitting && info.event.extendedProps.fitting.userList.length > 1) {
            info.revert();
            return;

            // const fitter = info.event.extendedProps.fitting;
            // newEvent.date = moment(fitter.date).toDate();
            // newEvent.start = moment(fitter.date + ' ' + fitter.timeFrom).format('YYYY-MM-DD HH:mm:00');
            // newEvent.end = moment(fitter.date + ' ' + fitter.timeTo).format('YYYY-MM-DD HH:mm:00');
        } else {
            newEvent.date = this.curDate;
            newEvent.start = moment(info.event.start).format('YYYY-MM-DD HH:mm:00');
            newEvent.end = moment(info.event.end).format('YYYY-MM-DD HH:mm:00');
        }

        newEvent.extendedProps = {
            booking: info.event.extendedProps.booking,
            fitting: info.event.extendedProps.fitting,
            user: newUser,
        };

        this.eventChangeUser(info.event, newEvent);
    }

    onEventChangeTime(info) {
        if (info.event.extendedProps.booking) {
            this.loadingShow();
            this.bookingEventChangeTime(info.event);

            return;
        }

        // prevent multifitting time change
        if (info.event.extendedProps.fitting.userList.length > 1) {
            info.revert();
            return;
        }

        this.loadingShow();
        this.fittingEventChangeTime(info.event);

    }

    addCalendarItem(userId, event: EventItem) {
        let user = this.findUser(userId);

        if (!user) {
            // console.warn(['Can not add event for user ' + userId, event]);

            return;
        }

        if (!this.calendarItems[user.id]) {
            this.calendarItems[user.id] = {
                user: user,
                events: []
            };
        }

        if (!event.extendedProps) {
            event.extendedProps = {};
        }

        event.extendedProps.user = user;

        this.calendarItems[user.id].events.push(event);
    }

    updateCalendar() {
        this.loading--;

        if (this.loading > 0) {
            return;
        }

        this.calendarItems = [];

        // add loaded bookings to calendar
        this.bookingService.list.forEach(booking => {
            let user = this.findUser(booking.userId);
            const timeFrom = moment(booking.date + ' ' + booking.timeFrom);
            const timeTo = moment(booking.date + ' ' + booking.timeTo);

            let title = (booking.company ? booking.company : booking.firstName + ' ' + booking.lastName) + ' | '
                + booking.suburb + ' | ' + booking.getMeasuringsString();

            if (this.props.calendarType === 'fitter') {
                title += ' | ' + booking.message;
            }

            this.addCalendarItem(booking.userId, {
                title: title,
                id: booking.id,
                extendedProps: {
                    booking: booking
                },
                start: moment(this.curDate).format('YYYY-MM-DD') + ' ' + booking.timeFrom,
                end: moment(this.curDate).format('YYYY-MM-DD') + ' ' + booking.timeTo,
                className: booking.howToKnow === 14 ? 'self__booking' : '',
            });
        });

        // add loaded fitting to calendar
        this.fittingService.list.forEach(fitter => {
            let className = fitter.fixedTime ? 'fixed__time' : '';

            const users = fitter.userList ? fitter.userList : [fitter.userId];


            if (users.length > 1) {
                className += ' multiple__fitter';
            }

            let title = (fitter.booking.company ? fitter.booking.company : fitter.booking.firstName + ' ' + fitter.booking.lastName);

            if (fitter.productList.length) {
                let tp = fitter.productList[0].type_name;
                switch (fitter.productList[0].type_name) {
                    case 'Check measure':
                        className += ' fitting__type__cm';
                        break;

                    case 'Service call':
                        className += ' fitting__type__sc';
                        break;

                    case 'Fitting':
                        className += ' fitting__type__fitting';
                        break;

                    case 'JP':
                        className += ' fitting__type__jp';
                        break;

                    default:
                        className += '';
                }

                if (tp === 'Check measure') {
                    tp = 'CM';
                }

                title += ' | ' + tp;
            }

            title += ', ' + fitter.booking.suburb + ' | ' + fitter.productToString();

            if (this.props.calendarType === 'fitter') {
                title += ' | ' + fitter.notes;
            }

            let eventItem = {
                title: title,
                id: 'fi' + fitter.id,
                extendedProps: {
                    fitting: fitter
                },
                start: moment(this.curDate).format('YYYY-MM-DD') + ' ' + fitter.timeFrom,
                end: moment(this.curDate).format('YYYY-MM-DD') + ' ' + fitter.timeTo,
                className: className,
            };

            if (users.length > 1) {
                eventItem.editable = false;
            }

            users.forEach(userId => {
                this.addCalendarItem(userId, eventItem);
            });

        });


        // all calendars goto date
        this.calendarRefs.forEach((userId, ref) => {
            if (!ref || !ref.current) {
                return;
            }

            let calendar = ref.current.getApi();
            calendar.gotoDate(this.curDate);
        });

        this.loadingHide();
        this.setState({});
    }

    loadData() {
        if (moment(this.lastDate).format('YYYY-MM-DD') === moment(this.curDate).format('YYYY-MM-DD')) {
            return;
        }

        this.lastDate = this.curDate;
        this.loadingShow();

        this.loading += 1;

        this.bookingService
            .getPagination()
            .setPageSize(1200);

        this.bookingService.setSearchParams({
            status: [BOOKING_STATUS.active],
            dateFrom:  moment(this.curDate, "MM-DD-YYYY").add('d', -100),
            dateTo: moment(this.curDate, "MM-DD-YYYY").add('d', 365),
            isHolding: 1
        });

        this.fittingService
            .getPagination()
            .setPageSize(1200);

        this.fittingService.setSearchParams({
            status: [BOOKING_STATUS.active],
            dateFrom:  moment(this.curDate, "MM-DD-YYYY").add('d', -100),
            dateTo: moment(this.curDate, "MM-DD-YYYY").add('d', 365),
            isHolding: 1
        });
        // this.updateCalendar();

    }

    contextMenu(e) {
        e.preventDefault();

        let hideItems = [];

        if (!this.hoverEvent || this.hoverEvent.event.extendedProps.type === 'not-working') {
            hideItems.push('cut');
        }

        if (!this.hoverEvent || this.hoverEvent.event.extendedProps.type !== 'not-working') {
            hideItems.push('delete');
        }

        if (!this.copiedEvent || (this.hoverEvent && this.hoverEvent.event.extendedProps.type === 'not-working')) {
            hideItems.push('paste');
        }

        if (hideItems.length === 3) {
            return;
        }

        this.triggeredEvent = this.hoverEvent;

        let targetTime = e.target.dataset.time;

        let user = null;
        let searchEl = e.target.parentElement;
        let maxDeep = 10000;

        while (true) {
            if (!searchEl) {
                break;
            }

            if (searchEl.classList.contains('calendar__booking__list')) {
                user = searchEl.dataset.id;
                break;
            }

            searchEl = searchEl.parentElement;
            maxDeep--;

            if (maxDeep === 0) {
                break;
            }
        }

        if (user) {
            this.contextTarget = {
                type: ContextTarget.TYPE_CELL,
                userId: Number(user),
                time: targetTime,
            };
        } else if (this.triggeredEvent) {
            this.contextTarget = {
                type: ContextTarget.TYPE_EVENT,
                event: this.triggeredEvent,
            };
        } else {
            this.contextTarget = {
                type: ContextTarget.TYPE_UNKNOWN
            };
        }

        let menu = this.refContextMenu.current;

        const resultWidth = e.pageX + menu.getWidth();
        const positionFixX = window.scrollX + window.innerWidth < resultWidth ? window.scrollX + window.innerWidth - resultWidth - 50 : 0;

        menu.hideItems(hideItems);
        menu.show(e.pageX + positionFixX, e.pageY);
    }

    onCopyEvent(deleteOnMove: boolean) {
        if (!this.triggeredEvent) {
            console.log('NO TRIG');
            return;
        }

        this.triggeredEvent.el.style.opacity = 0.5;

        this.deleteOnMove = deleteOnMove;
        this.copiedEvent = this.getUserEvent(
            this.triggeredEvent.event.extendedProps.user.id,
            this.triggeredEvent.event.extendedProps.booking ? this.triggeredEvent.event.extendedProps.booking.id : 'fi' + this.triggeredEvent.event.extendedProps.fitting.id
        );
    }

    onPasteEvent() {
        if (!this.copiedEvent) {
            return;
        }

        let start = moment(this.copiedEvent.start);
        let end = moment(this.copiedEvent.end);
        let targetStart = moment(moment(this.curDate).format('YYYY-MM-DD') + ' ' + this.contextTarget.time);

        let newEvent = new EventItem();
        newEvent.id = this.copiedEvent.id;
        newEvent.title = this.copiedEvent.title;
        newEvent.date = this.curDate;

        newEvent.className = this.copiedEvent.className;
        newEvent.start = targetStart.format('YYYY-MM-DD HH:mm:00');

        targetStart.add(moment.duration(end.diff(start)).asMilliseconds(), 'milliseconds');

        newEvent.end = targetStart.format('YYYY-MM-DD HH:mm:00');
        newEvent.extendedProps = {
            booking: this.copiedEvent.extendedProps.booking,
            fitting: this.copiedEvent.extendedProps.fitting,
            user: this.findUser(this.contextTarget.userId)
        };

        if (newEvent.extendedProps.booking) {
            newEvent.extendedProps.booking.date = moment(newEvent.date).format('YYYY-MM-DD');
        } else {
            if (newEvent.extendedProps.fitting.userList.length > 1) {
                newEvent.date = moment(newEvent.extendedProps.fitting.date).toDate();
                newEvent.start = moment(newEvent.extendedProps.fitting.date + ' ' + newEvent.extendedProps.fitting.timeFrom).format('YYYY-MM-DD HH:mm:00');
                newEvent.end = moment(newEvent.extendedProps.fitting.date + ' ' + newEvent.extendedProps.fitting.timeTo).format('YYYY-MM-DD HH:mm:00');
                newEvent.constraint = this.copiedEvent.constraint;
            } else {
                newEvent.extendedProps.fitting.date = moment(newEvent.date).format('YYYY-MM-DD');
            }
        }

        if (this.deleteOnMove) {
            this.eventChangeUser(this.copiedEvent, newEvent);
        }

        this.copiedEvent = null;
        this.contextTarget = null;
    }

    onMouseEnterEvent(info) {
        this.hoverEvent = info;
    }

    onMouseLeaveEvent() {
        this.hoverEvent = null;
    }

    onEventClick(info) {
        if (info.event.id.substr(0, 2) === 'an') {
            return;
        }

        // this.selectedEvent = info.event.extendedProps;

        this.selectedEvent = this.getUserEvent(info.event.extendedProps.user.id, info.event.id).extendedProps;

        this.showBookingPopup(true);
    }

    onDateClick(info, user) {
        this.selectedEvent = {
            user: user,
            booking: new Booking(),
            fitting: new Fitting()
        };

        let date = new moment(info.date);

        this.selectedEvent.booking.date = date.format('YYYY-MM-DD');
        this.selectedEvent.booking.timeFrom = date.format('HH:mm') + ':00';
        this.selectedEvent.booking.timeTo = new moment(info.date).add(2, 'hours').format('HH:mm') + ':00';

        this.selectedEvent.fitting.date = date.format('YYYY-MM-DD');
        this.selectedEvent.fitting.timeFrom = date.format('HH:mm') + ':00';
        this.selectedEvent.fitting.timeTo = new moment(info.date).add(1.5, 'hours').format('HH:mm') + ':00';

        this.showBookingPopup(true);
    }

    showBookingPopup(show: boolean): void {
        this.modalStatusRep.show = show;

        this.setState({});
    }

    loadReps() {
        this.loadingShow();
        this.loading++;

        if (this.props.allReps) {
            this.props.allReps.forEach(user => {
                if (!user.isHolding) {
                    return;
                }
                this.reps.push(user);
                this.calendarRefs[user.id] = React.createRef();
            });

            this.updateCalendar();
        }
    }

    onLoading(show) {
        if (show) {
            this.loadingShow();
        } else {
            this.loadingHide();
        }
    }

    onChange() {
        this.showBookingPopup(false);
        this.loadingShow();
        this.loading += 2;

        this.bookingService
            .resetPagination()
            .loadList();

        this.fittingService
            .resetPagination()
            .loadList();
    }

    canShowUserCalendar(user: User) {
        // consultant|reps
        if (this.props.calendarType === 'consultant') {
            if (user.role !== ROLES.reps.toString()) {
                return false;
            }

            if (!user.showroom) {
                return true;
            }

            // showroom match
            if (!this.props.showRooms || !this.props.showRooms[user.showroom]) {
                return false;
            }

            return true;
        }

        // fitters
        if (this.props.calendarType === 'fitter') {
            if (user.role !== ROLES.fitter.toString()) {
                return false;
            }

            let canShow = false;

            MEASUREMENTS_SELECT.some((item) => {
                if (user.measuringHas(item.value.toString()) && this.props.blinds && this.props.blinds[item.value]) {
                    canShow = true;

                    return true;
                }

                return false;
            });

            return canShow;
        }

        return false;
    }

    onDeleteEvent() {
        if (!this.triggeredEvent) {
            console.log('NO TRIG');
            return;
        }

        apiRequest('/bs/day-off/delete', {
            id: this.triggeredEvent.event.id.substr(3)
        })
            .then(() => {
                this.triggeredEvent = null;
                this.modalDeleteConfirm.show = false;

                this.onChange();
            }).catch(response => {
            notify({text: response.message});
        });

    }

    getTypeList() {
        let result = [];

        if (Access.isAllowed('Calendar', 'typeSwitchConsultant')) {
            result.push({
                value: 'consultant',
                label: 'Consultant'
            });
        }

        if (Access.isAllowed('Calendar', 'typeSwitchFitter')) {
            result.push({
                value: 'fitter',
                label: 'Fitter'
            });
        }

        return result;
    }

    getBookingDefaultTab() {
        if (this.selectedEvent.booking && this.selectedEvent.booking.id) {
            return 0;
        }

        if ((this.selectedEvent.fitting && this.selectedEvent.fitting.id)
            || this.auth.role === ROLES.commercial
            || this.props.calendarType === 'fitter'
        ) {
            return 1;
        }

        return 0;
    }

    render() {
        if (this.props.date) {
            this.curDate = this.props.date.date;
        }

        return (
            <>
                <div className="loading__overlay" ref={this.holdingRefLoading} style={{display: 'none'}}>
                    <svg className="icon__loading">
                        <circle cx="13" cy="13" r="13"></circle>
                    </svg>
                </div>
                <h1>Holding Calendar</h1>
                <div className="panel">
                    <div className="panel__body panel__body--no-padding">

                        <div className="calendar__booking">
                            <div
                                className="calendar__booking__row"
                                onContextMenu={this.contextMenu}
                            >
                                {this.reps.map((item, key) => {
                                    if (!this.canShowUserCalendar(item)) {
                                        return '';
                                    }

                                    return (
                                        <div
                                            className={'calendar__booking__list'}
                                            key={key + 'holding' + item.id} data-id={'holding' + item.id}>
                                            <div
                                                className="calendar__booking__reps__name"
                                            >
                                                    <span
                                                        className="firstname">{item.firstName.replace(/Holding_|[_)(]/g, ' ')}</span>
                                                {/*<span className="lastname">{item.lastName}</span>*/}
                                            </div>
                                            <FullCalendar
                                                key={Math.random()}
                                                initialView={'timeGridDay'}
                                                headerToolbar={{
                                                    left: '',
                                                    center: '',
                                                    right: ''
                                                }}
                                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                                // weekends={this.state.calendarWeekends}
                                                events={this.calendarItems[item.id] ? this.calendarItems[item.id].events : null}
                                                eventClick={this.onEventClick}
                                                dateClick={(info) => {
                                                    this.onDateClick(info, item)
                                                }}
                                                slotMinTime={this.minTime}
                                                slotMaxTime={this.maxTime}
                                                droppable={"true"}
                                                editable={"true"}
                                                allDaySlot={false}
                                                dayHeaders={false}
                                                // snapDuration={'00:01:00'}

                                                eventReceive={(info) => this.onMoveEventToOtherRep(info, item)}
                                                eventDrop={this.onEventChangeTime}

                                                // id={item.id}
                                                contentHeight={"auto"}
                                                eventDurationEditable={false}
                                                // repMinTime={reps.rep_time_from}
                                                // repMaxTime={reps.rep_time_to}
                                                // timing={reps.timing}
                                                initialDate={this.curDate}
                                                ref={this.calendarRefs[item.id]}
                                                eventMouseEnter={this.onMouseEnterEvent}
                                                eventMouseLeave={this.onMouseLeaveEvent}
                                                eventTimeFormat={{
                                                    hour: 'numeric',
                                                    minute: '2-digit',
                                                    meridiem: false
                                                }}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    status={this.modalStatusRep}
                    className="modal__add__booking abc__modal--top"
                >
                    {this.selectedEvent ?
                        <div className="abc__modal__content">
                            <div className="panel">
                                <Tabs
                                    items={
                                        [
                                            'Measure',
                                            'Fitter',
                                            'Logs',
                                            'Unavailability',
                                        ]
                                    }
                                    defaultIndex={this.getBookingDefaultTab()}
                                    showTab={[
                                        Access.isAllowed('EventTab', 'booking'),
                                        Access.isAllowed('EventTab', 'fitting'),
                                        true,
                                        true
                                    ]}
                                >
                                    <div>
                                        <BookingEvent
                                            calendarEvent={this.selectedEvent.booking}
                                            // event={this.selectedEvent}
                                            auth={this.auth}
                                            user={this.selectedEvent.user}
                                            onLoading={this.onLoading}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div>
                                        <FittingEvent
                                            calendarEvent={this.selectedEvent.fitting}
                                            user={this.selectedEvent.user}
                                            onLoading={this.onLoading}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div>
                                        <div className="panel__body">
                                            <Logs
                                                // date={this.curDate}
                                                calendarEvent={this.selectedEvent}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="panel__body">
                                            <AvailabilityEvent
                                                date={this.curDate}
                                                user={this.selectedEvent.user}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                </Tabs>
                            </div>

                        </div>
                        : ''}

                </Modal>

                <Modal
                    status={this.modalDeleteConfirm}
                >

                    <div className="panel">
                        <div className="panel__top">
                            <div className="panel__title">Delete event</div>
                        </div>
                        <div className="panel__body">
                            Are you sure you want to cancel this event?

                        </div>
                        <div className="panel__bottom">
                            <div className="bh">
                                <button className="btn" onClick={this.onDeleteEvent}>Yes</button>
                                <button
                                    className="btn"
                                    onClick={() => {
                                        this.modalDeleteConfirm.show = false;
                                        this.setState({})
                                    }}
                                >No
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    status={this.modalDeleteConfirm}
                >

                    <div className="panel">
                        <div className="panel__top">
                            <div className="panel__title">Delete event</div>
                        </div>
                        <div className="panel__body">
                            Are you sure you want to cancel this event?

                        </div>
                        <div className="panel__bottom">
                            <div className="bh">
                                <button className="btn" onClick={this.onDeleteEvent}>Yes</button>
                                <button
                                    className="btn"
                                    onClick={() => {
                                        this.modalDeleteConfirm.show = false;
                                        this.setState({})
                                    }}
                                >No
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    status={this.modalOutsideConfirm}
                >
                    <div className="panel">
                        <div className="panel__top">
                            <div className="panel__title">Alert Notice</div>
                        </div>

                        <div className="panel__body">
                            You are adding a booking outside the working hours. Please click "It's all good" to ignore
                            this message or move the booking to a different time frame.
                        </div>

                        <div className="panel__bottom">
                            <div className="bh">
                                <button className="btn" onClick={() => {
                                    this.modalOutsideConfirm.show = false;
                                    this.setState({});
                                }}>It's all good
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <ContextMenu
                    // target={'context-menu-content'}
                    ref={this.refContextMenu}
                    items={[
                        {
                            id: 'cut',
                            label: 'Cut',
                            onClick: () => {
                                this.onCopyEvent(true)
                            },
                        },
                        {
                            id: 'paste',
                            label: 'Paste',
                            onClick: this.onPasteEvent
                        },
                        {
                            id: 'delete',
                            label: 'Delete',
                            onClick: () => {
                                this.modalDeleteConfirm.show = true;
                                this.setState({})
                            }
                        },
                    ]}
                />
            </>
        );
    }
}