import React from 'react';
import moment from "moment";
import PropTypes from "prop-types";
import {User} from "../../components/form/model/User";
import {apiRequest} from "../../components/Globals";
import {notify} from "../../components/Notify";
import {Fitting} from "../../components/form/model/Fitting";
import {Auth} from "../../components/Auth";
import {Booking} from "../../components/form/model/Booking";

export default class Runs extends React.Component {
    static propTypes = {
        date: PropTypes.object,
        user: PropTypes.instanceOf(User),
        calendarType: PropTypes.string
    };

    state = {
        isSending: false,
    };

    auth = new Auth();

    constructor(props) {
        super(props);

        this.sendRunsheet = this.sendRunsheet.bind(this);

        this.auth.load();
    }

    sendRunsheet() {
        if (this.state.isSending) {
            return;
        }

        this.setState({isSending: true});

        let userId = null;

        if (this.props.user) {
            userId = this.props.user.id;
        } else if (this.props.userId) {
            userId = this.props.userId;
        } else {
            console.error('No user provided');
            return;
        }

        apiRequest('/bs/user/runsheet', {
            userId: userId,
            date: moment(this.props.date).format('YYYY-MM-DD')
        }).then(() => {
            this.setState({isSending: false});
            notify({text: 'Runsheet sent'});
        }).catch(response => {
            this.setState({isSending: false});
            notify({text: response.message});
        });
    }

    print() {
        let content = document.getElementById("printPlace");
        let pri = document.getElementById("ifmcontentstoprint").contentWindow;
        let calendarType = this.props.calendarType;


        pri.document.open();
        pri.document.write(content.innerHTML);

        if (calendarType === 'fitter') {
            pri.document.write('<style>' +
                '@page { size: A4 landscape; margin: 1cm 1cm 1cm 1cm; } ' +
                'body { margin: 0; padding: 0; font-size: 8pt; font-family: Arial, "Segoe UI", verdana; } ' +
                '.two__column { display: block; } ' +
                'p { font-size: 8pt; line-height: 1.4em; }' +
                '.run__event { margin-bottom: 20px; width: 100%; }' +
                '.printing__section { padding: 10px; border: 1px solid #e1e1e1; height: 170mm; vertical-align: top; }' +
                '.action{ display: none; } ' +
                '.run__count { font-size: 10px; } ' +
                '.printing__heading { font-weight: bold; padding-bottom: 10px; font-size: 14px; text-align: center; } ' +
                '.print__runs { page-break-after: always; display: flex;} ' +
                +'</style>');
        } else {
            pri.document.write('<style>' +
                '@page { size: A4; margin: 1.2cm 1.2cm 1.2cm 1.2cm; } ' +
                '@page footnote { color: red; } ' +
                'body { margin: 0; padding: 0; font-size: 12px; line-height: 1.4em; font-family: Arial, "Segoe UI", verdana; } ' +
                '.two__column { display: flex; } ' +
                '.two__column .item { flex: 0 0 50%; } ' +
                '.tbl__consultants { font-size: 12px; line-height: 1.4em; }' +
                '.tbl__consultants tr td { vertical-align: top; text-align: left; }' +
                '.tbl__consultants tr td:first-child { width: 40%; }' +
                '.tbl__consultants.narrow tr td:first-child { width: 20%; }' +
                // '.tbl__runs div { font-size: 12px; padding-right: 10px; } ' +
                '.printing__section { border-top: 1px solid #000; padding: 10px 0; } ' +
                '.action{ display: none; } ' +
                '.printing__sub { font-weight: bold; } ' +
                '.printing__heading { font-weight: bold; padding-bottom: 10px; font-size: 15px; text-align: center; } ' +
                +'</style>');
        }

        pri.document.close();
        pri.focus();
        pri.print();
    }

    render() {
        let {events, user, ...rest} = this.props;

        if (!events) {
            events = [];
        }

        let addClass = '';

        if (this.state.isSending) {
            addClass = 'runsheet__sending';
        }

        let fittings = [[]];
        let bookings = [[]];
        let bookingCount = 0;
        let fittingCount = 0;

        // counters only for render
        let decrBookings = 0;
        let decrFittings = 0;
        let bookingNumber = 0;
        let fittingNumber = 0;

        events.forEach((el) => {
            if (!el) {
                return
            }

            if (el instanceof Booking) {
                if (bookings[bookingCount].length === 6) {
                    bookingCount++;
                    bookings[bookingCount] = [];
                }

                bookings[bookingCount].push(el);
            }

            if (el instanceof Fitting) {
                if (fittings[fittingCount].length === 6) {
                    fittingCount++;
                    fittings[fittingCount] = [];
                }

                fittings[fittingCount].push(el);
            }
        });

        return (
            <>
                <iframe id="ifmcontentstoprint" style={{height: 0, width: 0, position: 'absolute'}}></iframe>

                <div className={'printing ' + addClass} id={'printPlace'}>
                    <div className="action">
                        <span onClick={() => this.print()}>Print</span>{
                        ![2, 6].includes(this.auth.role) ?
                            <> | <span onClick={this.sendRunsheet}>Email Runsheet</span></> : ''}
                    </div>
                    <div
                        className="printing__heading">{user ? user.firstName + ' ' + user.lastName + ' |' : ''} {events.length} Runs, {moment(this.props.date).format('dddd DD MMMM YYYY')}</div>

                    {bookings.map((bookingTable, key) => {
                        if (bookingTable.length === 0) {
                            return;
                        }

                        return (
                            <div className="print__runs" width="100%" key={'bookings' + key}>

                                {bookingTable.map(event => {

                                    if (!event) {
                                        decrBookings++;

                                        return;
                                    }

                                    bookingNumber++;

                                    return (
                                        <div className={'run__event'} key={event.id}>
                                            <div className="printing__section">
                                                <div
                                                    className="printing__title"> Run {bookingNumber - decrBookings}</div>

                                                <div className="two__column">
                                                    <div
                                                        className="printing__sub item">{event.company ? event.company : event.firstName + ' ' + event.lastName} | {event.suburb}{event.callBefore ? ' | Phone Before Appointment' : ''}</div>

                                                    <div className={'item'}>
                                                        <table className="tbl__consultants">
                                                            <tbody>
                                                            <tr>
                                                                <td>Products:</td>
                                                                <td>
                                                                    {event.products.map((prod, key) => (<div key={prod.label + key }>{prod.label}{event.products.length !== key + 1 && ', '} </div>))}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>


                                                <div className="two__column">
                                                    <div className="item">
                                                        <table className="tbl__consultants">
                                                            <tbody>
                                                            <tr>
                                                                <td>Booked by:</td>
                                                                <td>{event.howToKnow === 14 ? 'Self Booking' : event.creatorName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Phone:</td>
                                                                <td>{event.phone}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Email:</td>
                                                                <td>{event.email}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Address:</td>
                                                                <td>{event.unitNo ? event.unitNo + ', ' : ''}{event.address}, {event.suburb}, {event.postalCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Appointment time:</td>
                                                                <td>{moment(event.date + ' ' + event.timeFrom).format('h:mma')} - {moment(event.date + ' ' + event.timeTo).format('h:mma')}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="item">
                                                        <table className="tbl__consultants narrow">
                                                            <tbody>
                                                            <tr>
                                                                <td>Qty</td>
                                                                <td>{event.getMeasuringsString()}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Notes</td>
                                                                <td>{event.message}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}

                    {fittings.map((fittingTable, key) => {
                        if (fittingTable.length === 0) {
                            return;
                        }

                        return (
                            // <div className={'printing__events'}>
                            <div className="print__runs" width="100%" key={'fittings' + key}>

                                {fittingTable.map((event, i) => {
                                    if (!event) {
                                        decrFittings++;

                                        return;
                                    }

                                    fittingNumber++;

                                    let tp = '';

                                    if (event.productList.length) {
                                        tp = event.productList[0].type_name;

                                        if (tp === 'Check measure') {
                                            tp = 'CM';
                                        }
                                    } else {
                                        tp = 'Service Call';
                                    }

                                    // fitting
                                    return (
                                        <div className={'run__event'} key={event.id}>
                                            <div className="printing__section">

                                                <div className="run__count">
{/*
                                                    {event instanceof Fitting ?
                                                        moment(event.date + ' ' + event.timeFrom).format('a').toUpperCase()
                                                        : ''
                                                    } Run {fittingNumber - decrFittings}
*/}
                                                    {event instanceof Fitting ?
                                                        moment(event.date + ' ' + event.timeFrom).format('h:mm') + ' - ' + moment(event.date + ' ' + event.timeTo).format('h:mm')
                                                        : ''
                                                    } Run {fittingNumber - decrFittings}
                                                </div>

                                                <div className="two__column">
                                                    <div className="item">
                                                        <p>
                                                            <strong>{event.booking.company ? event.booking.company : event.booking.firstName + ' ' + event.booking.lastName}
                                                                <br/> {tp}, {event.booking.suburb} </strong><br/>
                                                            {event.callBefore ? 'Phone Before Appointment' : ''}

                                                        </p>
                                                        <p>Booked by {event.creatorName}<br/>
                                                            Sap No. {event.customerNo}
                                                        </p>

                                                        <p>
                                                            <strong>Address</strong><br/>
                                                            {event.booking.unitNo ? event.booking.unitNo + ', ' : ''}{event.booking.address}, {event.booking.suburb}<br/>
                                                            {event.booking.phone}<br/>
                                                            {/* {event.booking.email}<br/> */}
                                                        </p>
                                                    </div>

                                                    <div className="item">
                                                        <p>
                                                            All windows to be
                                                            cleared: {event.windows ? 'Yes' : 'No'}
                                                            <br/>
                                                            Balance Due: {event.balanceDue}<br/>
                                                            Takedowns: {event.takedowns}<br/>
                                                            Product Location: {event.productLocationText}
                                                        </p>

                                                        {/*

                                                                        Type:
                                                                        {getFittingType(event.type)}

                                                                        */}


                                                        {/**/}
                                                        {/*    Contract Price:*/}
                                                        {/*    {event.contractPrice}*/}
                                                        {/**/}

                                                        <p>
                                                            <strong>Product</strong><br/>
                                                            {event.productList.map(product => {
                                                                return (
                                                                    product.quantity + 'x ' + product.name + ' (' + (product.type_name === 'Check measure' ? 'CM' : product.type_name) + '), '
                                                                )
                                                            })}

                                                        </p>

                                                        <p>
                                                            Notes: {event.notes}
                                                        </p>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    )
                                })}
                                {/*</div>*/}
                            </div>
                        )
                    })}

                </div>
            </>
        );
    }
}
